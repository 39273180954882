import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserActiveStatus } from 'src/app/models/user.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { OperationService } from 'src/app/services/operation.service';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss']
})
export class OperationComponent implements OnInit {

  groups: any;
  limit: number;
  page: number;
  count: number = 0;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'Operation.createdAt': 'DESC' };
  filtered = {};
  operationFilterForm: any;
  operations: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private operationService: OperationService,
    private _snackBar: MatSnackBar
  ) { 
    this.manageProductCategoryFilterForm();
  }

  async ngOnInit() { 
    try {
      await this.loadOperations();
    } catch (error) {
      
    }
  }

  manageProductCategoryFilterForm(){
    this.operationFilterForm = new FormGroup({
      'Operation.id': new FormControl(''),
      'User.surname': new FormControl(''),
      'User.name': new FormControl(''),
      'User.email': new FormControl(''),
      'Operation.address': new FormControl(''),
      'Operation.phone': new FormControl(''),
      'User.active': new FormControl(''),
    });
  }

  async loadOperations(limit?: number, page?: number, order?: {}){
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let operations = await this.operationService.getAllOperations(this.page, this.limit, this.order, this.filtered);
    this.operations = operations;
    this.count = operations.length;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  translateActive(status) {
    return UserActiveStatus.find(as => as.value == status).ln.it;
  }

  
  async resetFilter(){
    this.manageProductCategoryFilterForm();
    this.filter();
  }

  async filter(){

    let filterForm = (new FormGroupToObject()).transform(this.operationFilterForm);
    this.filtered = {};

    if (filterForm['Operation.id'])
      this.filtered['Operation.id'] = filterForm['Operation.id'];    
    if (filterForm['User.active'])
      this.filtered['User.active'] = filterForm['User.active'];    
    if (filterForm['Operation.address'])
      this.filtered['Operation.address'] = { operator: 'like', values: [`%${filterForm['Operation.address']}%`] };
    if (filterForm['Operation.notes'])
      this.filtered['Operation.notes'] = { operator: 'like', values: [`%${filterForm['Operation.notes']}%`] };
    if (filterForm['Operation.phone'])
      this.filtered['Operation.phone'] = { operator: 'like', values: [`%${filterForm['Operation.phone']}%`] };
    if (filterForm['User.surname'])
      this.filtered['User.surname'] = { operator: 'like', values: [`%${filterForm['User.surname']}%`] };
    if (filterForm['User.name'])
      this.filtered['User.name'] = { operator: 'like', values: [`%${filterForm['User.name']}%`] };
    if (filterForm['User.email'])
      this.filtered['User.email'] = { operator: 'like', values: [`%${filterForm['User.email']}%`] };

    this.loadOperations();
  }

  async deleteOperation(operationId: number){
      if (!confirm("Sei sicuro di voler cancellare questo operation?"))
        return;
      let deleteResult: { id: number | string, success: boolean } = await this.operationService.delete(operationId);
      let message = "Cancellazione del operation fallita.";
      if (deleteResult.success) {
        this.loadOperations();
        message = "Cancellazione del operation avvenuta con successo.";
      }
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadOperations();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  async showToastMessage(message: string){
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

}
