import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Expert, ExpertTeamLeaderProvinces } from 'src/app/models/expert.schema';
import { TeamLeader, TeamLeaderProvinces } from 'src/app/models/team-leader.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { ExpertService } from 'src/app/services/expert.service';
import { TeamLeaderService } from 'src/app/services/team-leader.service';

@Component({
  selector: 'app-expert-availability-list',
  templateUrl: './expert-availability-list.component.html',
  styleUrls: ['./expert-availability-list.component.scss']
})
export class ExpertAvailabilityListComponent implements OnInit {

  availabilityData: any[] = [];
  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'ExpertTimeAvailability.date': 'DESC', 'ExpertTimeAvailability.startTime': 'ASC', 'ExpertTimeAvailability.endTime': 'ASC' };
  today: any;
  expertAvailablitiesForm: any;
  teamLeader: TeamLeader;
  groups: any;
  loggedInUserInfo: any;
  loggedInExpert: Expert;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private expertService: ExpertService,
    private teamLeaderService: TeamLeaderService,
    private authStatusService: AuthStatusService,
  ) {
    var today = new Date();
    var tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2); //DATA CORRENTE
    this.today = tmptoday;
    this.expertAvailablitiesForm = new FormGroup({
      'ExpertTimeAvailability.id': new FormControl(''),
      'ExpertTimeAvailability.date_from': new FormControl(tmptoday), 
      'ExpertTimeAvailability.date_to': new FormControl(tmptoday),   
      'ExpertTimeAvailability.startTime': new FormControl(''),
      'ExpertTimeAvailability.endTime': new FormControl(''),
      'ExpertTimeAvailability.available': new FormControl(''),
      'UserExpert.surname': new FormControl(''),
    });
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    } catch (error) {
      
    }
  }

  async ngOnInit(){
    await this.filter();
    // await this.initialize();
  }

  async initialize(){
    await this.getExpertAvailabilities();
  }

  async getExpertAvailabilities(limit?: number, page?: number, order?: {}){
    try {
      let filterForm = (new FormGroupToObject()).transform(this.expertAvailablitiesForm);
      if(filterForm['ExpertTimeAvailability.date_to'] && filterForm['ExpertTimeAvailability.date_from']){
        if((this.today != filterForm['ExpertTimeAvailability.date_from'])){
          this.defaultOrder = { 'ExpertTimeAvailability.date': 'ASC', 'ExpertTimeAvailability.startTime': 'ASC', 'ExpertTimeAvailability.endTime': 'ASC' };
        }
      }
      this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
      this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
      this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
      this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
      if(this.groups.includes('expert') && this.loggedInUserInfo){
        this.loggedInExpert = await this.expertService.getOne(this.loggedInUserInfo['extraId']);
      }
      let availabilities = await this.expertService.getExpertAvailabilities(this.page, this.limit, this.order, this.filtered);
      this.availabilityData = availabilities;
      if(this.groups.includes('teamleader')){
        const loggedInUserInfo = await this.authStatusService.getTokenInfo();
        this.teamLeader = await this.teamLeaderService.getOne(loggedInUserInfo['extraId']);
        if (this.teamLeader?.teamLeaderProvinces.length > 0) {
          this.availabilityData = this.availabilityData?.filter((availability) => {
            return availability?.expert?.expertProvinces.some((eProvince) => {
              if(this.teamLeader != null && eProvince.province != null){
                return this.teamLeader.teamLeaderProvinces.some((teamLeaderProvince: TeamLeaderProvinces) => {
                  return eProvince.province.provinceCode.toLowerCase() === teamLeaderProvince.province.provinceCode.toLowerCase()
                });
              }
            });
          });
        }
      }
      if(this.groups.includes('expert') && this.loggedInExpert && this.loggedInExpert.expertTeamLeaderProvinces && this.loggedInExpert.isTeamLeader ){
        this.availabilityData = this.availabilityData?.filter((availability) => {
          return availability?.expert?.expertProvinces.some((eProvince) => {
            if(this.loggedInExpert.expertTeamLeaderProvinces.length > 0 && eProvince.province != null){
              return this.loggedInExpert.expertTeamLeaderProvinces.some((expertTeamLeaderProvince: ExpertTeamLeaderProvinces) => {
                return eProvince.province.provinceCode.toLowerCase() === expertTeamLeaderProvince.province.provinceCode.toLowerCase()
              });
            }
          });
        });
      }
      this.count = this.availabilityData.length;
      this.maxPage = Math.ceil(this.count / this.limit);
    } catch (error) {
      
    }
  }

  async filter(){
    let filterForm = (new FormGroupToObject()).transform(this.expertAvailablitiesForm);
    this.filtered = {};
    if (filterForm['ExpertTimeAvailability.id'])
      this.filtered['ExpertTimeAvailability.id'] = filterForm['ExpertTimeAvailability.id'];  
    if (filterForm['ExpertTimeAvailability.date_from'])
      this.filtered['ExpertTimeAvailability.date'] = { operator: 'moreThanOrEqual', values: [filterForm['ExpertTimeAvailability.date_from']] };
    if (filterForm['ExpertTimeAvailability.date_to'])
      this.filtered['ExpertTimeAvailability.date'] = { operator: 'lessThan', values: [filterForm['ExpertTimeAvailability.date_to']] };
    if (filterForm['ExpertTimeAvailability.date_from'] && filterForm['ExpertTimeAvailability.date_to'])
      this.filtered['ExpertTimeAvailability.date'] = { operator: 'between', values: [filterForm['ExpertTimeAvailability.date_from'], filterForm['ExpertTimeAvailability.date_to']] };
    if (filterForm['UserExpert.surname'])
      this.filtered['UserExpert.surname'] = { operator: 'like', values: [`%${filterForm['UserExpert.surname']}%`], concat: [{ string: ' ' }, { column: 'UserExpert.name' }] };
    if (filterForm['ExpertTimeAvailability.startTime'])
      this.filtered['ExpertTimeAvailability.startTime'] = { operator: 'moreThanOrEqual', values: [filterForm['ExpertTimeAvailability.startTime']] };
    if (filterForm['ExpertTimeAvailability.endTime'])
      this.filtered['ExpertTimeAvailability.endTime'] = { operator: 'lessThan', values: [filterForm['ExpertTimeAvailability.endTime']] };
    if (filterForm['ExpertTimeAvailability.available'])
      this.filtered['ExpertTimeAvailability.available'] = { operator: 'like', values: [filterForm['ExpertTimeAvailability.available']] };

    await this.getExpertAvailabilities();
  }

  fnReset(){
    this.managePartnerAvailablitiesForm();
    this.filter();
  }

  managePartnerAvailablitiesForm(){
    this.expertAvailablitiesForm = new FormGroup({
      'ExpertTimeAvailability.id': new FormControl(''),
      'ExpertTimeAvailability.date_from': new FormControl(''), 
      'ExpertTimeAvailability.date_to': new FormControl(''),   
      'ExpertTimeAvailability.startTime': new FormControl(''),
      'ExpertTimeAvailability.endTime': new FormControl(''),
      'ExpertTimeAvailability.available': new FormControl(''),
      'UserExpert.surname': new FormControl(''),
    });
  }

  public navigateOrder(attribute: string | string[]) {
    delete this.order['ExpertTimeAvailability.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.getExpertAvailabilities();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  navigate(event) {
    this.getExpertAvailabilities(event.limit, event.page, this.order);
  }

  formatTimeShow(fromTime, toTime){
    let trimmedFromTime = fromTime != null ? fromTime?.slice(0, -3) : '';
    let trimmedToTime = toTime != null ? toTime?.slice(0, -3) : '';
    let time = trimmedFromTime + '-' + trimmedToTime;
    return time;
  }

}
