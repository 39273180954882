
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamLeader, TeamLeaderProvinces } from 'src/app/models/team-leader.schema';
import { User } from 'src/app/models/user.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { ProvincesService } from 'src/app/services/provinces.service';
import { TeamLeaderService } from 'src/app/services/team-leader.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-team-leader-form',
  templateUrl: './team-leader-form.component.html',
  styleUrls: ['./team-leader-form.component.scss']
})
export class TeamLeaderFormComponent implements OnInit {

  @Input('profile') public profile: boolean = false;
  public readonly formValidationMsgs;
  public teamLeaderForm: any;
  private checkedPassword1: boolean;
  private checkedPassword2: boolean;
  public tmpBtnDisabled: boolean;
  public groups: string[];
  public teamLeader: TeamLeader;
  public provinces: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private authStatusService: AuthStatusService,
    private teamLeaderService: TeamLeaderService,
    private provincesService: ProvincesService,
  ) {
    this.formValidationMsgs = TeamLeader.validationMessages;
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    this.provinces = await this.provincesService.getAllProvinces();
    this.teamLeaderForm = new FormGroup(TeamLeader.validation(this.profile));
    const teamLeaderId = this.profile ? (await this.teamLeaderService.getOne(this.authStatusService.getExtra()['id'])).id.toString() : this.route.snapshot.paramMap.get('id');
    if (teamLeaderId) {
      try {
        this.teamLeader = await this.teamLeaderService.getOne(teamLeaderId);
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }

    if (!this.teamLeader) {
      this.teamLeader = new TeamLeader();
      this.teamLeader.user = this.route.snapshot.paramMap.get('userId') ?
        await this.userService.getOne(this.route.snapshot.paramMap.get('userId')) :
        new User();
    }
    this.patchAdditionalInfo(teamLeaderId);
    this.patch();

    if (this.profile)
      this.setProfileValidators();
  }

  patch() {
    ['phone', 'address', 'notes', 'longitude', 'latitude'].forEach(param => {
      this.teamLeaderForm.controls[param].setValue(this.teamLeader[param]);
    });
    ['surname', 'name', 'email'].forEach(param => {
      this.teamLeaderForm.controls.user.controls[param].setValue(this.teamLeader.user[param]);
    });
    // if(this.teamLeader?.province){
    //   this.teamLeaderForm.controls.province.setValue(this.teamLeader?.province?.id)
    // }
  }

  patchAdditionalInfo(teamLeaderId: number|string){
    if(teamLeaderId && this.teamLeader?.teamLeaderProvinces?.length > 0){
      this.patchTeamLeaderProvinces();
    }
  }

  patchTeamLeaderProvinces(){
    const control = <FormArray>this.teamLeaderForm.get('teamLeaderProvinces');
    control.clear();
    if (!this.teamLeader.teamLeaderProvinces || Array.isArray(this.teamLeader.teamLeaderProvinces) && this.teamLeader.teamLeaderProvinces.length == 0){
      this.teamLeader.teamLeaderProvinces = [new TeamLeaderProvinces()];
    }
    this.teamLeader.teamLeaderProvinces.forEach((f, index) => {
      control.push(this.patchTeamLeaderProvinceValues(f, index));
    });
  }

  patchTeamLeaderProvinceValues(teamLeaderProvinces: TeamLeaderProvinces, index: number) {
    let provinceForm = TeamLeader.teamLeaderProvinceFormGroup();
    provinceForm.controls.province.setValue(
      { 
        id: teamLeaderProvinces?.province?.id, 
      }
    );
        
    return provinceForm;
  }

  setProfileValidators() {
    this.checkedPassword1 = false;
    this.checkedPassword2 = false;

    this.teamLeaderForm.controls.user.controls.password1.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword1) {
        this.checkedPassword1 = true;
        this.teamLeaderForm.controls.user.controls.password2.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });

    this.teamLeaderForm.controls.user.controls.password2.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword2) {
        this.checkedPassword2 = true;
        this.teamLeaderForm.controls.user.controls.password1.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });
  }

  getAddress(place: any) {
    if (!this.teamLeaderForm) return;
    this.teamLeaderForm.controls.address.touched = true;
    this.teamLeaderForm.controls.address.setValue(place.formatted_address || place.name || '');
    this.teamLeaderForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this.teamLeaderForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');
  }

  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this.teamLeaderForm.controls.address.value}`, '_blank');
  }

  onSubmit() {
    this.tmpBtnDisabled = true;
    let teamLeader= (new FormGroupToObject()).transform(this.teamLeaderForm);
    let promiseResult: any;
    let createType: boolean = true;
    if (this.profile &&
      this.teamLeaderForm.controls.user.value.password1 &&
      this.teamLeaderForm.controls.user.value.password1 === this.teamLeaderForm.controls.user.value.password2
    ){
      teamLeader['user']['password'] = this.teamLeaderForm.controls.user.value.password1;
    }
    if (this.teamLeader['id']) {
      teamLeader['user']['id'] = this.teamLeader.user.id;
      promiseResult = this.profile ?
        this.teamLeaderService.updateMe(teamLeader) :
        this.teamLeaderService.update(this.teamLeader['id'], teamLeader);
      createType = false;
    }
    else {
      if (this.teamLeader.user.id){
        teamLeader['user']['id'] = this.teamLeader.user.id;
      }
      promiseResult = this.teamLeaderService.create(teamLeader);
    }
    promiseResult.then((data: TeamLeader) => {
      this.teamLeader = data;
      const message: string = `Caposquadra ${createType ? "creato" : "aggiornato"} con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      }).afterDismissed().subscribe(result => {
        this.authStatusService.isAuthenticated().then(valid => {
          if (!valid){ this.router.navigate(['/']); }
        });
        if (createType) {
          if (this.route.snapshot.paramMap.get('userId')){
            this.router.navigate(['/utenti']);
          } else{
            this.router.navigate(['/team/leader', 'edit', this.teamLeader.id]);
          }
        }
      });
      this.patch();
      this.tmpBtnDisabled = false;
    }).catch(error => {
      this._snackBar.open(`Errore ${createType ? "nella creazione" : "nell'aggiornamento"} dell'expert`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      this.tmpBtnDisabled = false;
      console.log(error);
    });
  }

  addTeamLeaderProvince() {
    const control = <FormArray>this.teamLeaderForm.get('teamLeaderProvinces');
    control.push(TeamLeader.teamLeaderProvinceFormGroup());
  }

  removeTeamLeaderProvince(index: number) {
    const control = <FormArray>this.teamLeaderForm.get('teamLeaderProvinces');
    if (control.length === 1) {
      return;
    }
    control.removeAt(index);
  }

  isTeamLeaderProvinceOptionDisabled(item: any): boolean {
    let hasEmptyId = false;
    for (const provinces of this.teamLeaderForm.value.teamLeaderProvinces) {
      if (provinces.province.id === item.id) {
        hasEmptyId = true;
      }
    }
    return hasEmptyId;
  }

}
