export function isRequired(control: any) {
    let validators: any[];
    try {
        validators = Object.keys(control.validator(''));
    }
    catch {
        return false;
    }
    if (!validators) return false;
    return validators.includes('required') ||
        validators.includes('requiredIfOtherHasValue') ||
        validators.includes('requiredIfAllEmpty');
}
