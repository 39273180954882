import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { PartnerPriorityListService } from 'src/app/services/partner-priority-list.service';

@Component({
  selector: 'app-partner-priority-list',
  templateUrl: './partner-priority-list.component.html',
  styleUrls: ['./partner-priority-list.component.scss']
})
export class PartnerPriorityListComponent implements OnInit {

  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'PartnerPriority.createdAt': 'DESC' };
  priorityLsitFilterForm: any;
  partnerPriorityLists: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private partnerPriorityListService: PartnerPriorityListService,
    private _snackBar: MatSnackBar
  ) { 
    this.managePartnerPriorityFilterForm();
  }

  async ngOnInit() {
    try {
      await this.loadPartnerPriorityList();
    } catch (error) {
      
    }
  }

  managePartnerPriorityFilterForm(){
    this.priorityLsitFilterForm = new FormGroup({
      'PartnerPriority.id': new FormControl(''),
      'Provinces.provinceCode': new FormControl(''),
      'ProductCategory.categoryName': new FormControl(''),
      'Partner.businessName': new FormControl(''),
    });
  }

  async filter(){
    let filterForm = (new FormGroupToObject()).transform(this.priorityLsitFilterForm);
    this.filtered = {};

    if (filterForm['PartnerPriority.id'])
      this.filtered['PartnerPriority.id'] = filterForm['PartnerPriority.id'];    
    if (filterForm['Provinces.provinceCode'])
      this.filtered['Provinces.provinceCode'] = { operator: 'like', values: [`%${filterForm['Provinces.provinceCode']}%`] };
    if (filterForm['ProductCategory.categoryName'])
      this.filtered['ProductCategory.categoryName'] = { operator: 'like', values: [`%${filterForm['ProductCategory.categoryName']}%`] };
    if (filterForm['Partner.businessName'])
      this.filtered['Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Partner.businessName']}%`] };

    this.loadPartnerPriorityList();
  } 

  async resetFilter(){
    this.managePartnerPriorityFilterForm();
    this.filter();
  }

  async loadPartnerPriorityList(limit?: number, page?: number, order?: {}){
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let data = await this.partnerPriorityListService.getAllPartnerPriorityLists(this.page, this.limit, this.order, this.filtered);
    this.partnerPriorityLists = data;
    this.count = this.partnerPriorityLists.length;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  async deletePartnerPriorityList(id: number){

    try {
      if (!confirm("Sei sicuro di voler eliminare l'elenco di priorità dei partner?")){ return; }
      let deleteResult = await this.partnerPriorityListService.deletePriorityListService(id);
      if(deleteResult){
        this._snackBar.open('Elenco priorità partner eliminato correttamente', 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(result => {
          this.loadPartnerPriorityList();
        })
      }
    } catch (error) {
      this._snackBar.open('Qualcosa è andato storto', 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      })
    }

  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadPartnerPriorityList();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

}
