import { AbstractControl, FormControl, Validators } from "@angular/forms";

export class PartnerPriorityListSchema {

    public static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            productCategory: new FormControl('', Validators.required),
            province: new FormControl('', Validators.required),
            // partnerPriorities: new FormControl([], Validators.required)
            partnerPriorities: new FormControl('')
        }
        return validator;
    }

    public static readonly validationMessages = {
        'productCategory': [{ type: 'required', message: 'Obbligatorio' }],
        'province': [{ type: 'required', message: 'Obbligatorio' }],
        'partner': [{ type: 'required', message: 'Obbligatorio' }],
    }

    constructor(
        public id?: number,
        public productCategory?: any,
        public province?: any,
        public partnerPriorities?: any,
    ){}
}