import { AbstractControl, FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { fileType } from '../helpers/validators/file-type.validator';

export class ServiceModel {
    public static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            serviceName: new FormControl('', Validators.required),
            pricingModel: new FormControl('', Validators.required),
            // price: new FormControl('', Validators.required),
            notes: new FormControl(''),
            image: new FormControl(
                undefined,
                fileType(['png', 'jpg', 'gif', 'jpeg'])
            ),
        };
        return validator;
    }

    public static readonly validationMessages = {
        'serviceName': [{ type: 'required', message: 'Obbligatorio' }],
        'pricingModel': [{ type: 'required', message: 'Obbligatorio' }],
        // 'price': [{ type: 'required', message: 'Obbligatorio' }],
        image: [
            { type: 'fileType', message: 'Formati accettati: png, jpg, gif, jpeg' },
        ],
    };

    constructor(
        public id?: number,
        public serviceName?: string,
        public pricingModel?: string,
        // public price?: any,
        public notes?: string,
        public image?: any,
    ) { }
}