import { Component, OnInit, ViewChild } from '@angular/core';
import { Partner, PartnerProvinces } from '../../models/partner.schema';
import { PaginationComponent } from '../_pagination/pagination.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../../services/partner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import {  UserActiveStatus } from '../../models/user.schema';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { TeamLeader, TeamLeaderProvinces } from 'src/app/models/team-leader.schema';
import { TeamLeaderService } from 'src/app/services/team-leader.service';
import { Expert, ExpertTeamLeaderProvinces } from 'src/app/models/expert.schema';
import { ExpertService } from 'src/app/services/expert.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  public partners: Partner[];
  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public order: {};
  @ViewChild(PaginationComponent, { static: false }) paginationComponent: PaginationComponent;
  public _partnerFilterForm;
  public filtered = {};
  public readonly activeStatus = UserActiveStatus;
  public groups: string[];
  teamLeader: TeamLeader;
  loggedInExpert: Expert;
  loggedInUserInfo: any;

  constructor(
    private partnerService: PartnerService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private router: Router,
    private authStatusService: AuthStatusService,
    private teamLeaderService: TeamLeaderService,
    private expertService: ExpertService,
    private spinnerService: SpinnerService,
  ) { 
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async managePartnerFilterForm(){
    this._partnerFilterForm = new FormGroup({
      'Partner.id': new FormControl(''),
      'Partner.businessName': new FormControl(''),
      'User.email': new FormControl(''),
      'ProductCategory.categoryName': new FormControl(''),
      'Partner.phone': new FormControl(''),
      'Partner.city': new FormControl(''),
      'Partner.address': new FormControl(''),
      'User.active': new FormControl(''),
    });
  }

  async ngOnInit() {
    // this.spinnerService.show();
    this.loggedInUserInfo = await this.authStatusService.getTokenInfo();
    if(this.groups.includes('expert') && this.loggedInUserInfo){
      this.loggedInExpert = await this.expertService.getOne(this.loggedInUserInfo['extraId']);
    }
    await this.loadPartners();
    await this.managePartnerFilterForm();
    // this.spinnerService.hide();
  }

  async filter() {
    let filterForm = (new FormGroupToObject()).transform(this._partnerFilterForm);
    this.filtered = {};
    if (filterForm['Partner.id'])
      this.filtered['Partner.id'] = filterForm['Partner.id'];
    if (filterForm['Partner.businessName'])
      this.filtered['Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Partner.businessName']}%`] };
    if (filterForm['User.email'])
      this.filtered['User.email'] = { operator: 'like', values: [`%${filterForm['User.email']}%`] };
    if (filterForm['Partner.phone'])
      this.filtered['Partner.phone'] = { operator: 'like', values: [`%${filterForm['Partner.phone']}%`] };
    if (filterForm['Partner.city'])
      this.filtered['Partner.city'] = { operator: 'like', values: [`%${filterForm['Partner.city']}%`] };
    if (filterForm['Partner.address'])
      this.filtered['Partner.address'] = { operator: 'like', values: [`%${filterForm['Partner.address']}%`] };
    if (filterForm['ProductCategory.categoryName'])
      this.filtered['ProductCategory.categoryName'] = { operator: 'like', values: [`%${filterForm['ProductCategory.categoryName']}%`] };
    if (filterForm['User.active'])
      this.filtered['User.active'] = filterForm['User.active'];
    await this.loadPartners();
  }

  async navigate(event) {
    await this.loadPartners(event.limit, event.page, this.order);
  }

  async loadPartners(limit?: number, page?: number, order?: {}) {
    try {
      this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || 1;
      this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || 200;
      this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || { 'Partner.businessName': 'ASC' };
      let partners = await this.partnerService.getAllWithoutTimeAva(this.page, this.limit, this.order, this.filtered);
      this.partners = partners.partners;
      if(this.groups.includes('admin')){
        this.partners = partners.partners;
        this.count = this.partners.length;
        this.maxPage = Math.ceil(this.count / this.limit);
      }
      if(this.groups.includes('teamleader') && this.loggedInUserInfo){
        this.teamLeader = await this.teamLeaderService.getOne(this.loggedInUserInfo['extraId']);
        if (this.teamLeader.teamLeaderProvinces.length > 0) {
          this.partners = this.partners.filter((partner: Partner) => {
            if(partner.partnerProvinces.length > 0){
              return partner.partnerProvinces.some((pProvince: PartnerProvinces) => {
                if(pProvince.province != null){
                  return this.teamLeader.teamLeaderProvinces.some((teamLeaderProvince: TeamLeaderProvinces) => {
                    return pProvince.province.provinceCode.toLowerCase() === teamLeaderProvince.province.provinceCode.toLowerCase()
                  });
                }
              });
            }
          });
          this.count = this.partners.length;
          this.maxPage = Math.ceil(this.count / this.limit);
        }
      }
      if(this.groups.includes('expert') && this.loggedInExpert){
        if (this.loggedInExpert.expertTeamLeaderProvinces.length > 0) {
          this.partners = this.partners.filter((partner: Partner) => {
            if(partner.partnerProvinces.length > 0){
              return partner.partnerProvinces.some((pProvince: PartnerProvinces) => {
                if(pProvince.province != null){
                  return this.loggedInExpert.expertTeamLeaderProvinces.some((expertTeamLeaderProvince: ExpertTeamLeaderProvinces) => {
                    return pProvince.province.provinceCode.toLowerCase() === expertTeamLeaderProvince.province.provinceCode.toLowerCase()
                  });
                }
              });
            }
          });
          this.count = this.partners.length;
          this.maxPage = Math.ceil(this.count / this.limit);
        }
      }
    } catch (error) {
      console.log("error",error)
    }
  }

  async resetFilter(){
    this.managePartnerFilterForm();
    this.filter();
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    delete this.order['Partner.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(async (data) => {
      await this.loadPartners();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public async delete(partnerId: number) {
    if (!confirm("Sei sicuro di voler cancellare questo partner?"))
      return;
    let deleteResult: { id: number | string, success: boolean } = await this.partnerService.delete(partnerId);
    let message = "Cancellazione del partner fallita.";
    if (deleteResult.success) {
      this.partners = this.partners.filter(partner => partner.id != deleteResult.id);
      message = "Cancellazione del partner avvenuta con successo.";
    }
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  translateActive(status) {
    return UserActiveStatus.find(as => as.value == status).ln.it;
  }

}
