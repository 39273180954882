import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Partner } from 'src/app/models/partner.schema';
import { ProductPriorityListSchema } from 'src/app/models/product-priority-list.schema';
import { Product } from 'src/app/models/product.schema';
import { PartnerService } from 'src/app/services/partner.service';
import { ProductPriorityListService } from 'src/app/services/product-priority-list.service';
import { ProductService } from 'src/app/services/product.service';
import { ProvincesService } from 'src/app/services/provinces.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-product-priority-list-form',
  templateUrl: './product-priority-list-form.component.html',
  styleUrls: ['./product-priority-list-form.component.scss']
})
export class ProductPriorityListFormComponent implements OnInit {

  partners: Partner[] = [];
  products: Product[] = [];
  provinces: any[] = [];
  productPriorityListId: string|number;
  productPriorityList: ProductPriorityListSchema;
  productPriorityListForm: any;
  formValidationMsgs: any;
  constructor(
    private readonly productPriorityListService: ProductPriorityListService,
    private readonly provincesService: ProvincesService,
    private readonly partnerService: PartnerService,
    private readonly productService: ProductService,
    private readonly spinnerService: SpinnerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { 
    this.manageProductPriorityListForm();
  }

  manageProductPriorityListForm(){
    this.productPriorityListForm = new FormGroup(ProductPriorityListSchema.validation());
    this.formValidationMsgs = ProductPriorityListSchema.validationMessages;
  }

  async ngOnInit(){
    try {
      this.spinnerService.show();
      await this.getActiveProducts();
      await this.getActivePartners();
      await this.getAllProvinces();
      await this.initialize();
      this.spinnerService.hide();
    } catch (error) {
      this.spinnerService.hide();
      console.log("NG ONINIT ERROR", error?.message);
    }
  }

  async initialize(){
    try {
      this.productPriorityListId = this.route.snapshot.paramMap.get('id');
      if (this.productPriorityListId) {
        try {
          this.productPriorityList = await this.productPriorityListService.getSingleProductPriorityList(Number(this.productPriorityListId));
        }
        catch {
          this.router.navigateByUrl("/404", { skipLocationChange: true });
        }
      }
      if (this.productPriorityList){
        this.patch();
      }
      else{
        this.productPriorityList = new ProductPriorityListSchema();
      }
    } catch (error) {
      console.log("ERROR IN INITIALIZE FUNCTION", error?.message);
    }
  }

  async patch(){
    try {
      ['', ''].forEach(param => {
        this.productPriorityListForm.controls[param].setValue(this.productPriorityList[param]?.id);
      });
    } catch (error) {
      console.log("ERROR IN PATCH FUNCTION", error?.message);
    }
  }

  async onSubmit(){
    if(this.productPriorityListForm.valid){
      console.log("FORM VALUES", this.productPriorityListForm.value);
    }
  }

  async getActiveProducts(){
    try {
      this.products = (await this.productService.getAllActiveProducts(undefined, undefined, { 'Product.name': 'ASC' })).products;
    } catch (error) {
      console.log("PRODUCT FETCHING ERROR", error?.message);
    }
  }

  async getActivePartners(){
    try {
      try {
        this.partners = (await this.partnerService.getAllActivePartners(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
      }
      catch { }
    } catch (error) {
      console.log("PARTNER FETCHING ERROR", error?.message);
    }
  }

  async getAllProvinces(){
    try {
      this.provinces = await this.provincesService.getAllProvinces();
    } catch (error) {
      console.log("ERROR IN FETCHING PROVINCES", error?.message)
    }
  }

}
