import { Component, OnInit, ViewChild } from '@angular/core';
import { OperationFormComponent } from '../operation-form/operation-form.component';

@Component({
  selector: 'app-operation-form-decorator',
  templateUrl: './operation-form-decorator.component.html',
  styleUrls: ['./operation-form-decorator.component.scss']
})
export class OperationFormDecoratorComponent implements OnInit {

  @ViewChild(OperationFormComponent, { static: false }) form: OperationFormComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
