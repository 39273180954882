import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SpinnerService } from './services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';
  blankRequested = false;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.blankRequested = route.root.firstChild.snapshot.data['blank'] ? true : false;
      }
    });
  }

  ngOnInit(): void {
    // Subscribe to the loader state
    this.spinnerService.loaderState$.subscribe((state: boolean) => {
      this.isLoading = state;

      // Manually trigger change detection to avoid the error
      this.cdr.detectChanges();
    });
  }
}
