import { AbstractControl, FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { Product } from './product.schema';
import { EnumAux } from '../helpers/enum-aux.helper';
import { moreThanOrEqual } from '../helpers/validators/compare.validator';

export enum OfferStatus {
    unpublished = 'unpublished',
    published = 'published',
    finished = 'finished',
}

export class OfferStatusAux extends EnumAux {
    static active = ['unpublished', 'published', 'finished'];

    static aux = {
        unpublished: { css: "red", ln: { it: "Non pubblicata" } },
        published: { css: "green", ln: { it: "Pubblicata" } },
        finished: { css: "yellow", ln: { it: "Terminata" } },
    }
}

export class Offer {
    public static validation(): { [key: string]: AbstractControl } {
        let today: any = new Date();
        today.setUTCHours(0);
        today.setUTCMinutes(0);
        today.setUTCSeconds(0);
        today.setUTCMilliseconds(0);
        let validator: { [key: string]: AbstractControl } = {
            name: new FormControl('', Validators.required),
            description: new FormControl(''),
            timeOffer: new FormControl(''),
            tryAndBuy: new FormControl(''),
            fromDate: new FormControl('', moreThanOrEqual(today, true)),
            toDate: new FormControl(''),
            status: new FormControl(OfferStatus.unpublished),
            notes: new FormControl(''),
            products: new FormArray([this.productFormGroup()]),
        };
        validator['toDate'].setValidators([
            moreThanOrEqual(today, true),
            moreThanOrEqual(validator.fromDate, true, 'fromDate')
        ]);
        return validator;
    }

    public static productFormGroup() {
        return new FormGroup({
            id: new FormControl(''),
        });
    }

    public static readonly validationMessages = {
        'name': [{ type: 'required', message: 'Obbligatorio' }],
        'fromDate': [
            { type: 'required', message: 'Obbligatoria' },
            { type: 'moreThanOrEqual', message: 'Non può essere nel passato' },
        ],
        'toDate': [
            { type: 'required', message: 'Obbligatoria' },
            { type: 'moreThanOrEqual', message: 'Non può essere nel passato' },
            { type: 'fromDate', message: 'Non può essere precedente alla pubblicazione' },
        ],
        'products': {
            'id': [{ type: 'required', message: 'Obbligatorio' }]
        },
    };

    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        public timeOffer?: boolean,
        public tryAndBuy?: boolean,
        public fromDate?: Date,
        public toDate?: Date,
        public status?: OfferStatus,
        public notes?: string,
        public products?: Product[],
        public createdAt?: Date,
        public updatedAt?: Date,
        public deletedAt?: Date,
    ) { }
}
