import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthStatusService } from '../../services/auth-status.service';
import { DeliveryService } from 'src/app/services/delivery.service';

@Injectable()
export class DeliveryCanActivateLogIn implements CanActivate {

    constructor(
        private authService: AuthStatusService,
        private router: Router,
        public jwtHelper: JwtHelperService,
        private deliveryService: DeliveryService,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const token = localStorage.getItem('access');
        if (token === null || token === undefined)
            return true;
        const res: boolean = await this.authService.isAuthenticated();
        if (!res) {
            this.router.navigate(['/login']);
            return false;
        }
        return res;
    }
}
