import { AbstractControl, FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { Delivery } from './delivery.schema';
import { Product } from './product.schema';
import { phoneValid } from '../helpers/validators/phone.validator';

export class ShopifySale {
    public static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            orderId: new FormControl(''),
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            channel: new FormControl(''),
            total: new FormControl(''),
            paymentStatus: new FormControl(''),
            fullFillmentStatus: new FormControl(''),
            deliveryMethod: new FormControl(''),
            address: new FormControl('', Validators.required),
            phone: new FormControl('', phoneValid()),
            deliveryDate: new FormControl('', Validators.required),
            vendor: new FormControl(''),
            // partner: new FormGroup({ name: new FormControl('') }),
            status: new FormControl(''),
            notes: new FormControl(''),
            intercom: new FormControl(''),
            deliveryTime: new FormControl(''),
            deliveryProducts: new FormArray([new FormGroup(DeliveryProduct.validation())]),
            senderSurname: new FormControl(''),
            senderName: new FormControl(''),
            senderPhone: new FormControl('', phoneValid()),
            ddtFile: new FormControl(undefined),
            ddtNumber: new FormControl(''),
            smsPhoneNo: new FormControl('', phoneValid()),
        };
        return validator;
    }

    public static productFormGroup() {
        return new FormGroup(DeliveryProduct.validation());
    }

    public static readonly validationMessages = {
        'firstName': [{ type: 'required', message: 'Obbligatorio' }],
        'lastName': [{ type: 'required', message: 'Obbligatorio' }],
        'address': [{ type: 'required', message: 'Obbligatorio' }],
        'receiverPhone': [{ type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }],
        'senderPhone': [{ type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }],
        'phone': [{ type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }],
        'smsPhoneNo': [{ type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }],
        'deliveryDate': [
            { type: 'required', message: 'Obbligatoria' },
            { type: 'moreThan', message: 'Non può essere nel passato' }
        ],
        'deliveryProducts': {
            'product': {
                'name': [{ type: 'required', message: 'Obbligatorio' }]
            },
        },
        'partner': {
            'id': [{ type: 'required', message: 'Obbligatorio' }]
        },
    };

    constructor(
        public id?: number,
        public orderId?: number,
        public firstName?: string,
        public lastName?: string,
        public channel?: string,
        public paymentStatus?: string,
        public fullFillmentStatus?: string,
        public deliveryMethod?: string,
        public address?: string,
        public phone?: string,
        public vendor?: string,
        public status?: string,
        public fromTime?: string,
        public toTime?: string,
        public notes?: string,
        public intercom?: string,
        public deliveryProducts?: DeliveryProduct[],
        public senderSurname?: string,
        public senderName?: string,
        public ddtFile?: string,
        public ddtNumber?: string,
        public smsPhoneNo?: string,
    ) { }
}

export class DeliveryProduct {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            product: this.productFormGroup(),
        };
        return validator;
    }

    public static productFormGroup() {
        return new FormGroup({
            name: new FormControl('', Validators.required),
            quantity: new FormControl('1', Validators.required),
            sku: new FormControl(''),
            price: new FormControl(''),
        });
    }

    constructor(
        public delivery?: Delivery,
        public product?: Product,
        public date?: Date,
        public quantity?: number,
        public price?: number,
        public updatedAt?: Date,
        public createdAt?: Date,
    ) { }
}