import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export function equalContent(eqElements: { value: [AbstractControl] }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let value = control.value;
        let eqFields = eqElements.value.map((elem: AbstractControl) => {
            return value ? elem.value === value : !!elem.value;
        });
        if (!eqFields.includes(!value))
            return null;
        return {
            equalContent: { condition: eqElements.value }
        };
    }
}
