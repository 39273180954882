import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';
import { Operation } from '../models/operation.schema';

@Injectable({
  providedIn: 'root'
})
export class OperationService extends ApiAuthService {

  private readonly url = `${this.baseUrl}/operation`;
  
  async getAllOperations(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<any> {
    return this.getAll(page, limit, order, filter);
  }
  
  async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<any>{
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.get(`${this.url}`, { params: params }).toPromise() );
  }

  async getOne(id: string|number): Promise<Operation> {
    return this.request(() => this.http.get<Operation>(`${this.url}/${id}`).toPromise());
  }

  async create(operationParams: Operation): Promise<Operation> {
    let body = operationParams;
    return this.request(() => this.http.post<Operation>(`${this.url}`, body).toPromise());
  }

  async update(id: number, operationParams: Operation): Promise<Operation> {
    let body = operationParams;
    return this.request(() => this.http.put<Operation>(`${this.url}/${id}`, body).toPromise());
  }

  async updateMe(operationParams: Operation): Promise<Operation> {
    let body = operationParams;
    return this.request(() => this.http.put<Operation>(`${this.url}`, body).toPromise());
  }

  async delete(id: number): Promise<{ id: number, success: boolean }> {
    return this.request(() => this.http.delete<{ id: number, success: boolean }>(`${this.url}/${id}`).toPromise());
  }

}
