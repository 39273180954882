import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCategory } from 'src/app/models/product-category.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { ProvincesService } from 'src/app/services/provinces.service';

@Component({
  selector: 'app-product-category-form',
  templateUrl: './product-category-form.component.html',
  styleUrls: ['./product-category-form.component.scss']
})
export class ProductCategoryFormComponent implements OnInit {

  productCategoryForm: any;
  formValidationMsgs: any;
  productCategory: ProductCategory;
  productCategoryId: string|number = null;
  
  constructor(
    private productCategoryService: ProductCategoryService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private provincesService: ProvincesService,
    private fb: FormBuilder
  ) { 
    this.handleProductCategoryForm();
  }

  async ngOnInit() { 
    this.productCategoryId = this.route.snapshot.paramMap.get('id');
    if (this.productCategoryId) {
      try {
        this.productCategory = await this.productCategoryService.getProductCategory(Number(this.productCategoryId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.productCategory)
      this.patch();
    else
      this.productCategory = new ProductCategory();
  }

  handleProductCategoryForm(){
    this.productCategoryForm = new FormGroup(ProductCategory.validation());
    this.formValidationMsgs = ProductCategory.validationMessages;
  }

  patch(){
    ['categoryName', 'notes'].forEach(param => {
      this.productCategoryForm.controls[param].setValue(this.productCategory[param]);
    });
  }

  async onSubmit(){
    if(this.productCategoryForm.valid){
      let productCategory: ProductCategory = new FormGroupToObject().transform(this.productCategoryForm);
      let promiseResult: any
      if (this.productCategory['id']) {
        promiseResult = this.productCategoryService.updateProductCategory(this.productCategory['id'], productCategory);
      } else {
        promiseResult = this.productCategoryService.createProductCategory(productCategory);
      }
      let message: string = this.productCategory['id'] ? "Prodotti Categoria aggiornato" : "Prodotti Categoria creato";
      promiseResult.then(async (response: any) => {
        await this.showSnackBar(message);
        await this.router.navigate(['/product/categoria']);
      }).catch(async (error: any) => {
        await this.showSnackBar(error.error.message);
      });
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

}
