import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Expert } from 'src/app/models/expert.schema';
import { Partner } from 'src/app/models/partner.schema';
import { ExpertPriorityListSchema } from 'src/app/models/expert-priority-list.schema';
import { ExpertService } from 'src/app/services/expert.service';
import { PartnerService } from 'src/app/services/partner.service';
import { ProvincesService } from 'src/app/services/provinces.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { ValetPriorityListService } from 'src/app/services/valet-priority-list.service';

@Component({
  selector: 'app-duplicate-valet-priority-list',
  templateUrl: './duplicate-valet-priority-list.component.html',
  styleUrls: ['./duplicate-valet-priority-list.component.scss']
})
export class DuplicateValetPriorityListComponent implements OnInit {

  priorityListForm: any;
  formValidationMsgs: any;
  priorityListId: number|string;
  priorityList: any;
  partners: Partner[] = [];
  provinces: any[] = [];
  experts: Expert[] = [];
  allExperts: Expert[] = [];
  selectedExpert: number;
  dragAndDropExpertsList: any[] = [];

  constructor(
    private partnerService: PartnerService,
    private expertService: ExpertService,
    private provincesService: ProvincesService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private valetPriorityListService: ValetPriorityListService,
    private route: ActivatedRoute
  ) { 
    this.managePriorityListForm();
  }

  managePriorityListForm(){
    this.priorityListForm = new FormGroup(ExpertPriorityListSchema.validation());
    this.formValidationMsgs = ExpertPriorityListSchema.validationMessages;
  }

  async ngOnInit() { 
    try {
      await this.getAllActivePartners();
      await this.getAllProvinces();
      await this.getAllActiveExperts();
    } catch (error) { }
    await this.initialize();
  }

  async getAllActivePartners(){
    let result = await this.partnerService.getAllActivePartners();
    this.partners = result.partners;
  }

  async getAllActiveExperts(){
    let result = await this.expertService.getAllActiveExperts();
    this.experts = result.experts;
    this.allExperts = result.experts;
  }

  async getAllProvinces(){
    this.provinces = await this.provincesService.getAllProvinces();
  }

  addExpertInDropList(){
    if(this.selectedExpert === undefined || this.selectedExpert === null){ alert("Seleziona un valet qualsiasi"); return; }
    const selectedExpert = this.experts.find(p => p.id === this.selectedExpert);
    this.dragAndDropExpertsList.push(selectedExpert);
    this.experts = this.experts.filter(partner => partner.id !== this.selectedExpert);
    this.selectedExpert = null;
  }

  removeExpertFromDropList(index: number, expertId: number){
    this.dragAndDropExpertsList.splice(index,1);
    const removedExpert = this.allExperts.find(p => p.id === expertId);
    this.experts.push(removedExpert);
  }

  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dragAndDropExpertsList, event.previousIndex, event.currentIndex);
    if (this.priorityList['id']) {
      await this.updateWithDrag();
    }
  }

  async updateWithDrag(){
    let priorityFormData: any = (new FormGroupToObject()).transform(this.priorityListForm);
    const transformedArray = this.dragAndDropExpertsList.map((item, index) => {
      return {
        expert: {
          id: item.id,
          order: index + 1
        }
      };
    });
    priorityFormData.expertPriorities = transformedArray;
    let promiseResult: any = this.valetPriorityListService.updateExpertPriorityList(this.priorityList['id'], priorityFormData);
    promiseResult.then(async (response: any) => {
      this.dragAndDropExpertsList = [];
      await this.showSnackBar('Elenco di priorità dei valet riordinato');
      await this.router.navigate(['valet/priority-list/edit', response?.id]);
      await this.initialize();
    }).catch(async (error: any) => {
      await this.showSnackBar(error.error.message);
    });
  }

  async initialize(){
    this.priorityListId = this.route.snapshot.paramMap.get('id');
    if (this.priorityListId) {
      try {
        this.priorityList = await this.valetPriorityListService.getExpertPriorityList(Number(this.priorityListId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.priorityList){
      this.patch();
    }
    else{
      this.priorityList = new ExpertPriorityListSchema();
    }
  }

  patch(){

    ['partner', 'province'].forEach(param => {
      this.priorityListForm.controls[param].setValue(this.priorityList[param]?.id);
    });

    if(this.priorityList?.expertPriorities?.length > 0){
      this.priorityListForm.patchValue({
        expertPriorities: this.priorityList?.expertPriorities.map((data) => {
          this.dragAndDropExpertsList.push(data?.expert)
          this.experts = this.experts.filter(expert => expert.id !== data?.expert?.id);
          return data.expert.id
        })
      });
    }

  }

  async onSubmit(){
    if(this.priorityListForm.valid){
      
      let priorityFormData: any = (new FormGroupToObject()).transform(this.priorityListForm);
      let promiseResult: any;

      const transformedArray = this.dragAndDropExpertsList.map((item, index) => {
        return {
          expert: {
            id: item.id,
            order: index + 1
          }
        };
      });
      priorityFormData.expertPriorities = transformedArray;
      promiseResult = this.valetPriorityListService.createExpertPriorityList(priorityFormData);

      let message: string = this.priorityList['id'] ? "Elenco priorità dei valet aggiornato" : "Elenco priorità valet creato";
      promiseResult.then(async (response: any) => {
        await this.showSnackBar(message);
        await this.router.navigate(['/valet/priority-list']);
      }).catch(async (error: any) => {
        await this.showSnackBar(error.error.message);
      });

    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }
  

}
