import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthStatusService } from '../../services/auth-status.service';

@Injectable()
export class CanActivateLogIn implements CanActivate {

    constructor(private authService: AuthStatusService, private router: Router, public jwtHelper: JwtHelperService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const res: boolean = await this.authService.isAuthenticated();
        if (!res) {
            this.router.navigate(['/login']);
            return false;
        }
        return res;
    }
}
