import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthStatusService } from '../../services/auth-status.service';

@Injectable()
export class GroupRestricted implements CanActivate {

    constructor(private authService: AuthStatusService, private router: Router, public jwtHelper: JwtHelperService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const groups: string[] = route.data.groups.map((elem: string) => elem.toLowerCase());
        let included: boolean = false;
        let userGroups: string[] = this.authService.getRoles().map((elem: string) => elem.toLowerCase());
        groups.forEach(group => {
            if (userGroups.includes(group))
                included = true;
        });
        if (!included)
            this.router.navigate(['/404']);
        return included;
    }
}
