import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { BusinessSaleService } from 'src/app/services/business-sale.service';
import { CakeSaleService } from 'src/app/services/cake-sale.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { FlowersSaleService } from 'src/app/services/flowers-sale.service';
import { PartnerSaleActivityService } from 'src/app/services/partner-sale-activity.service';
import { ShopifySaleService } from 'src/app/services/shopify-sale.service';

@Component({
  selector: 'app-partner-sale-activity',
  templateUrl: './partner-sale-activity.component.html',
  styleUrls: ['./partner-sale-activity.component.scss']
})
export class PartnerSaleActivityComponent implements OnInit {
  
  groups: any;
  limit: number;
  page: number;
  count: number = 0;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'PartnerShopifySaleActivity.id': 'DESC' };
  filtered = {};
  partnerShopifySaleActivities: any[] = [];
  activityFilterForm: any;
  selected: string = 'active';
  salesToBeConfirm: any[] = [];
  saleBtn: boolean = false;
  settings = {
    active: {
      title: "Partner Vendita",
    },
    inactive: {
      title: "Storico Partner Vendita",
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private partnerSaleActivityService: PartnerSaleActivityService,
    private _snackBar: MatSnackBar,
    private deliveryService: DeliveryService,
    private shopifySaleService: ShopifySaleService,
    private cakeSaleService: CakeSaleService,
    private businessSaleService: BusinessSaleService,
    private flowersSaleService: FlowersSaleService,
  ) {
    this.manageSaleActivityFilterForm();
  }

  async ngOnInit() {
    try {
      this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
      await this.init();
    } catch (error) {
      
    }
  }

  async init(){
    this.selected == 'active' ? 
    await this.fetchVenditaToConfirm() :
    await this.loadPartnerSaleActivities();
  }

  async fetchVenditaToConfirm(){
    try {
      let allSalesToBeConfirm = await this.partnerSaleActivityService.fetchVenditaToConfirm();
      this.salesToBeConfirm = allSalesToBeConfirm.allSaleDelivery;
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  manageSaleActivityFilterForm(){
    this.activityFilterForm = new FormGroup({
      'PartnerShopifySaleActivity.id': new FormControl(''),
      'PartnerShopifySaleActivity.saleId': new FormControl(''),
      'PartnerShopifySaleActivity.orderId': new FormControl(''),
      'PartnerShopifySaleActivity.status': new FormControl(''),
      'PartnerShopifySaleActivity.shop': new FormControl(''),
    });
  }

  async loadPartnerSaleActivities(limit?: number, page?: number, order?: {}){
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let data = await this.partnerSaleActivityService.getAllPartnerSaleActivities(this.page, this.limit, this.order, this.filtered);
    this.partnerShopifySaleActivities = data;
    this.count = this.partnerShopifySaleActivities.length;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  async deletePartnerShopifySaleActivity(activityId: number){
    try {
      if (!confirm("Sei sicuro di voler eliminare la tua vendita?")){ return; }
      let deleteResult = await this.partnerSaleActivityService.deletePartnerSaleActivity(activityId);
      if(deleteResult){
        this._snackBar.open('Vendita eliminata con successo', 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(result => {
          this.loadPartnerSaleActivities();
        })
      }
    } catch (error) {
      this._snackBar.open('Qualcosa è andato storto', 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      })
    }

  }

  async filter(){
    let filterForm = (new FormGroupToObject()).transform(this.activityFilterForm);
    this.filtered = {};

    if (filterForm['PartnerShopifySaleActivity.id'])
      this.filtered['PartnerShopifySaleActivity.id'] = filterForm['PartnerShopifySaleActivity.id'];    
    if (filterForm['PartnerShopifySaleActivity.saleId'])
      this.filtered['PartnerShopifySaleActivity.saleId'] = filterForm['PartnerShopifySaleActivity.saleId'];
    if (filterForm['PartnerShopifySaleActivity.orderId'])
      this.filtered['PartnerShopifySaleActivity.orderId'] = filterForm['PartnerShopifySaleActivity.orderId'];
    if (filterForm['PartnerShopifySaleActivity.status'])
      this.filtered['PartnerShopifySaleActivity.status'] = { operator: 'like', values: [`%${filterForm['PartnerShopifySaleActivity.status']}%`] };
    if (filterForm['PartnerShopifySaleActivity.shop'])
      this.filtered['PartnerShopifySaleActivity.shop'] = { operator: 'like', values: [`%${filterForm['PartnerShopifySaleActivity.shop']}%`] };

    this.loadPartnerSaleActivities();
  }

  showShopName(sale: any){
    let deliveryInfo = JSON.parse(sale.deliveryInfo);
    return deliveryInfo?.shop?.toUpperCase();
  }

  public async acceptSaleForUniqueProduct(deliveryInfo: any){
    if (!confirm("Accetti la nuova consegna?"))
      return;
    let deliveryInformation = JSON.parse(deliveryInfo);
    await this.manageSaleForUniqueProduct(deliveryInformation?.id, 'accept');
  }

  public async refuseSaleForUniqueProduct(deliveryInfo: any){
    if (!confirm("Rifiuti la nuova consegna?"))
      return;
    let deliveryInformation = JSON.parse(deliveryInfo);
    await this.manageSaleForUniqueProduct(deliveryInformation?.id, 'refuse');
  }

  async manageSaleForUniqueProduct(deliveryId: number, status: string){
    this.saleBtn = true;
    let promise = this.deliveryService.manageSaleForUniqueProduct(deliveryId, status);
    promise.then(async (res) => {
      this.saleBtn = false;
      await this.init();
    }).catch((err) => {
      this.saleBtn = false;
    })
  }

  async acceptSaleForNonUniqueProduct(token: string, deliveryInfo: any){
    try {
      if (!confirm("Confermi di accettare la vendita?"))
        return;
      let result: any;
      let deliveryInformation = JSON.parse(deliveryInfo);
      if(deliveryInformation?.shop){
        if(deliveryInformation?.shop?.toLowerCase() === 'shopifysale'){
          result = await this.shopifySaleService.acceptSale(token, undefined, deliveryInformation);
        }
        if(deliveryInformation?.shop?.toLowerCase() === 'cakesales'){
          result = await this.cakeSaleService.acceptSale(token, undefined, deliveryInformation);
        }
        if(deliveryInformation?.shop?.toLowerCase() === 'flowerssales'){
          result = await this.flowersSaleService.acceptSale(token, undefined, deliveryInformation);
        }
        if(deliveryInformation?.shop?.toLowerCase() === 'businesssales'){
          result = await this.businessSaleService.acceptSale(token, undefined, deliveryInformation);
        }
      }

      if(result){
        this._snackBar.open('Sale has been accepted', 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(async (res) => {
          if(result?.id){
            this.router.navigate(['/consegne', result?.id]).then(() => {
              window.location.reload();
            });
          }
        })
      } else {
        this.showSnackBar('Something went wrong');
      }
    } catch (error) {
      if(error.error.message === 'ALREDAY_ACCEPTED'){
        this.showSnackBar('Sale has been already accepted');
      } else if(error.error.message === 'SALE_SERVICE_NOT_AVAILABLE') {
        this.showSnackBar('Partner has not sale type service. Can not accept sale');
      } else {}
    }
  }

  async refuseSaleForNonUniqueProduct(token: string, deliveryInfo: any){
    try {
      if (!confirm("Confermi di rifiutare la vendita?"))
        return;
      let result: any;
      let deliveryInformation = JSON.parse(deliveryInfo);
      if(deliveryInformation?.shop){
        if(deliveryInformation?.shop?.toLowerCase() === 'shopifysale'){
          result = await this.shopifySaleService.refuseSale(token, undefined);
        }
        if(deliveryInformation?.shop?.toLowerCase() === 'cakesales'){
          result = await this.cakeSaleService.refuseSale(token, undefined);
        }
        if(deliveryInformation?.shop?.toLowerCase() === 'flowerssales'){
          result = await this.flowersSaleService.refuseSale(token, undefined);
        }
        if(deliveryInformation?.shop?.toLowerCase() === 'businesssales'){
          result = await this.businessSaleService.refuseSale(token, undefined);
        }
      }
      if(result){
        await this.init();
        this.showSnackBar('Sale has been refused');
      }
    } catch (error) {
      this.showSnackBar('Something went wrong');
    }
  }
  
  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  async resetFilter(){
    this.manageSaleActivityFilterForm();
    this.filter();
  }

  navigate(event) {
    this.loadPartnerSaleActivities(event.limit, event.page, this.order);
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    if(this.order){
      for (let attr of attribute) {
        switch (this.order[attr]) {
          case 'ASC':
            return 'fa-sort-up';
          case 'DESC':
            return 'fa-sort-down';
          default:
            return 'fa-sort';
        }
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    if(this.order){
      delete this.order['Delivery.updatedAt'];
    }
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    if(this.order){
      for (let attr of attribute) {
        switch (this.order[attr]) {
          case 'ASC':
            this.order[attr] = 'DESC';
            break;
          case 'DESC':
            this.order[attr] = undefined;
            break;
          default:
            this.order = {}
            this.order[attr] = 'ASC';
            break;
        }
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadPartnerSaleActivities();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

}
