import { Component, OnInit } from '@angular/core';
import { AuthStatusService } from '../../services/auth-status.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public group: string;

  constructor(
    private authStatusService: AuthStatusService,
  ) {
    this.group = this.authStatusService.getRoles()[0].toLowerCase();
  }

  ngOnInit() {
  }

}
