import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { Product } from '../models/product.schema';

@Injectable({
  providedIn: 'root'
})
export class CommanService  extends ApiAuthService { 

  private readonly url = `${this.baseUrl}/comman`;

  
  createProductWithShopifyProduct(productParams: any): Promise<Product> {
    let body = productParams;
    return this.request(() =>
      this.http.post<any>(`${this.url}`, body).toPromise()
    );
  }
}
