import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { BusinessSaleService } from 'src/app/services/business-sale.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { BusinessSaleFormDialogComponent } from '../business-sale-form-dialog/business-sale-form-dialog.component';

@Component({
  selector: 'app-business-sale',
  templateUrl: './business-sale.component.html',
  styleUrls: ['./business-sale.component.scss']
})
export class BusinessSaleComponent implements OnInit {

  limit: number;
  page: number;
  count: number;
  maxPage: number;
  filtered: {};
  orders: string[];
  businessSaleForm: any;
  order: {};
  myjson:any=JSON;
  groups: any;
  selected: string = 'active';
  settings = {
    active: {
      title: "Business Vendita",
    },
    inactive: {
      title: "Storico Business Vendita",
    }
  };
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'BusinessSales.actualOrderId': 'DESC' };
  btnDisable: boolean = false;
  
  constructor(
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private deliveryService: DeliveryService,
    private businessSaleService: BusinessSaleService,
  ) { 
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.manageBusinessSalesFilterForm();
  }

  async ngOnInit(){
    this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
    await this.filter();
  }

  async manageBusinessSalesFilterForm(){
    this.businessSaleForm = new FormGroup({
      'BusinessSales.orderId': new FormControl(''),
      'BusinessSales.actualOrderId': new FormControl(''),
      'BusinessSales.firstName': new FormControl(''), 
      'BusinessSales.deliveryDate_from': new FormControl(''), 
      'BusinessSales.deliveryDate_to': new FormControl(''),   
      'BusinessSales.lastName': new FormControl(''),  
      'BusinessSales.channel': new FormControl(''),
      'BusinessSales.total': new FormControl(''),
      'BusinessSales.paymentStatus': new FormControl(''),
      'BusinessSales.fullFillmentStatus': new FormControl(''),
      'BusinessSales.deliveryMethod': new FormControl(''),
      'BusinessSales.address': new FormControl(''),
      'BusinessSales.pickUpTime': new FormControl(''),
      'BusinessSales.phone': new FormControl(''),
      'BusinessSales.deliveryDate': new FormControl(''),
      'BusinessSales.vendor': new FormControl(''),
      'BusinessSales.status': new FormControl(''),
    });
  }

  async filter() {

    let filterForm = (new FormGroupToObject()).transform(this.businessSaleForm);
    this.filtered = {};
    if (filterForm['BusinessSales.orderId'])
      this.filtered['BusinessSales.orderId'] = filterForm['BusinessSales.orderId'];    
    if (filterForm['BusinessSales.actualOrderId'])
      this.filtered['BusinessSales.actualOrderId'] = filterForm['BusinessSales.actualOrderId'];    
    if (filterForm['BusinessSales.firstName'])
      this.filtered['BusinessSales.firstName'] = { operator: 'like', values: [`%${filterForm['BusinessSales.firstName']}%`] };
    if (filterForm['BusinessSales.channel'])
      this.filtered['BusinessSales.channel'] = { operator: 'like', values: [`%${filterForm['BusinessSales.channel']}%`] };
    if (filterForm['BusinessSales.total'])
      this.filtered['BusinessSales.total'] = { operator: 'like', values: [`%${filterForm['BusinessSales.total']}%`] };
    if (filterForm['BusinessSales.paymentStatus'])
      this.filtered['BusinessSales.paymentStatus'] = { operator: 'like', values: [`%${filterForm['BusinessSales.paymentStatus']}%`] };
    if (filterForm['BusinessSales.fullFillmentStatus'])
      this.filtered['BusinessSales.fullFillmentStatus'] = { operator: 'like', values: [`%${filterForm['BusinessSales.fullFillmentStatus']}%`] };
    if (filterForm['BusinessSales.deliveryMethod'])
      this.filtered['BusinessSales.deliveryMethod'] = { operator: 'like', values: [`%${filterForm['BusinessSales.deliveryMethod']}%`] };
    if (filterForm['BusinessSales.address'])
      this.filtered['BusinessSales.address'] = { operator: 'like', values: [`%${filterForm['BusinessSales.address']}%`] };
    if (filterForm['BusinessSales.pickUpTime'])
      this.filtered['BusinessSales.pickUpTime'] = { operator: 'like', values: [`%${filterForm['BusinessSales.pickUpTime']}%`] };
    if (filterForm['BusinessSales.phone'])
      this.filtered['BusinessSales.phone'] = { operator: 'like', values: [`%${filterForm['BusinessSales.phone']}%`] };
    if (filterForm['BusinessSales.deliveryDate_from'])
      this.filtered['BusinessSales.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['BusinessSales.deliveryDate_from']] };
    if (filterForm['BusinessSales.deliveryDate_to'])
      this.filtered['BusinessSales.deliveryDate'] = { operator: 'lessThan', values: [filterForm['BusinessSales.deliveryDate_to']] };
    if (filterForm['BusinessSales.deliveryDate_from'] && filterForm['BusinessSales.deliveryDate_to'])
      this.filtered['BusinessSales.deliveryDate'] = { operator: 'between', values: [filterForm['BusinessSales.deliveryDate_from'], filterForm['BusinessSales.deliveryDate_to']] };
    if (filterForm['BusinessSales.vendor'])
      this.filtered['BusinessSales.vendor'] = { operator: 'like', values: [`%${filterForm['BusinessSales.vendor']}%`] };
    if (filterForm['BusinessSales.status'])
      this.filtered['BusinessSales.status'] = { operator: 'like', values: [`%${filterForm['BusinessSales.status']}%`] };

    await this.getBusinessSalesOrders();
  }

  async getBusinessSalesOrders(limit?: number, page?: number, order?: {}){

    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let promiseResult = null;
    promiseResult = this.selected == 'active' ? 
      this.businessSaleService.getBusinessSaleOrders(this.page, this.limit, this.order, this.filtered) :
      this.businessSaleService.getBusinessSaleOrdersHistory(this.page, this.limit, this.order, this.filtered);
    promiseResult.then((res) => {
      this.orders = res.orders;
      this.count = res.count;
      this.maxPage = Math.ceil(this.count / this.limit);
    }).catch((err) => {
      console.log("err", err)
    })
  }

  async confirmSale(saleOrder: any) {
    if(this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('teamleader')){
      /*If Sale if unique then not confirm sale because partner is still accept or reject the sale*/
      let delivery = await this.deliveryService.getDeliveryWithOrderId(saleOrder?.orderId, saleOrder?.realOrderNumber);
      if(delivery){
        this._snackBar.open(`This is unique product sale. Delivery has been created. Partner is still accpet or reject the sale`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
        return false;
      }
      /*If Sale if unique then not confirm sale because partner is still accept or reject the sale*/
      let ref = this.dialog.open(BusinessSaleFormDialogComponent, { width: '80vw', data: { saleOrder } });
      ref.afterClosed().subscribe(async (result) => {
        if (result?.delivery){
          await this.getBusinessSalesOrders();
        }
      });
    } else {
      return false;
    }
  }

  async refuseSale(orderId: number){
    if(!confirm(`Sei sicuro di voler rifiutare l'ordine di Shopify?`))
      return false;
    let promiseResult = await this.businessSaleService.refuseSaleByAdmin(orderId);
    if(promiseResult.success){
      this._snackBar.open(`L'ordine di Shopify è stato rifiutato. Puoi controllare nella cronologia`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      await this.getBusinessSalesOrders();
    } else {
      this._snackBar.open(`Qualcosa è andato storto`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }
  }


  async resetFilter() {
    await this.manageBusinessSalesFilterForm();
    await this.filter();
  }

  async navigate(event) {
    await this.getBusinessSalesOrders(event.limit, event.page, this.order);
  }

  async stopSaleAutomation(sale: any){
    if(!confirm(`Are You Sure to Stop Sale Automation ${sale?.platform?.toLowerCase()}?`))
      return false;
    try {
      this.btnDisable = true;
      let id: number = sale?.id;
      let promiseResult = await this.businessSaleService.stopSaleAutomation(id, sale);

      if(promiseResult.success){
        this._snackBar.open(`Sale Automation has been Stopped.`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
      } else {
        this._snackBar.open(`Something Went Wrong`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
      }
      await this.getBusinessSalesOrders();
    } catch (error) {
      this.btnDisable = false;
      this._snackBar.open(`Something Went Wrong ${error?.error?.message}`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }

  }

  public async navigateOrder(attribute: string | string[]) {
    delete this.order['BusinessSales.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    await this.navigatePage(this.limit, this.page, { order: this.order });
  }

  async navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(async (data) => {
      await this.getBusinessSalesOrders();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

}
