import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent {
  constructor(private route: ActivatedRoute, private userService: UserService, private router: Router) {
    console.log("404", this.route.snapshot.data["blank"]);
  }

  async ngOnInit() {
    try {
      const user = await this.userService.getMyInformation();
      if(user){
        this.router.navigate(['/consegne']);
      }
    } catch (error) {
      if (error.message === "UNAUTHORIZED" || error) {
        this.router.navigate(['/login']);
      }
    }
  }
}
