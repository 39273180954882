import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({
  providedIn: 'root'
})
export class PartnerSaleActivityService extends ApiAuthService {
  private readonly url = `${this.baseUrl}/partner-sale-activity`;

  async getAllPartnerSaleActivities(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<any> {
    return this.getAll(page, limit, order, filter);
  }

  async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<any>{
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.get(`${this.url}`, { params: params }).toPromise() );
  }

  async deletePartnerSaleActivity(activityId: number): Promise<any>{
    return this.request(() => this.http.delete(`${this.url}/${activityId}` ).toPromise() );
  }

  async fetchVenditaToConfirm(): Promise<any>{
    return this.request(() => this.http.get(`${this.url}/to-confirm`).toPromise() ); 
  }


}
