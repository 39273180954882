import { Component, OnInit, ViewChild } from '@angular/core';
import { SmsTemplate } from '../../models/sms-template.schema';
import { PaginationComponent } from '../_pagination/pagination.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStatusService } from '../../services/auth-status.service';
import { FormArray, FormGroup } from '@angular/forms';
import { SmsTemplatesService } from '../../services/sms-templates.service';

@Component({
  selector: 'app-sms-templates',
  templateUrl: './sms-templates.component.html',
  styleUrls: ['./sms-templates.component.scss']
})
export class SmsTemplatesComponent implements OnInit {

  public smsTemplates: SmsTemplate[];
  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public order: {};
  @ViewChild(PaginationComponent, { static: false }) paginationComponent: PaginationComponent;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private smsTemplateService: SmsTemplatesService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.loadSmsTemplates();
  }

  async loadSmsTemplates(){
    let smsTemp = await this.smsTemplateService.getAllSmsTemplates();
    this.smsTemplates = smsTemp.smsTemplates;
    this.count = smsTemp.count;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  async delete(smsTempId: number){
    if (!confirm("Sei sicuro di voler eliminare questo modello di sms?"))
      return;
    let deleteResult: { id: number | string, success: boolean } = await this.smsTemplateService.delete(smsTempId);
    let message = "L'eliminazione del modello SMS non è riuscita.";
    if (deleteResult.success){
      this.smsTemplates = this.smsTemplates.filter(smsTemp => smsTemp.id != deleteResult.id);
      message = "Annullamento riuscito del modello di sms.";
    }
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
    if (deleteResult.success)
      this.loadSmsTemplates();
  }

  filter(){
    console.log("Filter function called");
  }

}
