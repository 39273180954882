import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ShopifySaleFormDialogComponent } from '../shopify-sale-form-dialog/shopify-sale-form-dialog.component';

@Component({
  selector: 'app-delivery-import-dialog',
  templateUrl: './delivery-import-dialog.component.html',
  styleUrls: ['./delivery-import-dialog.component.scss']
})
export class DeliveryImportDialogComponent implements OnInit {
  
  public deliveries: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { importDeliveries: [] },
    private dialogRef: MatDialogRef<ShopifySaleFormDialogComponent>,
    private deliveryService: DeliveryService,
  ) { }

  async ngOnInit() {
    this.deliveries = this.data.importDeliveries;
    console.log('this.deliveries :>> ', this.deliveries);
  }

  async handleImport(){
    let promise = this.deliveryService.import_product_via_csv(this.deliveries);
    promise.then((res) => {
      this.dialogRef.close({ data:  res});
    }).catch((err) => {
      console.log('err :>> ', err);
    })
  }

}
