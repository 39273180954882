export function roundNumber(num, digits, mode: 'r' | 'f' | 'c' = 'r') {
    const divisor = Math.pow(10, digits);
    switch (mode) {
        case 'r':
            return Math.round(num * divisor) / divisor;
        case 'f':
            return Math.floor(num * divisor) / divisor;
        case 'c':
            return Math.ceil(num * divisor) / divisor;
    }
}
