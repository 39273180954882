import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Operation } from 'src/app/models/operation.schema';
import { User } from 'src/app/models/user.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { OperationService } from 'src/app/services/operation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-operation-form',
  templateUrl: './operation-form.component.html',
  styleUrls: ['./operation-form.component.scss']
})
export class OperationFormComponent implements OnInit {

  @Input('profile') public profile: boolean = false;
  public readonly formValidationMsgs;
  public operationForm: any;
  private checkedPassword1: boolean;
  private checkedPassword2: boolean;
  public tmpBtnDisabled: boolean;
  public groups: string[];
  public operation: Operation;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private authStatusService: AuthStatusService,
    private operationService: OperationService,
  ) { 
    this.formValidationMsgs = Operation.validationMessages;
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    this.operationForm = new FormGroup(Operation.validation(this.profile));
    const expertId = this.profile ?
      (await this.operationService.getOne(this.authStatusService.getExtra()['id'])).id.toString() :
      this.route.snapshot.paramMap.get('id');
    if (expertId) {
      try {
        this.operation = await this.operationService.getOne(expertId);
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }

    if (!this.operation) {
      this.operation = new Operation();
      this.operation.user = this.route.snapshot.paramMap.get('userId') ?
        await this.userService.getOne(this.route.snapshot.paramMap.get('userId')) :
        new User();
    }

    this.patch();

    if (this.profile)
      this.setProfileValidators();
  }

  patch() {
    ['phone', 'address', 'notes', 'longitude', 'latitude'].forEach(param => {
      this.operationForm.controls[param].setValue(this.operation[param]);
    });
    ['surname', 'name', 'email'].forEach(param => {
      this.operationForm.controls.user.controls[param].setValue(this.operation.user[param]);
    });
  }

  setProfileValidators() {
    this.checkedPassword1 = false;
    this.checkedPassword2 = false;

    this.operationForm.controls.user.controls.password1.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword1) {
        this.checkedPassword1 = true;
        this.operationForm.controls.user.controls.password2.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });

    this.operationForm.controls.user.controls.password2.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword2) {
        this.checkedPassword2 = true;
        this.operationForm.controls.user.controls.password1.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });
  }

  getAddress(place: any) {
    if (!this.operationForm) return;
    this.operationForm.controls.address.touched = true;
    this.operationForm.controls.address.setValue(place.formatted_address || place.name || '');
    this.operationForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this.operationForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');
  }

  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this.operationForm.controls.address.value}`, '_blank');
  }

  onSubmit() {
    this.tmpBtnDisabled = true;
    let operation = (new FormGroupToObject()).transform(this.operationForm);
    let promiseResult: any;
    let createType: boolean = true;
    if (this.profile &&
      this.operationForm.controls.user.value.password1 &&
      this.operationForm.controls.user.value.password1 === this.operationForm.controls.user.value.password2
    ){
      operation['user']['password'] = this.operationForm.controls.user.value.password1;
    }
    if (this.operation['id']) {
      operation['user']['id'] = this.operation.user.id;
      promiseResult = this.profile ?
        this.operationService.updateMe(operation) :
        this.operationService.update(this.operation['id'], operation);
      createType = false;
    }
    else {
      if (this.operation.user.id){
        operation['user']['id'] = this.operation.user.id;
      }
      promiseResult = this.operationService.create(operation);
    }
    promiseResult.then((data: Operation) => {
      this.operation = data;
      const message: string = `Operation ${createType ? "creato" : "aggiornato"} con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      }).afterDismissed().subscribe(result => {
        this.authStatusService.isAuthenticated().then(valid => {
          if (!valid){ this.router.navigate(['/']); }
        });
        if (createType) {
          if (this.route.snapshot.paramMap.get('userId')){
            this.router.navigate(['/utenti']);
          } else{
            this.router.navigate(['/operation', 'edit', this.operation.id]);
          }
        }
      });
      this.patch();
      this.tmpBtnDisabled = false;
    }).catch(error => {
      this._snackBar.open(`Errore ${createType ? "nella creazione" : "nell'aggiornamento"} dell'expert`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      this.tmpBtnDisabled = false;
      console.log(error);
    });
  }
  

}
