import { CakeOrderService } from './../../services/cake-order.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ai-cake-order-info-modal',
  templateUrl: './ai-cake-order-info-modal.component.html',
  styleUrls: ['./ai-cake-order-info-modal.component.scss']
})
export class AiCakeOrderInfoModalComponent implements OnInit {

  public cakeOrderInfo: any;
  public savedCakeOrderInfo: any;
  public lineItems: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { order: any },
    private readonly cakeOrderService: CakeOrderService,
  ) { 
    this.cakeOrderInfo = this.data.order;
  }

  async ngOnInit() {
    try {
      let data = await this.cakeOrderService.getCakeOrderInfo(this.cakeOrderInfo?.realOrderNumber, this.cakeOrderInfo?.orderId);
      this.savedCakeOrderInfo = data;
      let orderDetails = JSON.parse(this.savedCakeOrderInfo?.orderDetails);
      this.lineItems = orderDetails.line_items;
      // console.log("this.savedCakeOrderInfo", this.savedCakeOrderInfo);
      // console.log("this.lineItems", this.lineItems)
    } catch (error) {
      console.log("ERROR", error?.message);
    }
  }

  openImage(imageUrl: string) {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }
  }

}
