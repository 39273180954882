import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export function requiredIfAllEmpty(requiredIf: { value: [AbstractControl] }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let value = control.value;
        let notEmptyFields = requiredIf.value.filter((elem: AbstractControl) => {
            return !!elem.value;
        });
        if (!!value && notEmptyFields.length === 0 || !value && notEmptyFields.length === 1)
            return null;
        return {
            requiredIfAllEmpty: { condition: requiredIf.value }
        };
    }
}
