import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class ObserverService<T> {
    protected _toggle = new Subject<T>();
    public object$: Observable<T> = this._toggle.asObservable();

    toggle(object: T) {
        this._toggle.next(object);
    }
}
