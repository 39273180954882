import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { CakeOrderService } from 'src/app/services/cake-order.service';

@Component({
  selector: 'app-cake-orders-details',
  templateUrl: './cake-orders-details.component.html',
  styleUrls: ['./cake-orders-details.component.scss']
})
export class CakeOrdersDetailsComponent implements OnInit {

  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'CakeOrderInfo.createdAt': 'DESC' };
  cakeOrdersFilterForm: any;
  cakeOrderProducts: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cakeOrderService: CakeOrderService
  ) { 
    this.manageCakeOrderFilterForm();
  }

  async ngOnInit() {
    try {
      await this.loadCakeOrderProducts();
    } catch (error) {
      
    }
  }

  manageCakeOrderFilterForm(){
    this.cakeOrdersFilterForm = new FormGroup({
      'CakeOrderInfo.id': new FormControl(''),
      'CakeOrderInfo.name': new FormControl(''),
      'CakeOrderInfo.sku': new FormControl(''),
      'CakeOrderInfo.description': new FormControl(''),
      'CakeOrderInfo.price': new FormControl(''),
    });
  }

  
  async filter(){
    let filterForm = (new FormGroupToObject()).transform(this.cakeOrdersFilterForm);
    this.filtered = {};

    if (filterForm['CakeOrderInfo.id'])
      this.filtered['CakeOrderInfo.id'] = filterForm['CakeOrderInfo.id'];    
    if (filterForm['CakeOrderInfo.name'])
      this.filtered['CakeOrderInfo.name'] = { operator: 'like', values: [`%${filterForm['CakeOrderInfo.name']}%`] };
    if (filterForm['CakeOrderInfo.sku'])
      this.filtered['CakeOrderInfo.sku'] = { operator: 'like', values: [`%${filterForm['CakeOrderInfo.sku']}%`] };
    if (filterForm['CakeOrderInfo.description'])
      this.filtered['CakeOrderInfo.description'] = { operator: 'like', values: [`%${filterForm['CakeOrderInfo.description']}%`] };
    if (filterForm['CakeOrderInfo.price'])
      this.filtered['CakeOrderInfo.price'] = { operator: 'like', values: [`%${filterForm['CakeOrderInfo.price']}%`] };

    this.loadCakeOrderProducts();
  } 

  async resetFilter(){
    this.manageCakeOrderFilterForm();
    this.filter();
  }

  async loadCakeOrderProducts(limit?: number, page?: number, order?: {}){
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let data = await this.cakeOrderService.getAllCakeOrderProducts(this.page, this.limit, this.order, this.filtered);
    this.cakeOrderProducts = data;
    this.count = this.cakeOrderProducts.length;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  openImage(imageUrl: string) {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadCakeOrderProducts();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

}
