import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceModel } from "src/app/models/service.schems";
import { FormGroupToObject } from "src/app/pipes/form-group-to-object.pipe";
import { Service } from "src/app/services/service.service";

@Component({
  selector: "app-service-form",
  templateUrl: "./service-form.component.html",
  styleUrls: ["./service-form.component.scss"],
})
export class ServiceFormComponent implements OnInit {
  _serviceForm: any;
  serviceId: string|number = null;
  serviceModel: ServiceModel;
  formValidationMsgs;
  public removeFile: boolean;
  public imagePath: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: Service,
    private _snackBar: MatSnackBar,
  ) {
    this.manageServiceForm();
  }

  async ngOnInit() {
    this._serviceForm = new FormGroup(ServiceModel.validation());
    this.serviceId = this.route.snapshot.paramMap.get('id');
    if (this.serviceId) {
      try {
        this.serviceModel = await this.service.getService(Number(this.serviceId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.serviceModel)
      this.patch();
    else
      this.serviceModel = new ServiceModel();
  }

  manageServiceForm() {
    this._serviceForm = new FormGroup(ServiceModel.validation());
    this.formValidationMsgs = ServiceModel.validationMessages;
  }

  patch(){
    this.removeFile = false;
    ['serviceName', 'pricingModel', 'notes'].forEach(param => {
      this._serviceForm.controls[param].setValue(this.serviceModel[param]);
    });
    if(this.serviceModel.image){
      this.imagePath = this.serviceModel.image;
    }
  }

  async onSubmit() {
    if (this._serviceForm.valid) {

      let service: ServiceModel = new FormGroupToObject().transform(this._serviceForm);
      let image = service.image;
      if (!this.removeFile) delete service.image;
      let promiseResult: any;
      if (this.serviceModel['id']) {
        promiseResult = this.service.updateService(this.serviceModel['id'], service);
      } else {
        promiseResult = this.service.createService(service);
      }

      let message: string = this.serviceModel['id'] ? "Servizio aggiornato" : "Servizio creato";
      promiseResult.then(async (response: any) => {
        if (image)
          await this.uploadImage(response?.id).catch((err) => { console.log('err', err) });
        await this.showSnackBar(message);
        await this.router.navigate(['/servizi']);
      }).catch(async (error: any) => {
        await this.showSnackBar(error.error.message);
      });
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this._serviceForm.get('image').setValue(file);
    }
  }

  uploadImage(serviceId: number) {
    return this.service.updateServicePhoto( serviceId, this._serviceForm.get('image').value );
  }

  openImage(event) {
    let w = window.open("");
    w.document.write(event.target.outerHTML);
  }

  deleteFile() {
    this.removeFile = true;
  }
}
