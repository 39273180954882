import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
export class ExpertTimeAvailability {
    static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            startEndTime: new FormArray([new FormGroup(StartTimeEndTime.validation())]),
            expert: new FormControl(''),
            available: new FormControl(true),
            date: new FormControl(''),
        };
        return validator;
    }

    public static timingFormGroup() {
        return new FormGroup(StartTimeEndTime.validation());
    }

    static readonly validationMessages = {
        'startTime': [ { type: 'required', message: 'Obbligatorio' } ],
        'endTime': [ { type: 'required', message: 'Obbligatorio' } ],
    };

    constructor(
        public id?: number,
        public startTime?: string,
        public endTime?: string,
        public expert?: any,
        public available?: boolean,
        public date?: Date
    ) { }
}
export class StartTimeEndTime {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            timings: this.timingFormGroup(),
        };
        return validator;
    }

    public static timingFormGroup() {
        return new FormGroup({
            startTime: new FormControl('', Validators.required),
            endTime: new FormControl('', Validators.required),
        });
    }

    constructor(
        public id?: number,
        public startTime?: String,
        public endTime?: String,
        public updatedAt?: Date,
        public createdAt?: Date,
    ) { }
}