import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({
  providedIn: 'root'
})
export class ProductPriorityListService extends ApiAuthService {
  private readonly url = `${this.baseUrl}/product-priority-list`;

  async getAllProductPriorityLists(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<any> {
    return this.getAll(page, limit, order, filter);
  }

  async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<any>{
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.get(`${this.url}`, { params: params }).toPromise() );
  }

  async getSingleProductPriorityList(id: number): Promise<any> {
    return this.request(() => this.http.get(`${this.url}/${id}`).toPromise());
  }

  async createProductPriorityList(formData: any): Promise<any> {
    return this.request(() => this.http.post(`${this.url}`, formData).toPromise());
  }

  async updateProductPriorityList(id: number, formData: any): Promise<any>{
    return this.request(() => this.http.put(`${this.url}/${id}`, formData ).toPromise() );
  }
  
  async deleteProductPriorityList(id: number): Promise<any>{
    return this.request(() => this.http.delete(`${this.url}/${id}` ).toPromise() );
  }

}
