import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerPriorityListSchema } from 'src/app/models/partner-priority-list.schema';
import { Partner } from 'src/app/models/partner.schema';
import { ProductCategory } from 'src/app/models/product-category.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { PartnerPriorityListService } from 'src/app/services/partner-priority-list.service';
import { PartnerService } from 'src/app/services/partner.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { ProvincesService } from 'src/app/services/provinces.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-duplicate-partner-priority-list',
  templateUrl: './duplicate-partner-priority-list.component.html',
  styleUrls: ['./duplicate-partner-priority-list.component.scss']
})
export class DuplicatePartnerPriorityListComponent implements OnInit {

  priorityListForm: any;
  formValidationMsgs: any;
  provinces: any[] = [];
  allPartners: Partner[] = [];
  partners: Partner[] = [];
  productCategories: ProductCategory[] = [];
  priorityListId: number|string;
  priorityList: any;
  dragAndDropPartnersList: any[] = [];
  selectedPartner: number;

  constructor(
    private productCategoryService: ProductCategoryService,
    private partnerService: PartnerService,
    private provincesService: ProvincesService,
    private route: ActivatedRoute,
    private router: Router,
    private partnerPriorityListService: PartnerPriorityListService,
    private _snackBar: MatSnackBar,
  ) {
    this.managePriorityListForm();
  }


  async ngOnInit() { 
    try {
      await this.getAllProductCategories();
      await this.getAllActivePartners();
      await this.getAllProvinces();
      /*
      console.log("ProductCategories", this.productCategories);
      console.log("Active Partners", this.partners);
      console.log("Provinces", this.provinces);
      */
    } catch (error) {
      console.log("Error", error);
    }
    await this.initialize();
  }

  async getAllProductCategories(){
    this.productCategories = await this.productCategoryService.getAllProductCategories();
  }

  async getAllActivePartners(){
    let result = await this.partnerService.getAllActivePartners();
    this.partners = result.partners;
    this.allPartners = result.partners;
  }

  async getAllProvinces(){
    this.provinces = await this.provincesService.getAllProvinces();
  }

  managePriorityListForm(){
    this.priorityListForm = new FormGroup(PartnerPriorityListSchema.validation());
    this.formValidationMsgs = PartnerPriorityListSchema.validationMessages;
  }

  async initialize(){
    this.priorityListId = this.route.snapshot.paramMap.get('id');
    if (this.priorityListId) {
      try {
        this.priorityList = await this.partnerPriorityListService.getPartnerPriorityList(Number(this.priorityListId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.priorityList){
      this.patch();
    }
    else{
      this.priorityList = new PartnerPriorityListSchema();
    }
  }

  patch(){

    ['productCategory', 'province'].forEach(param => {
      this.priorityListForm.controls[param].setValue(this.priorityList[param]?.id);
    });

    if(this.priorityList?.partnerPriorities?.length > 0){
      this.priorityListForm.patchValue({
        partnerPriorities: this.priorityList?.partnerPriorities.map((data) => {
          this.dragAndDropPartnersList.push(data?.partner)
          this.partners = this.partners.filter(partner => partner.id !== data?.partner?.id);
          return data.partner.id
        })
      });
    }

  }

  get getSelectedPartnersValue(): FormArray {
    return this.priorityListForm.get('partnerPriorities') as FormArray;
  }

  async onSubmit(){
    if(this.priorityListForm.valid){
      
      let priorityFormData: any = (new FormGroupToObject()).transform(this.priorityListForm);
      let promiseResult: any;

      const transformedArray = this.dragAndDropPartnersList.map((item, index) => {
        return {
          partner: {
            id: item.id,
            order: index + 1
          }
        };
      });
      priorityFormData.partnerPriorities = transformedArray;
      promiseResult = this.partnerPriorityListService.createPartnerPriorityList(priorityFormData);

      let message: string = "Elenco priorità partner creato";
      promiseResult.then(async (response: any) => {
        await this.showSnackBar(message);
        await this.router.navigate(['/partner/priority/list']);
      }).catch(async (error: any) => {
        await this.showSnackBar(error.error.message);
      });

    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dragAndDropPartnersList, event.previousIndex, event.currentIndex);
  }

  addPartnerInDropList(){
    if(this.selectedPartner === undefined || this.selectedPartner === null){ alert("Seleziona un partner qualsiasi"); return; }
    const selectedPartner = this.partners.find(p => p.id === this.selectedPartner);
    this.dragAndDropPartnersList.push(selectedPartner);
    this.partners = this.partners.filter(partner => partner.id !== this.selectedPartner);
    this.selectedPartner = null;
  }

  removePartnerFromDropList(index: number, partnerId: number){
    this.dragAndDropPartnersList.splice(index,1);
    const removedPartner = this.allPartners.find(p => p.id === partnerId);
    this.partners.push(removedPartner);
  }

}
