import { Component, OnInit, ViewChild } from '@angular/core';
import { Product, ProductStatus } from '../../models/product.schema';
import { PaginationComponent } from '../_pagination/pagination.component';
import { ProductService } from '../../services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { PartnerService } from '../../services/partner.service';
import { read, utils, writeFile } from 'xlsx';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  public products: Product[];
  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public order: {};
  @ViewChild(PaginationComponent, { static: false }) paginationComponent: PaginationComponent;
  public showElem: string;
  public groups: string[];
  public _productFilterForm;
  public filtered = {};
  public partner_list: any=[];
  public searchPartner: any = '';
  public import_products: any = [];
  public readonly productStatus = ProductStatus;
  public defaultOrder = { 'Product.name': 'ASC' }
  public defaultPage = 1;
  public defaultLimit = 100;

  constructor(
    private authStatusService: AuthStatusService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private partnerService: PartnerService,
    private router: Router
  ) { }

  async ngOnInit() {
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
    this._productFilterForm = new FormGroup({
      'Product.name': new FormControl(''),
      'Product.category': new FormControl(''),
      'Product.price': new FormControl(''),
      'Product.price.operator': new FormControl('equal'),
      'Product.stock': new FormControl(''),
      'Product.Partner.businessName': new FormControl(''),
      'Product.productStatus': new FormControl(''),
      'ProductCategory.categoryName': new FormControl(''),
      'Partner.businessName': new FormControl(''),
      'Product.uniqueProduct': new FormControl(''),
      'Product.sku': new FormControl(''),
    });
    this.showElem = 'table';
    this.loadProducts();
    if(this.groups.includes('admin')){
      //this.loadPartner();
    }
  }

  /*
  async loadPartner(){
      let partners = await this.partnerService.getAll(1, 1000);
      let prtners = partners.partners;
      let partner_arr = [];
      prtners.forEach((p) => {
        console.log(p);
        partner_arr[p['id']] = p['businessName'];

      });

      this.partner_list = partner_arr;
  } */

  async filter() {
    let filterForm = (new FormGroupToObject()).transform(this._productFilterForm);
    this.filtered = {};
    if (filterForm['Product.name'])
      this.filtered['Product.name'] = { operator: 'like', values: [`%${filterForm['Product.name']}%`] };
    if (filterForm['Product.category'])
      this.filtered['Product.category'] = { operator: 'like', values: [`%${filterForm['Product.category']}%`] };
    if (filterForm['Product.price'])
      this.filtered['Product.price'] = { operator: filterForm['Product.price.operator'], values: [filterForm['Product.price']] };
    if (filterForm['Product.stock'])
      this.filtered['Product.stock'] = { operator: 'like', values: [`%${filterForm['Product.stock']}%`] };
    if (filterForm['Product.Partner.businessName'])
      this.filtered['Product.Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Product.Partner.businessName']}%`] };
    if (filterForm['Product.productStatus'])
      this.filtered['Product.productStatus'] = { operator: 'like', values: [`%${filterForm['Product.productStatus']}%`] };
    if (filterForm['ProductCategory.categoryName'])
      this.filtered['ProductCategory.categoryName'] = { operator: 'like', values: [`%${filterForm['ProductCategory.categoryName']}%`] };
    if (filterForm['Partner.businessName'])
      this.filtered['Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Partner.businessName']}%`] };
    if (filterForm['Product.uniqueProduct'])
      this.filtered['Product.uniqueProduct'] = { operator: 'like', values: [`%${filterForm['Product.uniqueProduct']}%`] };
    if (filterForm['Product.sku'])
      this.filtered['Product.sku'] = { operator: 'like', values: [`%${filterForm['Product.sku']}%`] };
    this.loadProducts();
  }

  navigate(event) {
    this.loadProducts(event.limit, event.page, this.order);
  }

  async loadProducts(limit?: number, page?: number, order?: {}) {
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let products = await this.productService.getAll(this.page, this.limit, this.order, this.filtered);
    this.products = products.products;
    this.count = products.count;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    delete this.order['Product.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }
    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  public async delete(productId: number) {
    if (!confirm("Sei sicuro di voler cancellare questo prodotto?"))
      return;
    let product: { id: number | string, success: boolean } = await this.productService.delete(productId);
    let message = "Cancellazione del prodotto fallita.";
    if (product.success)
      message = "Cancellazione del prodotto avvenuta con successo.";
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
    if (product.success)
      this.loadProducts();
  }

  public show(elem: string) {
    this.showElem = elem;
  }

  // openImage(event) {
  //   let w = window.open("");
  //   w.document.write(event.target.outerHTML);
  // }
  openImage(imageUrl: string) {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }
  }

  import(){
    let el = document.getElementById("importcsv");
    el.click();
  }

  handleImport($event: any) {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          this.import_products = rows;
          let importProducts = this.import_products;
          let promiseResult: any;
          promiseResult = this.productService.import_product_via_csv(importProducts);
          promiseResult.then(async (res: any) => {
            await this.loadProducts();
            console.log('res :>> ', res);
            this._snackBar.open("Prodotto aggiunto con successo.", 'Chiudi', {
              direction: "ltr",
              duration: 2000,
              horizontalPosition: "center",
              politeness: "assertive",
              verticalPosition: "top"
            });
          }).catch((error: any) => {
            console.log('error :>> ', error);
            this._snackBar.open("Qualcosa è andato storto", 'Chiudi', {
              direction: "ltr",
              duration: 2000,
              horizontalPosition: "center",
              politeness: "assertive",
              verticalPosition: "top"
            })
          });
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  downloadCsvFormat(){
    const headings = [[
      "name",
      "price",
      "productCategory",
      "line",
      "stock",
      "description",
      // "priceHistory",
      "sku",
      "partnerId",
      // "quantity",
      "unEditable",
      "uniqueProduct",
      "productStatus",
      "imageUrl",
    ]];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.book_append_sheet(wb, ws, 'Products');
    writeFile(wb, 'products.xlsx');
  }

  async export(){
    this.order = this.order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || { 'Product.name': 'ASC' };
    let res = await this.productService.getAllProductsXlsx(this.order, this.filtered);
    let anchor = document.createElement('a');
    anchor.download = 'products_export.xlsx';
    anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
    anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

  public async changeProductStatus(productId: number, status: number) {
    let promiseResult = this.productService.changeProductStatus(productId, status);
    promiseResult.then((data: Product) => {
      this.loadProducts();
      // this.products[this.products.findIndex(u => u.id == productId)] = data;
      const message: string = `Stato dell'utente aggiornato con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }).catch(error => {
      this._snackBar.open("Errore nell'aggiornamento dello stato dell'utente", 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      console.log(error);
    });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    // this.loadDeliveries();
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadProducts();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }
}
