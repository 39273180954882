import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { SmsTemplate } from '../models/sms-template.schema';

@Injectable({ providedIn: 'root' })
export class SmsTemplatesService extends ApiAuthService{
  private readonly url = `${this.baseUrl}/sms-templates`;

  async create(smsParams: SmsTemplate): Promise<SmsTemplate>{
    let body = smsParams;
    return this.request(() => this.http.post<SmsTemplate>(`${this.url}`, body).toPromise());
  }

  async getAllSmsTemplates(): Promise<{ smsTemplates: SmsTemplate[]; count: number }>{
    return this.request(() => this.http.get<SmsTemplate>(`${this.url}`).toPromise());
  }

  delete(id: number): Promise<{ id: number; success: boolean }> {
    return this.request(() =>
      this.http.delete<{ id: number; success: boolean }>(`${this.url}/${id}`).toPromise()
    );
  }

  getOne(id: number): Promise<SmsTemplate> {
    return this.request(() =>
      this.http.get<SmsTemplate>(`${this.url}/${id}`).toPromise()
    );
  }

  update(id: number, smsTemParams: SmsTemplate): Promise<SmsTemplate> {
    let body = smsTemParams;
    return this.request(() =>
      this.http.put<SmsTemplate>(`${this.url}/${id}`, body).toPromise()
    );
  }
}
