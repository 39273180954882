import { AbstractControl, FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { Partner } from './partner.schema';

export class AdminSmsTemplate {
    public static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            temp_type: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required),
            partner: new FormGroup({ id: new FormControl('') }),
        };
        return validator;
    }

    public static readonly validationMessages = {
        'temp_type': [{ type: 'required', message: 'Obbligatorio' }],
        'description': [{ type: 'required', message: 'Obbligatorio' }],
        'partner': {
            'id': [{ type: 'required', message: 'Obbligatorio' }]
        },
    };

    constructor(
        public id?: number,
        public temp_type?: string,
        public description?: string,
        public partner?: Partner,
    ) { }
}