import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as env from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiService {
  baseUrl = env.environment.url;
  constructor(protected readonly http: HttpClient) { }

}
