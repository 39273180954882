import { Component, OnInit } from '@angular/core';
import { ShopifySaleService } from '../../services/shopify-sale.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { MatDialog } from '@angular/material/dialog';
import { ShopifySaleFormDialogComponent } from '../shopify-sale-form-dialog/shopify-sale-form-dialog.component'
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'app-shopify-sale',
  templateUrl: './shopify-sale.component.html',
  styleUrls: ['./shopify-sale.component.scss']
})
export class ShopifySaleComponent implements OnInit {

  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public filtered: {};
  public orders: string[];
  public _shopifySaleForm: any;
  public order: {};
  public myjson:any=JSON;
  public groups: any;
  public selected: string = 'active';
  public settings = {
    active: {
      title: "Deluxy Vendita",
    },
    inactive: {
      title: "Storico Deluxy Vendita",
    }
  };
  public defaultPage = 1;
  public defaultLimit = 100;
  public defaultOrder = { 'ShopifySale.actualOrderId': 'DESC' };
  btnDisable: boolean = false;

  constructor(
    private authStatusService: AuthStatusService,
    private shopifySaleService: ShopifySaleService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private deliveryService: DeliveryService,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
  }

  async ngOnInit() {
    this._shopifySaleForm = new FormGroup({
      'ShopifySale.orderId': new FormControl(''),
      'ShopifySale.actualOrderId': new FormControl(''),
      'ShopifySale.firstName': new FormControl(''), 
      'ShopifySale.deliveryDate_from': new FormControl(''), 
      'ShopifySale.deliveryDate_to': new FormControl(''),   
      'ShopifySale.lastName': new FormControl(''),  
      'ShopifySale.channel': new FormControl(''),
      'ShopifySale.total': new FormControl(''),
      'ShopifySale.paymentStatus': new FormControl(''),
      'ShopifySale.fullFillmentStatus': new FormControl(''),
      'ShopifySale.deliveryMethod': new FormControl(''),
      'ShopifySale.address': new FormControl(''),
      'ShopifySale.pickUpTime': new FormControl(''),
      'ShopifySale.phone': new FormControl(''),
      'ShopifySale.deliveryDate': new FormControl(''),
      'ShopifySale.vendor': new FormControl(''),
      'ShopifySale.status': new FormControl(''),
    });
    this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
    this.filter();
    // await this.getShopifyOrders();
    // await this.getShopifyOrdersByDirecltyApi();
  }

  async filter() {

    let filterForm = (new FormGroupToObject()).transform(this._shopifySaleForm);
    this.filtered = {};
    if (filterForm['ShopifySale.orderId'])
      this.filtered['ShopifySale.orderId'] = filterForm['ShopifySale.orderId'];    
    if (filterForm['ShopifySale.actualOrderId'])
      this.filtered['ShopifySale.actualOrderId'] = filterForm['ShopifySale.actualOrderId'];    
    if (filterForm['ShopifySale.firstName'])
      this.filtered['ShopifySale.firstName'] = { operator: 'like', values: [`%${filterForm['ShopifySale.firstName']}%`] };
    if (filterForm['ShopifySale.channel'])
      this.filtered['ShopifySale.channel'] = { operator: 'like', values: [`%${filterForm['ShopifySale.channel']}%`] };
    if (filterForm['ShopifySale.total'])
      this.filtered['ShopifySale.total'] = { operator: 'like', values: [`%${filterForm['ShopifySale.total']}%`] };
    if (filterForm['ShopifySale.paymentStatus'])
      this.filtered['ShopifySale.paymentStatus'] = { operator: 'like', values: [`%${filterForm['ShopifySale.paymentStatus']}%`] };
    if (filterForm['ShopifySale.fullFillmentStatus'])
      this.filtered['ShopifySale.fullFillmentStatus'] = { operator: 'like', values: [`%${filterForm['ShopifySale.fullFillmentStatus']}%`] };
    if (filterForm['ShopifySale.deliveryMethod'])
      this.filtered['ShopifySale.deliveryMethod'] = { operator: 'like', values: [`%${filterForm['ShopifySale.deliveryMethod']}%`] };
    if (filterForm['ShopifySale.address'])
      this.filtered['ShopifySale.address'] = { operator: 'like', values: [`%${filterForm['ShopifySale.address']}%`] };
    if (filterForm['ShopifySale.pickUpTime'])
      this.filtered['ShopifySale.pickUpTime'] = { operator: 'like', values: [`%${filterForm['ShopifySale.pickUpTime']}%`] };
    if (filterForm['ShopifySale.phone'])
      this.filtered['ShopifySale.phone'] = { operator: 'like', values: [`%${filterForm['ShopifySale.phone']}%`] };
    if (filterForm['ShopifySale.deliveryDate_from'])
      this.filtered['ShopifySale.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['ShopifySale.deliveryDate_from']] };
    if (filterForm['ShopifySale.deliveryDate_to'])
      this.filtered['ShopifySale.deliveryDate'] = { operator: 'lessThan', values: [filterForm['ShopifySale.deliveryDate_to']] };
    if (filterForm['ShopifySale.deliveryDate_from'] && filterForm['ShopifySale.deliveryDate_to'])
      this.filtered['ShopifySale.deliveryDate'] = { operator: 'between', values: [filterForm['ShopifySale.deliveryDate_from'], filterForm['ShopifySale.deliveryDate_to']] };
    if (filterForm['ShopifySale.vendor'])
      this.filtered['ShopifySale.vendor'] = { operator: 'like', values: [`%${filterForm['ShopifySale.vendor']}%`] };
    if (filterForm['ShopifySale.status'])
      this.filtered['ShopifySale.status'] = { operator: 'like', values: [`%${filterForm['ShopifySale.status']}%`] };

    this.getShopifyOrders();
  }

  async resetFilter() {
    
    this._shopifySaleForm = new FormGroup({
      'ShopifySale.orderId': new FormControl(''),
      'ShopifySale.actualOrderId': new FormControl(''),
      'ShopifySale.firstName': new FormControl(''), 
      'ShopifySale.deliveryDate_from': new FormControl(''), 
      'ShopifySale.deliveryDate_to': new FormControl(''),   
      'ShopifySale.lastName': new FormControl(''),  
      'ShopifySale.channel': new FormControl(''),
      'ShopifySale.total': new FormControl(''),
      'ShopifySale.paymentStatus': new FormControl(''),
      'ShopifySale.fullFillmentStatus': new FormControl(''),
      'ShopifySale.deliveryMethod': new FormControl(''),
      'ShopifySale.address': new FormControl(''),
      'ShopifySale.pickUpTime': new FormControl(''),
      'ShopifySale.phone': new FormControl(''),
      'ShopifySale.deliveryDate': new FormControl(''),
      'ShopifySale.vendor': new FormControl(''),
      'ShopifySale.status': new FormControl(''),
    });
    this.filter();
    this.getShopifyOrders();
  }

  async getShopifyOrders(limit?: number, page?: number, order?: {}){

    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let promiseResult = null;
    promiseResult = this.selected == 'active' ? 
      this.shopifySaleService.getShopifyOrders(this.page, this.limit, this.order, this.filtered) :
      this.shopifySaleService.getShopifyOrderHistory(this.page, this.limit, this.order, this.filtered);
    promiseResult.then((res) => {
      // console.log("From Database", res)
      this.orders = res.orders;
      this.count = res.count;
      this.maxPage = Math.ceil(this.count / this.limit);
    }).catch((err) => {
      console.log("err", err)
    })
  }

  async getShopifyOrdersByDirecltyApi() {
    let promiseResult = this.shopifySaleService.getShopifyOrdersVaiApi();
    promiseResult.then((res) => {
      console.log("From Shopify", res.orders)
    }).catch((err) => {
      console.log("error", err);
    });
  }

  async confirmSale(saleOrder: any) {
    if(this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('teamleader')){
      /*If Sale if unique then not confirm sale because partner is still accept or reject the sale*/
      let delivery = await this.deliveryService.getDeliveryWithOrderId(saleOrder?.orderId, saleOrder?.realOrderNumber);
      if(delivery){
        this._snackBar.open(`This is unique product sale. Delivery has been created. Partner is still accpet or reject the sale`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
        return false;
      }
      /*If Sale if unique then not confirm sale because partner is still accept or reject the sale*/
      let ref = this.dialog.open(ShopifySaleFormDialogComponent, { width: '80vw', data: { saleOrder } });
      ref.afterClosed().subscribe(result => {
        if (result?.delivery){
          console.log("Success");
          this.getShopifyOrders();
        }
      });
    } else {
      return false;
    }
  }

  async refuseSale(id: number){
    if(!confirm(`Sei sicuro di voler rifiutare l'ordine di Shopify?`))
      return false;
    let promiseResult = await this.shopifySaleService.refuseSaleByAdmin(id);
    if(promiseResult.success){
      this._snackBar.open(`L'ordine di Shopify è stato rifiutato. Puoi controllare nella cronologia`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      await this.getShopifyOrders();
    } else {
      this._snackBar.open(`Qualcosa è andato storto`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }
  }

  async stopSaleAutomation(sale: any){
    if(!confirm(`Are You Sure to Stop Sale Automation ${sale?.platform?.toLowerCase()}?`))
      return false;
    try {
      this.btnDisable = true;
      let id: number = sale?.id;
      let promiseResult = await this.shopifySaleService.stopSaleAutomation(id, sale);

      if(promiseResult.success){
        this._snackBar.open(`Sale Automation has been Stopped.`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
      } else {
        this._snackBar.open(`Something Went Wrong`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
      }
      await this.getShopifyOrders();
    } catch (error) {
      this.btnDisable = false;
      this._snackBar.open(`Something Went Wrong ${error?.error?.message}`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }
  }

  navigate(event) {
    this.getShopifyOrders(event.limit, event.page, this.order);
  }

  public navigateOrder(attribute: string | string[]) {
    delete this.order['ShopifySale.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.getShopifyOrders();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

}