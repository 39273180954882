import { Injectable } from "@angular/core";
import { ApiAuthService } from "./api-auth.service";
import { Delivery, DeliveryStatusAux } from "../models/delivery.schema";
import { ToTypeOrm } from "../models/_to-type-orm.schema";

@Injectable({
  providedIn: "root",
})
export class ExpertSalaryService extends ApiAuthService {

  private readonly url = `${this.baseUrl}/deliveries`;

  async getAllDeliveries(expertId: number, page?: number, limit?: number, order?: {}, filter: any = {}): Promise<{ deliveries: Delivery[], count: number }> {
    if (!filter['status'])
        filter['status'] = { operator: 'in', values: DeliveryStatusAux.mixed };
      return this.getAll(expertId, page, limit, order, filter);
  }

  async getAllInvoicedDeliveries(expertId: number, page?: number, limit?: number, order?: {}, filter: any = {}): Promise<{ deliveries: Delivery[], count: number }> {
    console.log('filter', filter)
    if (!filter['status'])
        filter['status'] = { operator: 'in', values: DeliveryStatusAux.mixed };
      return this.getAll(expertId, page, limit, order, filter);
  }

  async getAll(expertId: number, page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ deliveries: Delivery[], count: number }> {
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => 
      this.http.get<Delivery[]>(`${this.url}/salary/expert/${expertId}`, { params: params }).toPromise()
    );
  }

  async sendSalaryEmailToExpert(formData: any, expertId: number): Promise<any> {
    return this.request(() =>
      this.http.post<Delivery[]>(`${this.url}/salary/email/${expertId}`, formData).toPromise()
    );
  }
  
}
