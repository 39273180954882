import { Injectable } from "@angular/core";
import { ApiAuthService } from "./api-auth.service";
import { Delivery, DeliveryStatusAux } from "../models/delivery.schema";
import { ToTypeOrm } from "../models/_to-type-orm.schema";

@Injectable({
  providedIn: "root",
})
export class PartnerInvoiceService extends ApiAuthService {
  private readonly url = `${this.baseUrl}/deliveries`;

  async getAllDeliveriesForInvoice(): Promise<{
    deliveries: Delivery[];
    count: number;
  }> {
    return this.request(() =>
      this.http.get<Delivery[]>(`${this.url}/invoice/partner`).toPromise()
    );
  }

  async getDeliveriesByPartnerId(partnerId: number, filter: {}): Promise<{
    deliveries: Delivery[];
    count: number;
  }> {
    let params = {};
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() =>
      this.http.get<Delivery[]>(`${this.url}/invoice/partner/${partnerId}`, { params: params }).toPromise()
    );
  }

  async sendInvoiceToPartner(formData: any, partnerId: number): Promise<any> {
    return this.request(() =>
      this.http.post<Delivery[]>(`${this.url}/send/invoice/${partnerId}`, formData).toPromise()
    );
  }

  async getAllDeliveries(partnerId: number, page?: number, limit?: number, order?: {}, filter: any = {}): Promise<{ deliveries: Delivery[], count: number }> {
    if (!filter['status'])
        filter['status'] = { operator: 'in', values: DeliveryStatusAux.mixed };
      return this.getAll(partnerId, page, limit, order, filter);
  }

  async getAllInvoicedDeliveries(partnerId: number, page?: number, limit?: number, order?: {}, filter: any = {}): Promise<{ deliveries: Delivery[], count: number }> {
    console.log('filter', filter)
    if (!filter['status'])
        filter['status'] = { operator: 'in', values: DeliveryStatusAux.mixed };
      return this.getAll(partnerId, page, limit, order, filter);
  }

  async getAll(partnerId: number, page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ deliveries: Delivery[], count: number }> {
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => 
      this.http.get<Delivery[]>(`${this.url}/invoice/partner/${partnerId}`, { params: params }).toPromise()
    );
  }
}
