import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { ValetPriorityListService } from 'src/app/services/valet-priority-list.service';

@Component({
  selector: 'app-valet-priority-list',
  templateUrl: './valet-priority-list.component.html',
  styleUrls: ['./valet-priority-list.component.scss']
})
export class ValetPriorityListComponent implements OnInit {

  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'ExpertPriority.createdAt': 'DESC' };
  priorityListFilterForm: any;
  expertPriorityLists: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private valetPriorityListService: ValetPriorityListService,
    private _snackBar: MatSnackBar,
  ) { 
    this.handlePriorityListFilterForm();
  }

  handlePriorityListFilterForm(){
    this.priorityListFilterForm = new FormGroup({
      'ExpertPriority.id': new FormControl(''),
      'Provinces.provinceCode': new FormControl(''),
      'UserExpert.surname': new FormControl(''),
      'Partner.businessName': new FormControl(''),
    });
  }

  async ngOnInit() { 
    try {
      await this.loadExpertPriorityList();
    } catch (error) {
      
    }
  }

  async filter(){
    let filterForm = (new FormGroupToObject()).transform(this.priorityListFilterForm);
    this.filtered = {};

    if (filterForm['ExpertPriority.id'])
      this.filtered['ExpertPriority.id'] = filterForm['ExpertPriority.id'];    
    if (filterForm['Provinces.provinceCode'])
      this.filtered['Provinces.provinceCode'] = { operator: 'like', values: [`%${filterForm['Provinces.provinceCode']}%`] };
    if (filterForm['Partner.businessName'])
      this.filtered['Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Partner.businessName']}%`] };
    if (filterForm['UserExpert.surname'])
      this.filtered['UserExpert.surname'] = { operator: 'like', values: [`%${filterForm['UserExpert.surname']}%`], concat: [{ string: ' ' }, { column: 'UserExpert.name' }] };

    this.loadExpertPriorityList();
  } 

  async resetFilter(){
    this.handlePriorityListFilterForm();
    this.filter();
  }

  async loadExpertPriorityList(limit?: number, page?: number, order?: {}){
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let data = await this.valetPriorityListService.getAllValetPriorityLists(this.page, this.limit, this.order, this.filtered);
    this.expertPriorityLists = data;
    this.count = this.expertPriorityLists.length;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  async deleteValetPriorityList(id: number){

    try {
      if (!confirm("Sei sicuro di voler eliminare l'elenco di priorità dei partner?")){ return; }
      let deleteResult = await this.valetPriorityListService.deleteValetListService(id);
      if(deleteResult){
        this._snackBar.open('Elenco priorità partner eliminato correttamente', 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(result => {
          this.loadExpertPriorityList();
        })
      }
    } catch (error) {
      this._snackBar.open('Qualcosa è andato storto', 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      })
    }

  }
  
  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadExpertPriorityList();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

}
