import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { Service } from 'src/app/services/service.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  services: any[] = [];
  serviceFilterForm: any;
  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  public defaultPage = 1;
  public defaultLimit = 100;
  public defaultOrder = { 'Service.createdAt': 'DESC' };
  constructor(
    private service: Service,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.manageServiceFilterForm();
  }

  async ngOnInit() {
    await this.initialize();
  }

  async initialize(){
    // await this.getAllServices();
    await this.loadServices();
  }

  /*
  async getAllServices(){
    try {
      this.services = await this.service.getAllServices();
    } catch (error) {
      
    }
  }
  */
  
  async deleteService(serviceId: number){
    if (!confirm("Sei sicuro di voler eliminare questo servizio?"))
      return;
    try { 
      const result = await this.service.deleteService(serviceId);
      await this.showSnackBar('Servizio cancellato con successo');
      // await this.getAllServices();
      await this.loadServices();
    } catch (error) {
      this.showSnackBar(`${error.error.message}`);
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

  openImage(event) {
    let w = window.open("");
    w.document.write(event.target.outerHTML);
  }

  manageServiceFilterForm(){
    this.serviceFilterForm = new FormGroup({
      'Service.id': new FormControl(''),
      'Service.serviceName': new FormControl(''),
      'Service.pricingModel': new FormControl(''),
      'Service.notes': new FormControl(''),
    });
  }

  filter(){
    let filterForm = (new FormGroupToObject()).transform(this.serviceFilterForm);
    this.filtered = {};
    if (filterForm['Service.id'])
      this.filtered['Service.id'] = filterForm['Service.id'];  
    if (filterForm['Service.serviceName'])
      this.filtered['Service.serviceName'] = { operator: 'like', values: [`%${filterForm['Service.serviceName']}%`]};
    if (filterForm['Service.pricingModel'])
      this.filtered['Service.pricingModel'] = { operator: 'like', values: [`%${filterForm['Service.pricingModel']}%`]};
    if (filterForm['Service.notes'])
      this.filtered['Service.notes'] = { operator: 'like', values: [`%${filterForm['Service.notes']}%`]};

    this.loadServices();
  }

  fnReset(){
    this.manageServiceFilterForm();
  }

  async loadServices(limit?: number, page?: number, order?: {}){
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let services = await this.service.getAllServicess(this.page, this.limit, this.order, this.filtered);
    this.services = services;
    this.count = services.length;
    this.maxPage = Math.ceil(this.count / this.limit);
    // console.log('this.limit', this.limit)
    // console.log('this.page', this.page)
    // console.log('this.count', this.count)
    // console.log('this.maxPage', this.maxPage)
  }

  public navigateOrder(attribute: string | string[]) {
    delete this.order['Service.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadServices();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  navigate(event) {
    this.loadServices(event.limit, event.page, this.order);
  }

}
