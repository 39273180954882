import { Component, OnInit, Input } from '@angular/core';
import { Expert, ExpertProvinces, ExpertServices, ExpertTeamLeaderProvinces } from '../../models/expert.schema';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExpertService } from '../../services/expert.service';
import { FormArray, FormGroup } from '@angular/forms';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { Vehicle } from '../../models/vehicle.schema';
import { orderByProperty } from '../../helpers/order-by-property.helper';
import { User } from '../../models/user.schema';
import { AuthStatusService } from '../../services/auth-status.service';
import { UserService } from '../../services/user.service';
import { ExpertServiceService } from 'src/app/services/expert-service.service';
import { ExpertServiceSchema } from 'src/app/models/expert-service.schems';
import { ProvincesService } from 'src/app/services/provinces.service';

@Component({
  selector: 'app-expert-form',
  templateUrl: './expert-form.component.html',
  styleUrls: ['./expert-form.component.scss']
})
export class ExpertFormComponent implements OnInit {
  @Input('profile') public profile: boolean = false;
  public readonly formValidationMsgs;
  public _expertForm: any;
  public expert: Expert;
  public vehicles: Vehicle[];

  private checkedPassword1: boolean;
  private checkedPassword2: boolean;
  public tmpBtnDisabled: boolean;
  public groups: string[];
  public services: ExpertServiceSchema[] = [];
  selectedServices: any[] = [];
  showExtraKmInput: boolean[] = [];
  provinces: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private expertService: ExpertService,
    private userService: UserService,
    private authStatusService: AuthStatusService,
    private _expertServiceService: ExpertServiceService,
    private provincesService: ProvincesService,
  ) {
    this.formValidationMsgs = Expert.validationMessages;
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    try {
      this.services = await this._expertServiceService.getAllServicess();
      this.provinces = await this.provincesService.getAllProvinces();
    }
    catch { }
    await this.initialize();
  }

  async initialize() {
    this.vehicles = orderByProperty(await this.expertService.getAllVehicles(), 'name');
    this._expertForm = new FormGroup(Expert.validation(this.vehicles, this.profile));
    const expertId = this.profile ?
      (await this.expertService.getOne(this.authStatusService.getExtra()['id'])).id.toString() :
      this.route.snapshot.paramMap.get('id');
    if (expertId) {
      try {
        this.expert = await this.expertService.getOne(expertId);
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (!this.expert) {
      this.expert = new Expert();
      this.expert.user = this.route.snapshot.paramMap.get('userId') ?
        await this.userService.getOne(this.route.snapshot.paramMap.get('userId')) :
        new User();
    }
    this.patchAdditionalInfo(expertId);
    this.patch();
    if (this.profile)
      this.setProfileValidators();
  }

  patchAdditionalInfo(expertId: number|string){
    if(expertId && this.expert?.expertServices?.length > 0){
      this.patchExpertServices();
    }
    if(expertId && this.expert?.expertProvinces?.length > 0){
      this.patchExpertProvinces();
    }
    if(expertId && this.expert?.expertTeamLeaderProvinces?.length > 0){
      this.patchExpertTeamLeaderProvinces();
    }
  }

  patch() {
    ['phone', 'address', 'notes', 'longitude', 'latitude', 'minimumKmIncluded', 'isTeamLeader'].forEach(param => {
      this._expertForm.controls[param].setValue(this.expert[param]);
    });
    ['surname', 'name', 'email'].forEach(param => {
      this._expertForm.controls.user.controls[param].setValue(this.expert.user[param]);
    });
    if (this.expert.vehicles){
      for (let i = 0; i < this.vehicles.length; i++) {
        for (const vehicle of this.expert.vehicles)
          if (vehicle.id === this.vehicles[i].id) {
            this._expertForm.controls.vehicles.controls[i].setValue(true);
            break;
          }
      }
    }
  }

  patchExpertServices(){
    const control = <FormArray>this._expertForm.get('expertServices');
    control.clear();
    if (!this.expert.expertServices || Array.isArray(this.expert.expertServices) && this.expert.expertServices.length == 0){
      this.expert.expertServices = [new ExpertServices()];
    }
    this.expert.expertServices.forEach((f, index) => {
      control.push(this.patchValues(f, index));
    });
    this.selectedServices = this._expertForm.controls.expertServices?.value;
  }

  
  patchValues(expertServices: ExpertServices, index: number) {
    this.showExtraKmInput[index] = expertServices?.service?.serviceType.toLowerCase() === 'fixedpricesalary';
    let serviceForm = Expert.serviceFormGroup();
    serviceForm.controls.service.setValue(
      { 
        id: expertServices?.service?.id, 
        salary: expertServices?.salary,
        minimumKmPrice: expertServices?.minimumKmPrice
      }
    );
        
    return serviceForm;
  }

  
  patchExpertProvinces(){
    const control = <FormArray>this._expertForm.get('expertProvinces');
    control.clear();
    if (!this.expert.expertProvinces || Array.isArray(this.expert.expertProvinces) && this.expert.expertProvinces.length == 0){
      this.expert.expertProvinces = [new ExpertProvinces()];
    }
    this.expert.expertProvinces.forEach((f, index) => {
      control.push(this.patchExpertProvinceValues(f, index));
    });
  }

  patchExpertProvinceValues(expertProvinces: ExpertProvinces, index: number) {
    let provinceForm = Expert.provinceFormGroup();
    provinceForm.controls.province.setValue(
      { 
        id: expertProvinces?.province?.id, 
      }
    );
        
    return provinceForm;
  }

  patchExpertTeamLeaderProvinces(){
    const control = <FormArray>this._expertForm.get('expertTeamLeaderProvinces');
    control.clear();
    if (!this.expert.expertTeamLeaderProvinces || Array.isArray(this.expert.expertTeamLeaderProvinces) && this.expert.expertTeamLeaderProvinces.length == 0){
      this.expert.expertTeamLeaderProvinces = [new ExpertTeamLeaderProvinces()];
    }
    this.expert.expertTeamLeaderProvinces.forEach((f, index) => {
      control.push(this.patchExpertTeamLeaderProvinceValues(f, index));
    });
  }

  patchExpertTeamLeaderProvinceValues(expertTeamLeaderProvinces: ExpertTeamLeaderProvinces, index: number) {
    let provinceForm = Expert.teamLeaderProvinceFormGroup();
    provinceForm.controls.province.setValue(
      { 
        id: expertTeamLeaderProvinces?.province?.id, 
      }
    );
        
    return provinceForm;
  }

  setProfileValidators() {
    this.checkedPassword1 = false;
    this.checkedPassword2 = false;

    this._expertForm.controls.user.controls.password1.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword1) {
        this.checkedPassword1 = true;
        this._expertForm.controls.user.controls.password2.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });

    this._expertForm.controls.user.controls.password2.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword2) {
        this.checkedPassword2 = true;
        this._expertForm.controls.user.controls.password1.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });
  }

  onSubmit() {
    this.tmpBtnDisabled = true;
    let expert: Expert = (new FormGroupToObject()).transform(this._expertForm);
    expert['vehicles'] = [];
    for (let i = 0; i < this._expertForm.controls.vehicles.controls.length; i++) {
      if (this._expertForm.controls.vehicles.controls[i].value)
        expert['vehicles'].push(this.vehicles[i]);
    }
    let promiseResult: any;
    let createType: boolean = true;
    if (this.profile &&
      this._expertForm.controls.user.value.password1 &&
      this._expertForm.controls.user.value.password1 === this._expertForm.controls.user.value.password2
    ){
      expert['user']['password'] = this._expertForm.controls.user.value.password1;
    }

    if(this.groups.includes('expert')){
      delete expert?.expertProvinces;
      delete expert?.expertServices;
      delete expert?.expertTeamLeaderProvinces;
    }

    if(this.groups.includes('admin') || this.groups.includes('operation')){
      if(expert?.expertProvinces[0]?.province?.id === null || expert?.expertProvinces[0]?.province?.id === undefined){
        delete expert.expertProvinces;
      }
      if(expert?.expertServices[0]?.service?.id === null || expert?.expertServices[0]?.service?.id === undefined){
        delete expert.expertServices;
      }
      if(expert?.expertTeamLeaderProvinces[0]?.province?.id === null || expert?.expertTeamLeaderProvinces[0]?.province?.id === undefined){
        delete expert.expertTeamLeaderProvinces;
      }
    }
    // if(this.groups.includes('operation')){
    //   expert.expertServices = null;
    // }
    // console.log('expert', expert);
    // return;
    if (this.expert['id']) {
      expert['user']['id'] = this.expert.user.id;
      promiseResult = this.profile ?
        this.expertService.updateMe(expert) :
        this.expertService.update(this.expert['id'], expert);
      createType = false;
    }
    else {
      if (this.expert.user.id)
        expert['user']['id'] = this.expert.user.id;
      promiseResult = this.expertService.create(expert);
    }
    promiseResult.then((data: Expert) => {
      this.expert = data;
      const message: string = `Expert ${createType ? "creato" : "aggiornato"} con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      }).afterDismissed().subscribe(result => {
        this.authStatusService.isAuthenticated().then(valid => {
          if (!valid)
            this.router.navigate(['/']);
        });
        if (createType) {
          if (this.route.snapshot.paramMap.get('userId')){
            this.router.navigate(['/utenti']);
          } else{
            this.router.navigate(['/expert', this.expert.id, 'edit']);
          }
        }
      });
      this.patch();
      this.patchAdditionalInfo(this.expert?.id);
      // this.patchExpertServices();
      this.tmpBtnDisabled = false;
    }).catch(error => {
      this._snackBar.open(`Errore ${createType ? "nella creazione" : "nell'aggiornamento"} dell'expert`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      this.tmpBtnDisabled = false;
      console.log(error);
    });
  }

  getAddress(place: any) {
    if (!this._expertForm) return;
    this._expertForm.controls.address.touched = true;
    this._expertForm.controls.address.setValue(place.formatted_address || place.name || '');
    this._expertForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this._expertForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');
  }

  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this._expertForm.controls.address.value}`, '_blank');
  }

  addService() {
    const control = <FormArray>this._expertForm.get('expertServices');
    control.push(Expert.serviceFormGroup());
  }


  removeService(index: number) {
    const control = <FormArray>this._expertForm.get('expertServices');
    if (control.length === 1) {
      return;
    }
    control.removeAt(index);
    this.selectedServices = this._expertForm.controls.expertServices?.value;
  }

  isOptionDisabled(service: any): boolean {
    if(this.selectedServices.length){
      let data = this.selectedServices.map((service) => {
        return this.services.find((item) => {
          return service.service.id === item.id;  
        })?.serviceType;
      });
      return data.includes(service.serviceType) || data.includes(service.serviceType);
    }
  }
  
  handleServiceChange(serviceId: any, index: number){
    this.selectedServices = this._expertForm.controls.expertServices?.value;
    const selectedService = this.services.find(service => service.id == serviceId);
    this.showExtraKmInput[index] = selectedService.serviceType.toLowerCase() === 'fixedpricesalary';
  }

  addProvince() {
    const control = <FormArray>this._expertForm.get('expertProvinces');
    control.push(Expert.provinceFormGroup());
  }

  isProvinceOptionDisabled(item: any): boolean {
    let hasEmptyId = false;
    for (const provinces of this._expertForm.value.expertProvinces) {
      if (provinces.province.id === item.id) {
        hasEmptyId = true;
      }
    }
    return hasEmptyId;
  }

  removeProvince(index: number) {
    const control = <FormArray>this._expertForm.get('expertProvinces');
    if (control.length === 1) {
      return;
    }
    control.removeAt(index);
  }

  addTeamLeaderProvince() {
    const control = <FormArray>this._expertForm.get('expertTeamLeaderProvinces');
    control.push(Expert.teamLeaderProvinceFormGroup());
  }

  removeTeamLeaderProvince(index: number) {
    const control = <FormArray>this._expertForm.get('expertTeamLeaderProvinces');
    if (control.length === 1) {
      return;
    }
    control.removeAt(index);
  }

  isTeamLeaderProvinceOptionDisabled(item: any): boolean {
    let hasEmptyId = false;
    for (const provinces of this._expertForm.value.expertTeamLeaderProvinces) {
      if (provinces.province.id === item.id) {
        hasEmptyId = true;
      }
    }
    return hasEmptyId;
  }

}
