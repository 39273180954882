import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpertServiceService } from './../../services/expert-service.service';
import { ExpertServiceSchema } from 'src/app/models/expert-service.schems';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-expert-service',
  templateUrl: './expert-service.component.html',
  styleUrls: ['./expert-service.component.scss']
})
export class ExpertServiceComponent implements OnInit {

  expertServiceFilterForm: any;
  expertServices: ExpertServiceSchema[] = [];
  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'ExpertService.createdAt': 'DESC' };

  constructor(
    private expertServiceService: ExpertServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {
    this.manageExpertServiceFilterForm();
  }

  async ngOnInit() {
    await this.initialize();
  }

  async initialize(){
    await this.getAllExpertServices();
  }

  manageExpertServiceFilterForm(){
    this.expertServiceFilterForm = new FormGroup({
      'ExpertService.id': new FormControl(''),
      'ExpertService.serviceName': new FormControl(''),
      'ExpertService.serviceType': new FormControl(''),
      'ExpertService.notes': new FormControl(''),
    });
  }

  async getAllExpertServices (limit?: number, page?: number, order?: {}){
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let services = await this.expertServiceService.getAllServicess(this.page, this.limit, this.order, this.filtered);
    this.expertServices = services;
    this.count = services.length;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  filter(){
    let filterForm = (new FormGroupToObject()).transform(this.expertServiceFilterForm);
    this.filtered = {};
    if (filterForm['ExpertService.id'])
      this.filtered['ExpertService.id'] = filterForm['ExpertService.id'];  
    if (filterForm['ExpertService.serviceName'])
      this.filtered['ExpertService.serviceName'] = { operator: 'like', values: [`%${filterForm['ExpertService.serviceName']}%`]};
    if (filterForm['ExpertService.serviceType'])
      this.filtered['ExpertService.serviceType'] = { operator: 'like', values: [`%${filterForm['ExpertService.serviceType']}%`]};
    if (filterForm['ExpertService.notes'])
      this.filtered['ExpertService.notes'] = { operator: 'like', values: [`%${filterForm['ExpertService.notes']}%`]};

    this.getAllExpertServices();
  }

  fnReset(){
    this.manageExpertServiceFilterForm();
  }

  async deleteExpertService(expertServiceId: number){
    if (!confirm("Sei sicuro di voler eliminare questo valet servizio?"))
      return;
    try { 
      const result = await this.expertServiceService.deleteExpertService(expertServiceId);
      await this.showToastMessage('Servizio cancellato con successo');
      await this.getAllExpertServices();
    } catch (error) {
      this.showToastMessage(`${error.error.message}`);
    }
  }

  public navigateOrder(attribute: string | string[]) {
    delete this.order['Service.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.getAllExpertServices();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  async showToastMessage(message: string){
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

  navigate(event) {
    this.getAllExpertServices(event.limit, event.page, this.order);
  }

}
