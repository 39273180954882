import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Delivery } from 'src/app/models/delivery.schema';
import { Expert } from 'src/app/models/expert.schema';
import { Service } from 'src/app/services/service.service';
import { UserService } from 'src/app/services/user.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ExpertSalaryService } from 'src/app/services/expert-salary.service';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'app-expert-salary-dialog',
  templateUrl: './expert-salary-dialog.component.html',
  styleUrls: ['./expert-salary-dialog.component.scss']
})
export class ExpertSalaryDialogComponent implements OnInit {

  @ViewChild('htmlPdf') htmlPdfRef!: ElementRef;
  groups: any;
  deliveries: Delivery[] = [];
  expert: Expert;
  historyPage: string = "";
  currentDate: Date = new Date();
  user: any;
  disableBtn: boolean = false;
  grandTotal: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      deliveries: Delivery[],
      expert: Expert,
      historyPage: any
    },
    private authStatusService: AuthStatusService,
    private userService: UserService,
    private dialogRef: MatDialogRef<ExpertSalaryDialogComponent>,
    public service: Service,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private expertSalaryService: ExpertSalaryService,
    private deliveryService: DeliveryService
  ) {
    this.historyPage = data.historyPage;
    this.deliveries = data.deliveries;
    this.expert = data.expert;
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
  }

  async ngOnInit() {
    this.user = await this.userService.getMyInformation();
    this.calculateGrandTotal();
  }

  calculateGrandTotal(){
    this.grandTotal = 0; 
    this.deliveries.forEach((delivery) => {
        let amount: any = parseFloat(delivery?.expertSalary) + parseFloat(delivery?.valetAdditionalPrice)
        this.grandTotal += parseFloat(amount);
    });
    this.grandTotal = parseFloat(this.grandTotal.toFixed(2)); 
  }

  showServiceDetails(serviceId: number){
    if(serviceId){
      const service = this.expert?.expertServices.find((item: any) => {
        return item?.id == serviceId;
      });
      return service;
    }
  }

  downloadInvoice() {
    this.generatePdf('download');
  }

  viewInvoice() {
    this.generatePdf('view');
  }

  sendInvoice() {
    this.generatePdf('send');
  }

  generatePdf(action: string) {
    const DATA: any = this.htmlPdfRef.nativeElement;
    html2canvas(DATA).then((canvas) => {
      const pdfHeight = 297;
      const fileWidth = 208;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const totalPages = Math.ceil(fileHeight / pdfHeight);
      let PDF = new jsPDF('p', 'mm', 'a4');
      for (let i = 0; i < totalPages; i++) {
        if (i > 0) {
          PDF.addPage();
        }
        let position = -i * pdfHeight;
        PDF.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          0,
          position,
          fileWidth,
          fileHeight
        );
      }

      switch(action) {
        case 'download':
          PDF.save('expert-salary.pdf');
          break;
        case 'view':
          const pdfUrl = URL.createObjectURL(PDF.output('blob'));
          const newWindow = window.open('', '_blank');
          if (newWindow) {
            newWindow.location.href = pdfUrl;
          }
          break;
        case 'send':
          this.disableBtn = true;
          const pdfBlob = PDF.output('blob');
          const formData = new FormData();
          formData.append('salary', pdfBlob, 'expert-salary.pdf');
          formData.append('deliveries', JSON.stringify(this.deliveries));
          let promise = this.expertSalaryService.sendSalaryEmailToExpert(formData, this.expert?.id);
          promise.then((res: any)=> {
            if(res){
              this.disableBtn = false;
              this.openSnackBar('Email di stipendio valet inviata correttamente');
              this.dialogRef.close({ success: true });
              this.deliveries = [];
            }
          }).catch((err: any)=> {
            this.disableBtn = false;
            this.openSnackBar(`${err.error.message}`);
          })
          break;
        default:
          break;
      }
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

  async exportSalaryInfo(){
    let res = await this.deliveryService.exportSalaryInfo(this.deliveries);
    let anchor = document.createElement('a');
    anchor.download = 'pro_forma_valet.xlsx';
    anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
    anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

}
