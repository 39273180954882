import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {
    private readonly intervals: {} = {
        'year': { value: 31536000, text: { 1: 'anno', 2: 'anni' } },
        'month': { value: 2592000, text: { 1: 'mese', 2: 'mesi' } },
        'week': { value: 604800, text: { 1: 'settimana', 2: 'settimane' } },
        'day': { value: 86400, text: { 1: 'giorno', 2: 'giorni' } },
        'hour': { value: 3600, text: { 1: 'ora', 2: 'ore' } },
        'minute': { value: 60, text: { 1: 'minuto', 2: 'minuti' } },
        'second': { value: 1, text: { 1: 'secondo', 2: 'secondi' } }
    };

    transform(value: any, args?: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return 'Ora';
            for (const index in this.intervals) {
                let counter: number = Math.floor(seconds / this.intervals[index].value);
                if (counter > 0)
                    return counter + ' ' + this.getText(index, counter) + ' fa';
            }
        }
        return value;
    }

    private getText(index: string, num: number) {
        let finalText = "";
        for (const count in this.intervals[index].text) {
            if (num < parseInt(count))
                break;
            finalText = this.intervals[index].text[count];
        }
        return finalText;
    }

}
