import { Injectable } from '@angular/core';
import { AdminSmsTemplate } from '../models/admin-sms-template.schema';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({
  providedIn: 'root'
})
export class AdminSmsTemplatesService extends ApiAuthService{

  private readonly url = `${this.baseUrl}/admin-sms-templates`;

  async getAllAdminSmsTemplates(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ adminSmsTemplates: AdminSmsTemplate[]; count: number }>{
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.get<AdminSmsTemplate>(`${this.url}`, { params: params }).toPromise());
  }

  async create(smsParams: AdminSmsTemplate, smsTempForAdmin?: boolean): Promise<AdminSmsTemplate>{
    let body = smsParams;
    return this.request(() => this.http.post<AdminSmsTemplate>(`${this.url}/${smsTempForAdmin}`, body).toPromise());
  }

  getOne(id: number): Promise<AdminSmsTemplate> {
    return this.request(() =>
      this.http.get<AdminSmsTemplate>(`${this.url}/${id}`).toPromise()
    );
  }

  delete(id: number): Promise<{ id: number; success: boolean }> {
    return this.request(() =>
      this.http.delete<{ id: number; success: boolean }>(`${this.url}/${id}`).toPromise()
    );
  }

  update(id: number, adminSmsTemParams: AdminSmsTemplate): Promise<AdminSmsTemplate> {
    let body = adminSmsTemParams;
    return this.request(() =>
      this.http.put<AdminSmsTemplate>(`${this.url}/${id}`, body).toPromise()
    );
  }
}
