import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Delivery } from 'src/app/models/delivery.schema';

@Component({
  selector: 'app-delivery-link-dialog',
  templateUrl: './delivery-link-dialog.component.html',
  styleUrls: ['./delivery-link-dialog.component.scss']
})
export class DeliveryLinkDialogComponent implements OnInit {
  public delivery: Delivery;
  public link: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { delivery: Delivery }) {
    this.delivery = data.delivery;
  }

  ngOnInit() {
    this.link = `${window.location.origin}/consegne/${this.delivery.identifier}`;
  }

}
