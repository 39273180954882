import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  private loaderSubject = new BehaviorSubject<boolean>(false); // Initialize with false (loader hidden)
  loaderState$ = this.loaderSubject.asObservable(); // Observable to subscribe to

  // Show the loader
  show() {
    this.loaderSubject.next(true);
  }

  // Hide the loader
  hide() {
    this.loaderSubject.next(false);
  }
}
