import { FlowersSaleService } from './../../services/flowers-sale.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryProduct, TimePeriods } from 'src/app/models/delivery.schema';
import { Partner } from 'src/app/models/partner.schema';
import { Product } from 'src/app/models/product.schema';
import { ShopifySale } from 'src/app/models/shopify-sale.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { CakeSaleService } from 'src/app/services/cake-sale.service';
import { PartnerService } from 'src/app/services/partner.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-deluxy-flowers-sale-form',
  templateUrl: './deluxy-flowers-sale-form.component.html',
  styleUrls: ['./deluxy-flowers-sale-form.component.scss']
})
export class DeluxyFlowersSaleFormComponent implements OnInit {

  public sale: ShopifySale;
  public readonly formValidationMsgs;
  public flowersSaleForm: any;
  public timePeriods = TimePeriods;
  public partners: Partner[];
  public products: Product[];
  public saleProducts: any;
  public from: string;
  public to: string;
  constructor(
    private route: ActivatedRoute,
    private flowersSaleService: FlowersSaleService,
    private router: Router,
    private partnerService: PartnerService,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
  ) { 
    this.formValidationMsgs = ShopifySale.validationMessages;
  }

  async ngOnInit() {
    this.flowersSaleForm = new FormGroup(ShopifySale.validation());
    const saleId: string = this.route.snapshot.paramMap.get('id');
    if (saleId) {
      try {
        this.sale = await this.flowersSaleService.getOne(saleId);
        this.saleProducts = JSON.parse(this.sale['lineItems']);
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
      try {
        this.partners = (await this.partnerService.getAllActivePartners(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
      }
      catch { }
      try{
        this.products = (await this.productService.getAllActiveProducts(undefined, undefined, { 'Product.name': 'ASC' })).products;
      }
      catch {}
    }
    this.patch();
  }

  patch(){

    ['firstName', 'lastName', 'address', 'phone', 'total', 'paymentStatus', 'fullFillmentStatus', 'deliveryMethod', 'notes', 'intercom', 'deliveryTime', 'senderName', 'senderSurname', 'senderPhone'].forEach((param) => {
      this.flowersSaleForm.controls[param].setValue(this.sale[param]);
    });

    if(this.sale['deliveryDate']){
      this.flowersSaleForm.controls['deliveryDate'].setValue(new DatePipe('it-IT').transform(this.sale['deliveryDate'], 'yyyy-MM-dd'));
    }

    if(this.sale['deliveryTime']){
      let [from, to] = this.sale['deliveryTime'].split("-");
      let fromTime = `${from}:00`;
      let toTime = `${parseInt(from)+1}:00`;
      let deliveryTime = fromTime + "-" + toTime;
      this.flowersSaleForm.controls.deliveryTime.setValue(deliveryTime);
    }

    for(let p of this.partners){
      if(p.businessName.toLowerCase() == this.sale.vendor.toLowerCase()){
        this.flowersSaleForm.controls.vendor.setValue(p.businessName);
        break;
      }
    }

    /* Set products */
    const control = <FormArray>this.flowersSaleForm.get('deliveryProducts');
    control.clear();
    this.saleProducts.forEach((product) => {
      control.push(this.patchValues(product));
    });
    /* Set products */
  }

  patchValues(deliveryProduct: DeliveryProduct){
    let productForm = ShopifySale.productFormGroup();
    if (deliveryProduct['id']){
      productForm.controls.product.setValue({ name: deliveryProduct['name'], quantity: deliveryProduct['quantity'], sku: deliveryProduct['sku'], price: deliveryProduct['price'] });
    } else {
      productForm.controls.product.setValue({ name: deliveryProduct['name'], quantity: deliveryProduct['quantity'], sku: deliveryProduct['sku'], price: deliveryProduct['price'] });
    }
    return productForm;
  }

  addProduct() {
    const control = <FormArray>this.flowersSaleForm.get('deliveryProducts');
    control.push(ShopifySale.productFormGroup());
  }

  removeProduct(index: number) {
    const control = <FormArray>this.flowersSaleForm.get('deliveryProducts');
    control.removeAt(index);
  }

  getAddress(place: any) {
    if (!this.flowersSaleForm) return;
    this.flowersSaleForm.controls.address.touched = true;
    this.flowersSaleForm.controls.address.setValue(place.formatted_address || place.name || '');
    this.flowersSaleForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this.flowersSaleForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');
  }

  directions(event:any){
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this.flowersSaleForm.controls.address.value}`, '_blank');
  }

  onSubmit(e:any=[]){
    let sale: any = (new FormGroupToObject()).transform(this.flowersSaleForm);
    let lineItems = new Array();
    for(let product of sale.deliveryProducts){
      lineItems.push(product.product);
    }
    sale.lineItems = JSON.stringify(lineItems);
    delete sale.channel;
    delete sale.status;
    delete sale.deliveryProducts;
    // sale.orderId = Math.floor(Math.random() * 90000) + 10000;
    // sale.status = 'New';
    // sale.channel = 'By App';
    sale.status = this.sale.status;
    sale.channel = this.sale.channel;
    sale.orderId = this.sale.orderId;
    // let promiseResult: any = this.cakeSaleService.create(sale);
    let promiseResult: any = this.flowersSaleService.update(this.sale.id, sale);
    promiseResult.then((res: any) => {
      if(res){
        this._snackBar.open(`La nuova vendita viene creata con Id ` + res.orderId, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(result => {
          this.router.navigate(['/flowers/vendita']);
        });
      } else{
        this._snackBar.open(`Qualcosa è andato storto?`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(result => {
          this.router.navigate(['/flowers/vendita']);
        });
      }
    }).catch((err: any) => {
      console.log('err :>> ', err);
    })
  }

}
