import { Component, OnInit, ViewChild } from '@angular/core';
import { TeamLeaderFormComponent } from '../team-leader-form/team-leader-form.component';

@Component({
  selector: 'app-team-leader-form-decorator',
  templateUrl: './team-leader-form-decorator.component.html',
  styleUrls: ['./team-leader-form-decorator.component.scss']
})
export class TeamLeaderFormDecoratorComponent implements OnInit {

  @ViewChild(TeamLeaderFormComponent, { static: false }) form: TeamLeaderFormComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
