export class EnumAux {
    static aux;

    static css(key?: string) {
        return key ?
            this.aux[key].css :
            Object.keys(this.aux).reduce(res => {
                res[key] = this.aux[key].css; return res;
            }, {});
    }

    static ln(lang: string, key?: string) {
        return key ?
            this.aux[key].ln[lang] :
            Object.keys(this.aux).reduce(res => {
                res[key] = this.aux[key].ln[lang]; return res;
            }, {});
    }
}

/** --- Example of use ---
 * Create this class instead of an enum inside the schema file.
 *
export class StatusAux extends EnumAux {
    static active = ["created", "assigned"];
    static inactive = ["delivered", "invalidated", "canceled"];

    static aux = {
        created: { css: "red", ln: { it: "Da gestire" } },
        assigned: { css: "yellow", ln: { it: "In gestione" } },
        delivered: { css: "green", ln: { it: "Consegnata" } },
        invalidated: { css: "black", ln: { it: "Annullata in fase di gestione" } },
        canceled: { css: "grey", ln: { it: "Annullata" } },
    }
}
*/
