import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginDTO } from 'src/app/DTO/loginDTO';
import * as jwt from 'jwt-decode';
import { NewPasswordFormComponent } from '../new-password-form/new-password-form.component';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  public loginDTO: LoginDTO;
  public token: string;
  public validToken: boolean = false;
  public activate: boolean = false;
  private newPassword: { password1: string, password2: string };
  public showToken: boolean = false;
  @ViewChild(NewPasswordFormComponent, { static: false }) newPasswordFormComponent: NewPasswordFormComponent;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private _snackBar: MatSnackBar) {
    this.loginDTO = new LoginDTO();
    this.newPassword = { password1: null, password2: null };
  }

  async ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.activate = this.route.snapshot.data['activate'] ? true : false;
    if (this.token) {
      switch ((await this.authService.checkIfValid(this.token)).message) {
        case "TOKEN.VALID_EMAIL":
          this.validToken = true;
          break;
        case "VALID":
        case "INVALID":
        default:
          this.validToken = false;
          this._snackBar.open(
            'Token non valido. Rifare la richiesta di recupero password.',
            'Chiudi',
            {
              direction: "ltr",
              duration: 5000,
              horizontalPosition: "center",
              politeness: "assertive",
              verticalPosition: "top"
            });
          break;
      }
    }
  }

  async recovery() {
    try {
      this.authService.resetPassword(this.loginDTO.email).then((data: any) => {
        const result: { message: string } = data;

        if (result.message === "RESET_PASSWORD.OK") {
          this._snackBar.open(
            'Email per il reset della password inviata correttamente. Controlla la tua casella di posta per continuare la procedura.',
            'Chiudi',
            {
              direction: "ltr",
              duration: 5000,
              horizontalPosition: "center",
              politeness: "assertive",
              verticalPosition: "top"
            });
        }

      }).catch(error => {
        const result: { statusCode: number, message: string } = error.error;
        let message: string = "";
        switch (result.message) {
          case 'RESET_PASSWORD.USER_NOT_EXISTS':
            message = "L'email non esiste.";
            break;
          case 'RESET_PASSWORD.MAIL_NOT_SENT':
            message = "Non è stato possibile inviare l'email. Contattare l'amministratore.";
            break;
          default:
            message = "Errore di sistema. Contattare l'amministratore.";
            break;
        }
        if (message)
          this._snackBar.open(message, 'Chiudi', {
            direction: "ltr",
            duration: 2000,
            horizontalPosition: "center",
            politeness: "assertive",
            verticalPosition: "top"
          });
      });
    } catch (error) {
      console.log('Server error')
    }
  }

  async setNewPassword() {
    try {
      this.newPasswordFormComponent.setNewPassword(this.activate).then((data: any) => {
        const result: { message: string } = data;

        if (result.message === "SET_NEW_PASSWORD.OK") {
          this._snackBar.open(
            'Password reimpostata correttamente. Effettuare il login per accedere.',
            'Chiudi',
            {
              direction: "ltr",
              duration: 5000,
              horizontalPosition: "center",
              politeness: "assertive",
              verticalPosition: "top"
            }
          ).afterDismissed().subscribe(result => {
            this.router.navigate(['/']);
          });
        }

      }).catch(error => {
        const result: { statusCode: number, message: string } = error.error;
        let message: string = "";
        switch (result.message) {
          case 'SET_NEW_PASSWORD.TOKEN_NOT_EXISTS':
            message = "Il token utilizzato non esiste. Rieffettuare la richiesta di recupero.";
            this._snackBar.open(message, 'Chiudi', {
              direction: "ltr",
              duration: 2000,
              horizontalPosition: "center",
              politeness: "assertive",
              verticalPosition: "top"
            }).afterDismissed().subscribe(result => {
              this.router.navigate(['/reset_password']);
            });
            message = "";
            break;
          case 'SET_NEW_PASSWORD.TOKEN_EXPIRED':
            message = "Il token utilizzato è scaduto. Rieffettuare la richiesta di recupero.";
            this._snackBar.open(message, 'Chiudi', {
              direction: "ltr",
              duration: 2000,
              horizontalPosition: "center",
              politeness: "assertive",
              verticalPosition: "top"
            }).afterDismissed().subscribe(result => {
              this.router.navigate(['/reset_password']);
            });
            message = "";
            break;
          case 'SET_NEW_PASSWORD.PASSWORDS_MISMATCH':
            message = "Le password non coincidono.";
            console.log(this.newPassword);
            break;
          default:
            message = "Errore di sistema. Contattare l'amministratore.";
            break;
        }
        if (message)
          this._snackBar.open(message, 'Chiudi', {
            direction: "ltr",
            duration: 2000,
            horizontalPosition: "center",
            politeness: "assertive",
            verticalPosition: "top"
          });
      });
    } catch (error) {
      console.log('Server error')
    }
  }

  checkTokenValidity() {
    const payload: { id: string, iat: number, exp: number } = jwt.default(this.token);
    const now: number = (new Date()).getTime() / 1000;
    return payload.iat < now && now < payload.exp;
  }
}
