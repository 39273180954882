import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({
  providedIn: 'root'
})
export class AllSalesService extends ApiAuthService {

  private readonly url = `${this.baseUrl}/all-sales`;

  async getAllSalesOrders(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }){
    filter['status'] = { operator: 'in', values: ['New', 'Emailed', 'notaccepted'] };
    return this.getAll(page, limit, order, filter);
  }

  async getAllSalesOrdersHistory(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }){
    filter['status'] = { operator: 'in', values: ['Confirmed', 'Refused'] };
    return this.getAll(page, limit, order, filter);
  }

  async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }):Promise<any>{
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.get(`${this.url}`, { params: params }).toPromise() );
  }

}
