import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { Product } from '../models/product.schema';
import { GetAllFilter } from '../models/_to-type-orm.schema';
import { HttpResponse } from '@angular/common/http';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({ providedIn: 'root' })
export class ProductService extends ApiAuthService {
  private readonly url = `${this.baseUrl}/products`;

  getAll(page?: number, limit?: number, order?: {}, filter?: GetAllFilter): Promise<{ products: Product[]; count: number }> {
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() =>
      this.http.get<{ products: Product[]; count: number }>(`${this.url}`, { params: params, }).toPromise()
    );
  }

  getAllActiveProducts(page?: number, limit?: number, order?: {}, filter?: GetAllFilter): Promise<{ products: Product[]; count: number }> {
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() =>
      this.http.get<{ products: Product[]; count: number }>(`${this.url}/active-products`, { params: params, }).toPromise()
    );
  }



  getOne(id: number): Promise<Product> {
    return this.request(() =>
      this.http.get<Product>(`${this.url}/${id}`).toPromise()
    );
  }

  create(productParams: Product): Promise<Product> {
    let body = productParams;
    return this.request(() =>
      this.http.post<Product>(`${this.url}`, body).toPromise()
    );
  }

  update(id: number, productParams: Product): Promise<Product> {
    let body = productParams;
    return this.request(() =>
      this.http.put<Product>(`${this.url}/${id}`, body).toPromise()
    );
  }

  delete(id: number): Promise<{ id: number; success: boolean }> {
    return this.request(() =>
      this.http.delete<{ id: number; success: boolean }>(`${this.url}/${id}`).toPromise()
    );
  }

  findOneImage(id: number): Promise<HttpResponse<Blob>> {
    return this.request(() =>
      this.http
        .get(`${this.url}/img/${id}`, {
          observe: 'response',
          responseType: 'blob',
        })
        .toPromise()
    );
  }

  updateProductPhoto(id: number, file: any) {
    const formData = new FormData();
    formData.append('image', file);
    // console.log('before put', file);
    return this.request(() =>
      this.http.put<Product>(`${this.url}/img/${id}`, formData).toPromise()
    );
  }

  import_product_via_csv(products: Product): Promise<Product>{
    return this.request(() =>
      this.http.post<Product>(`${this.url}/import`, products).toPromise()
    );
  }

  getAllProductsXlsx(order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<HttpResponse<Blob>> {
    let params = {};
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() =>
        this.http
            .get(`${this.url}/get/xlsx`, {
                params: params,
                observe: 'response',
                responseType: 'blob',
            })
            .toPromise()
    );
  }

  findProduct(sku: string): Promise<Product> {
    return this.http.get<Product>(`${this.url}/findProduct/${sku}`).toPromise()
  }

  findProductByName(productName: string): Promise<Product> {
    return this.http.get<Product>(`${this.url}/findProductByName/${productName}`).toPromise()
  }
  async changeProductStatus(productId: number, status: number): Promise<Product> {
    return this.request(() => this.http.patch<Product>(`${this.url}/${productId}/status`, { status: status }).toPromise());
  }
  

}
