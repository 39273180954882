import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({
  providedIn: 'root'
})
export class Service extends ApiAuthService {
  private readonly url = `${this.baseUrl}/service`;

  async getAllServices(): Promise<any>{
    return this.request(() => this.http.get(`${this.url}`).toPromise() );
  }

  async createService(formValues: any): Promise<any>{
    return this.request(() => this.http.post(`${this.url}`, formValues ).toPromise() );
  }

  async getService(serviceId: number): Promise<any>{
    return this.request(() => this.http.get(`${this.url}/${serviceId}` ).toPromise() );
  }

  async deleteService(serviceId: number): Promise<any>{
    return this.request(() => this.http.delete(`${this.url}/${serviceId}` ).toPromise() );
  }

  async updateService(serviceId: number, formValues: any): Promise<any>{
    return this.request(() => this.http.put(`${this.url}/${serviceId}`, formValues ).toPromise() );
  }

  async updateServicePhoto(id: number, file: any) {
    const formData = new FormData();
    formData.append('image', file);
    return this.request(() =>
      this.http.put<Service>(`${this.url}/img/${id}`, formData).toPromise()
    );
  }


  async getAllServicess(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<any> {
    return this.getAll(page, limit, order, filter);
  }

  async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<any> {
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() => this.http.get<any>(`${this.url}`, { params: params }).toPromise());
}

}
