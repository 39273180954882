import { PipeTransform, Pipe } from '@angular/core';
import { ProductService } from '../services/product.service';

@Pipe({ name: 'productImage' })
export class ProductImagePipe implements PipeTransform {

    constructor(
        private productService: ProductService,
    ) { }

    async transform(productId: number): Promise<string> {
        const imageBlob: Blob = (await this.productService.findOneImage(productId)).body;
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(imageBlob);
        });
    }

}
