import { PipeTransform, Pipe } from '@angular/core';
import { isRequired } from '../helpers/control-is-required.helper';

@Pipe({ name: 'required' })
export class RequiredPipe implements PipeTransform {
  transform(control, character: string = "*"): string {
    return isRequired(control) ? character : '';
  }
}

// {{_userForm.get('name') | required}}
