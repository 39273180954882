import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExpertTimeAvailability, StartTimeEndTime } from 'src/app/models/expert-time-availability.schema';
import { PartnerTimeAvailability } from 'src/app/models/partner-time-availability.schema';
import { ExpertService } from 'src/app/services/expert.service';
import { PartnerService } from 'src/app/services/partner.service';

@Component({
  selector: 'app-set-availability-time',
  templateUrl: './set-availability-time.component.html',
  styleUrls: ['./set-availability-time.component.scss']
})
export class SetAvailabilityTimeComponent implements OnInit {

  availabilityData: any;
  formValidationMsgs: any;
  timeAvailabilityForm: any;
  userServices = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { info: {} },
    private partnerService: PartnerService,
    private expertService: ExpertService,
    private dialogRef: MatDialogRef<SetAvailabilityTimeComponent>,
  ) { 
    this.availabilityData = data.info;
    if(this.availabilityData?.userType?.toLowerCase() === 'partner'){
      this.handlePartnerTimeAvailabilityForm();
    }
    if(this.availabilityData?.userType?.toLowerCase() === 'expert'){
      this.handleExpertTimeAvailabilityForm();
    }
    this.userServices = {
      partner: this.partnerService,
      expert: this.expertService,
    }
  }

  ngOnInit(){}

  async onSubmit(){
    if(this.timeAvailabilityForm.valid){
      if(this.availabilityData?.userType?.toLowerCase() === 'partner'){
        this.timeAvailabilityForm.controls.partner.setValue(this.availabilityData.partner.id);
      }
      if(this.availabilityData?.userType?.toLowerCase() === 'expert'){
        this.timeAvailabilityForm.controls.expert.setValue(this.availabilityData.expert.id);
      }
      this.timeAvailabilityForm.controls.date.setValue(this.availabilityData.date);
      let promise: any;
      this.timeAvailabilityForm.value.updateAbleData = this.availabilityData.updateAbleData[0]?.id != undefined ? this.availabilityData.updateAbleData : null;
      promise = this.userServices[this.availabilityData?.userType].setTimeAvailability(this.timeAvailabilityForm.value);
      promise.then((res) => {
        if(res){
          this.dialogRef.close({ success: true });
        } else {
          this.dialogRef.close({ success: false });
        }
      }).catch((err) => {
        this.dialogRef.close({ success: false });
      });
    }
  }

  handlePartnerTimeAvailabilityForm(){
    this.timeAvailabilityForm = new FormGroup(PartnerTimeAvailability.validation());
    if(this.availabilityData.updateAbleData){
      const control = <FormArray>this.timeAvailabilityForm.get('startEndTime');
      control.clear();
      if (!this.availabilityData.updateAbleData || Array.isArray(this.availabilityData.updateAbleData) && this.availabilityData.updateAbleData.length == 0){
        this.availabilityData.updateAbleData = [new StartTimeEndTime()]; 
      }
      this.availabilityData.updateAbleData.forEach(f => {
        control.push(this.patchValues(f));
      });
      this.timeAvailabilityForm.controls.available.setValue(this.availabilityData.updateAbleData[0]?.id != undefined ? this.availabilityData.updateAbleData[0]?.available : true);
    }
    this.formValidationMsgs = PartnerTimeAvailability.validationMessages;
  }

  handleExpertTimeAvailabilityForm(){
    this.timeAvailabilityForm = new FormGroup(ExpertTimeAvailability.validation());

    if(this.availabilityData.updateAbleData){
      const control = <FormArray>this.timeAvailabilityForm.get('startEndTime');
      control.clear();
      if (!this.availabilityData.updateAbleData || Array.isArray(this.availabilityData.updateAbleData) && this.availabilityData.updateAbleData.length == 0){
        this.availabilityData.updateAbleData = [new StartTimeEndTime()]; 
      }
      this.availabilityData.updateAbleData.forEach(f => {
        control.push(this.patchValues(f));
      });
      this.timeAvailabilityForm.controls.available.setValue(this.availabilityData.updateAbleData[0]?.id != undefined ? this.availabilityData.updateAbleData[0]?.available : true);
    }
    this.formValidationMsgs = ExpertTimeAvailability.validationMessages;
  }

  patchValues(timings: StartTimeEndTime) {
    let timingForm = ExpertTimeAvailability.timingFormGroup();
    if (timings?.id){
      timingForm.controls.timings.setValue(
        { 
          startTime: timings?.startTime,
          endTime: timings?.endTime 
        }
      );
    }
    return timingForm;
  }

  addTiming(){
    const control = <FormArray>this.timeAvailabilityForm.get('startEndTime');
    if(this.availabilityData?.userType?.toLowerCase() === 'partner'){
      control.push(PartnerTimeAvailability.timingFormGroup());
    }
    if(this.availabilityData?.userType?.toLowerCase() === 'expert'){
      control.push(ExpertTimeAvailability.timingFormGroup());
    }
  }

  removeTiming(index: number){
    const control = <FormArray>this.timeAvailabilityForm.get('startEndTime');
    if (control.length === 1) {
      return;
    }
    control.removeAt(index);
  }
  

}
