import { AbstractControl, FormControl, Validators } from '@angular/forms';

export class ExpertServiceSchema {
    public static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            serviceName: new FormControl('', Validators.required),
            serviceType: new FormControl('', Validators.required),
            notes: new FormControl(''),
        };
        return validator;
    }

    public static readonly validationMessages = {
        'serviceName': [{ type: 'required', message: 'Obbligatorio' }],
        'serviceType': [{ type: 'required', message: 'Obbligatorio' }],
    };

    constructor(
        public id?: number,
        public serviceName?: string,
        public serviceType?: string,
        public notes?: string,
    ) { }
}