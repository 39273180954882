import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function fileType(type: string | string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const file = control.value;
        if (file) {
            const extension = file.name.split('.')[1].toLowerCase();
            if (Array.isArray(type)) {
                if (!type.map(t => t.toLowerCase()).includes(extension.toLowerCase()))
                    return { fileType: true };
            }
            else {
                if (type.toLowerCase() !== extension.toLowerCase())
                    return { fileType: true };
            }
            return null;
        }
        return null;
    };
}
