import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeliveryService } from 'src/app/services/delivery.service';

@Component({
  selector: 'app-delivery-not-delivered-dialog',
  templateUrl: './delivery-not-delivered-dialog.component.html',
  styleUrls: ['./delivery-not-delivered-dialog.component.scss']
})
export class DeliveryNotDeliveredDialogComponent implements OnInit {
  @ViewChild('notes') notes: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    private deliveryService: DeliveryService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<DeliveryNotDeliveredDialogComponent>,
  ) { }

  ngOnInit() {
  }

  public async notDelivered(notes: string) {
    if (!confirm("Sei sicuro di voler impostare questa consegna come non consegnata?"))
      return;
    return this.deliveryService.setDeliveryNotDelivered(this.data.id, { notes }).then(data => {
      this.snack(`Consegna aggiornata con successo`);
      this.dialogRef.close({ done: true });
    }).catch(err => {
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
      this.dialogRef.close({ done: false });
    });
  }

  snack(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  onSubmit() {
    if (!this.notes.nativeElement.value.trim()) {
      this.snack(`Note obbligatorie`);
      return;
    }
    return this.notDelivered(this.notes.nativeElement.value.trim());
  }

}
