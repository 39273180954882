import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCategory } from 'src/app/models/product-category.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss']
})
export class ProductCategoryComponent implements OnInit {

  productCategories: ProductCategory[] = [];
  productCategoryFilterForm: any;
  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'ProductCategory.createdAt': 'DESC' };
  public groups: string[];

  constructor(
    private route: ActivatedRoute,
    private productCategoryService: ProductCategoryService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private authStatusService: AuthStatusService,
  ) { 
    this.manageProductCategoryFilterForm();
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    await this.loadProductCategories();
  }

  manageProductCategoryFilterForm(){
    this.productCategoryFilterForm = new FormGroup({
      'ProductCategory.id': new FormControl(''),
      'ProductCategory.categoryName': new FormControl(''),
      'ProductCategory.notes': new FormControl(''),
    });
  }

  async loadProductCategories(limit?: number, page?: number, order?: {}){
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let productCategories = await this.productCategoryService.getAllProductCategories(this.page, this.limit, this.order, this.filtered);
    this.productCategories = productCategories;
    this.count = productCategories.length;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  async filter(){
    let filterForm = (new FormGroupToObject()).transform(this.productCategoryFilterForm);
    this.filtered = {};

    if (filterForm['ProductCategory.id'])
      this.filtered['ProductCategory.id'] = filterForm['ProductCategory.id'];    
    if (filterForm['ProductCategory.categoryName'])
      this.filtered['ProductCategory.categoryName'] = { operator: 'like', values: [`%${filterForm['ProductCategory.categoryName']}%`] };
    if (filterForm['ProductCategory.notes'])
      this.filtered['ProductCategory.notes'] = { operator: 'like', values: [`%${filterForm['ProductCategory.notes']}%`] };

    this.loadProductCategories();
  }

  async resetFilter(){
    this.manageProductCategoryFilterForm();
    this.filter();
  }

  async deleteProductCategory(pCategoryId: number){
    if (!confirm("Sei sicuro di voler eliminare questo Product Categoria?"))
      return;
    try { 
      const result = await this.productCategoryService.deleteProductCategory(pCategoryId);
      await this.showToastMessage('Product Categoria cancellato con successo');
      await this.loadProductCategories();
    } catch (error) {
      this.showToastMessage(`${error.error.message}`);
    }
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadProductCategories();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  async showToastMessage(message: string){
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

}
