import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerDeliveryRulesSchema } from 'src/app/models/partner-delivery-rules.schema';
import { Partner } from 'src/app/models/partner.schema';
import { ServiceModel } from 'src/app/models/service.schems';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { PartnerDeliveryRulesService } from 'src/app/services/partner-delivery-rules.service';
import { PartnerService } from 'src/app/services/partner.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-partner-delivery-rules-form',
  templateUrl: './partner-delivery-rules-form.component.html',
  styleUrls: ['./partner-delivery-rules-form.component.scss']
})
export class PartnerDeliveryRulesFormComponent implements OnInit {

  groups: string[];
  ruleId: string;
  partnerDeliveryRule: PartnerDeliveryRulesSchema;
  partnerDeliveryRulesForm: any;
  formValidationMsgs: any
  services: ServiceModel
  partners: Partner[] = [];
  tmpBtnDisabled: boolean;
  days: { selected: boolean }[] = [
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false }
  ];
  weekDays = ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private partnerService: PartnerService,
    private partnerDeliveryRulesService: PartnerDeliveryRulesService,
    private _snackBar: MatSnackBar,
  ) { 
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
      this.formValidationMsgs = PartnerDeliveryRulesSchema.validationMessages;
      this.handlePartnerDeliveryRulesForm();
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() { 
    try {
      await this.getAllActivePartners();
      const ruleId: string = this.route.snapshot.paramMap.get('id');
      this.ruleId = ruleId;
      if (this.ruleId) {
        try {
          this.partnerDeliveryRule = await this.partnerDeliveryRulesService.getOne(Number(this.ruleId));
        }
        catch {
          this.router.navigateByUrl("/404", { skipLocationChange: true });
        }
      }

      if (this.partnerDeliveryRule){
        this.patch();
      } else {
        this.partnerDeliveryRule = new PartnerDeliveryRulesSchema();
      }
    } catch (error) {
      this.router.navigateByUrl("/404", { skipLocationChange: true });
    }
  }

  handlePartnerDeliveryRulesForm(){
    this.partnerDeliveryRulesForm = new FormGroup(PartnerDeliveryRulesSchema.validation());
    this.formValidationMsgs = PartnerDeliveryRulesSchema.validationMessages;
  }

  async getAllActivePartners(){
    try {
      this.partners = (await this.partnerService.getAllActivePartners(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
    } catch (error) {
      
    }
  }

  patch(){
    ['startTimeRange', 'endTimeRange', 'serviceType', 'kmLimit', 'perDayDeliveryLimit', 'totalDeliveryLimit', 'billable', 'payable', 'additionalPrice', 'valetAdditionalPrice', 'validFrom', 'validTill', 'totalDeliveryRule', 'perDayDeliveryRule'].forEach((param) => {
      if (this.partnerDeliveryRule[param] instanceof Date){
        this.partnerDeliveryRulesForm.controls[param].setValue(new DatePipe('it-IT').transform(this.partnerDeliveryRule[param], 'yyyy-MM-dd'));
      } else {
        this.partnerDeliveryRulesForm.controls[param].setValue(this.partnerDeliveryRule[param]);
      }
      if(this.partnerDeliveryRule.days.length){
        this.days = this.partnerDeliveryRule.days;
      }
    });
    if (this.partnerDeliveryRule.partner){
      this.partnerDeliveryRulesForm.controls.partner.controls.id.setValue(this.partnerDeliveryRule.partner.id);
    }
  }

  async onSubmit(){
    if(this.partnerDeliveryRulesForm.valid){
      this.tmpBtnDisabled = true;
      let partnerDeliveryRule: any = new FormGroupToObject().transform(this.partnerDeliveryRulesForm);
      partnerDeliveryRule.days = this.days;
      let promiseResult: any;
      if (this.partnerDeliveryRule['id']) {
        promiseResult = this.partnerDeliveryRulesService.updatepartnerDeliveryRule(this.partnerDeliveryRule['id'], partnerDeliveryRule);
      } else {
        promiseResult = this.partnerDeliveryRulesService.create(partnerDeliveryRule);
      }
      let message: string = this.partnerDeliveryRule['id'] ? "Partner Conegna Rule aggiornato" : "Partner Conegna Rule creato";
      promiseResult.then(async (response: any) => {
        this.tmpBtnDisabled = false;
        await this.showSnackBar(message);
        await this.router.navigate(['/partner/delivery/rules']);
      }).catch(async (error: any) => {
        this.tmpBtnDisabled = false;
        await this.showSnackBar(error.error.message);
      });
    }
  }

  onCheckboxChange(selectedCheckbox: string) {
    if (selectedCheckbox === 'perDayDeliveryRule') {
        this.partnerDeliveryRulesForm.patchValue({
            totalDeliveryRule: !this.partnerDeliveryRulesForm.get('perDayDeliveryRule').value,
        });
    } else if (selectedCheckbox === 'totalDeliveryRule') {
        this.partnerDeliveryRulesForm.patchValue({
            perDayDeliveryRule: !this.partnerDeliveryRulesForm.get('totalDeliveryRule').value,
        });
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

}
