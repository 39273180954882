import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStatusService } from '../../services/auth-status.service';
import { SmsTemplate } from '../../models/sms-template.schema';
import { FormArray, FormGroup } from '@angular/forms';
import { SmsTemplatesService } from '../../services/sms-templates.service';


@Component({
  selector: 'app-sms-templates-form',
  templateUrl: './sms-templates-form.component.html',
  styleUrls: ['./sms-templates-form.component.scss']
})
export class SmsTemplatesFormComponent implements OnInit {

  public readonly formValidationMsgs;
  public _smsTemplateForm: any;
  public smsTemplate: SmsTemplate;
  public tmpBtnDisabled: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private smsTemplateService: SmsTemplatesService,
    private _snackBar: MatSnackBar,
  ) { 
    this.formValidationMsgs = SmsTemplate.validationMessages;
  }

  async ngOnInit() {
    this.tmpBtnDisabled = false;
    this._smsTemplateForm = new FormGroup(SmsTemplate.validation());
    const smsTempId: string = this.route.snapshot.paramMap.get('id');
    if (smsTempId) {
      try {
        this.smsTemplate = await this.smsTemplateService.getOne(Number(smsTempId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.smsTemplate)
      this.patch();
    else
      this.smsTemplate = new SmsTemplate();
  }

  onSubmit(){
    let smsTemp: SmsTemplate = new FormGroupToObject().transform(this._smsTemplateForm);
    let promiseResult: any;
    if (this.smsTemplate['id']) {
      promiseResult = this.smsTemplateService.update(this.smsTemplate['id'], smsTemp);
    } else {
      promiseResult = this.smsTemplateService.create(smsTemp);
    }
    promiseResult.then(async(data: SmsTemplate) => {
      if(data['status'] === false){
        this._snackBar.open('Non puoi creare un altro modello di sms con lo stesso tipo', 'Chiudi', {
          direction: 'ltr',
          duration: 2000,
          horizontalPosition: 'center',
          politeness: 'assertive',
          verticalPosition: 'top',
        });
        return false;
      }
      this.smsTemplate = await this.smsTemplateService.getOne(data.id);
      const message: string = `Modello di messaggio creato con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: 'ltr',
        duration: 2000,
        horizontalPosition: 'center',
        politeness: 'assertive',
        verticalPosition: 'top',
      }).afterDismissed().subscribe(result => {
        this.router.navigate(['/smstemplates', this.smsTemplate.id, 'edit']);
      });
    }).catch((err: any) => {
      console.log("err", err)
    });
  }

  patch(){
    ['temp_type', 'description'].forEach(param => {
      this._smsTemplateForm.controls[param].setValue(this.smsTemplate[param]);
    });
  }

}
