import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.schema';
import { UserService } from 'src/app/services/user.service';
import { AuthStatusService } from '../../services/auth-status.service';
import { ExpertService } from 'src/app/services/expert.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public buttonsClicked;
  public userLoggedIn: boolean;
  public groups: string[];
  public alertUnreadCounter: number;
  public user: any;
  public guestView: boolean = true;
  public smsCheck: any;
  public loggedInExpert: any;

  constructor(
    private authStatusService: AuthStatusService,
    private userService: UserService,
    private expertService: ExpertService,
  ) {
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    this.guestView = !(await this.authStatusService.isAuthenticated());
    this.buttonsClicked = {
      notificationShade: false,
      userShade: false,
      adminShade: false
    };
    if (!this.guestView)
      this.user = await this.userService.getMyInformation();
      sessionStorage.setItem("currentUser", JSON.stringify(this.user));
    if(this.user)
      this.smsCheck = this.user['partner']?.sendSms;

    const loggedInUserInfo = await this.authStatusService.getTokenInfo();
    if(this.groups.includes('expert') && loggedInUserInfo){
      this.loggedInExpert = await this.expertService.getOne(loggedInUserInfo['extraId']);
    }
  }

  ngAfterViewInit() {
    $('header.main-bar a').click(this.closeNavIcon);
  }

  buttonClicked(id: string) {
    event.preventDefault();
    if (!this.buttonsClicked[id]) {
      Object.keys(this.buttonsClicked).forEach(key => {
        this.buttonsClicked[key] = false;
        if (key != id)
          $(`#${key}Dropdown`).hide('fast');
      });
    }
    this.buttonsClicked[id] = !this.buttonsClicked[id];
    $(`#${id}Dropdown`).toggle('fast');
  }

  navIconClicked() {
    $('#navIcon').toggleClass('open');
    $('.header-nav').slideToggle('fast');
  }

  closeNavIcon() {
    $('#navIcon').removeClass('open');
    $('.header-nav').slideUp('fast');
  }
}
