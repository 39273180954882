import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpertDeliveryRulesSchema } from 'src/app/models/expert-delivery-rules.schema';
import { Expert } from 'src/app/models/expert.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { ExpertDeliveryRulesService } from 'src/app/services/expert-delivery-rules.service';
import { ExpertService } from 'src/app/services/expert.service';

@Component({
  selector: 'app-expert-delivery-rules-form',
  templateUrl: './expert-delivery-rules-form.component.html',
  styleUrls: ['./expert-delivery-rules-form.component.scss']
})
export class ExpertDeliveryRulesFormComponent implements OnInit {

  groups: string[];
  ruleId: string;
  expertDeliveryRule: ExpertDeliveryRulesSchema;
  expertDeliveryRulesForm: any;
  formValidationMsgs: any
  experts: Expert[] = [];
  tmpBtnDisabled: boolean;
  days: { selected: boolean }[] = [
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false },
    { selected: false }
  ];
  weekDays = ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private expertService: ExpertService,
    private expertDeliveryRulesService: ExpertDeliveryRulesService,
    private _snackBar: MatSnackBar,
  ) { 
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
      this.formValidationMsgs = ExpertDeliveryRulesSchema.validationMessages;
      this.handleExpertDeliveryRulesForm();
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() { 
    try {
      await this.getAllActiveExperts();
      const ruleId: string = this.route.snapshot.paramMap.get('id');
      this.ruleId = ruleId;
      if (this.ruleId) {
        this.expertDeliveryRule = await this.expertDeliveryRulesService.getOne(Number(this.ruleId));
      }
      if (this.expertDeliveryRule){
        this.patch();
      } else {
        this.expertDeliveryRule = new ExpertDeliveryRulesSchema();
      }
    } catch (error) {
      this.router.navigateByUrl("/404", { skipLocationChange: true });
    }
  }

  patch(){
    ['startTimeRange', 'endTimeRange', 'serviceType', 'kmLimit', 'perDayDeliveryLimit', 'totalDeliveryLimit', 'billable', 'payable', 'additionalPrice', 'valetAdditionalPrice', 'validFrom', 'validTill'].forEach((param) => {
      if (this.expertDeliveryRule[param] instanceof Date){
        this.expertDeliveryRulesForm.controls[param].setValue(new DatePipe('it-IT').transform(this.expertDeliveryRule[param], 'yyyy-MM-dd'));
      } else {
        this.expertDeliveryRulesForm.controls[param].setValue(this.expertDeliveryRule[param]);
      }
      if(this.expertDeliveryRule.days.length){
        this.days = this.expertDeliveryRule.days;
      }
    });
    if (this.expertDeliveryRule.expert){
      this.expertDeliveryRulesForm.controls.expert.controls.id.setValue(this.expertDeliveryRule.expert.id);
    }
  }

  handleExpertDeliveryRulesForm(){
    this.expertDeliveryRulesForm = new FormGroup(ExpertDeliveryRulesSchema.validation());
    this.formValidationMsgs = ExpertDeliveryRulesSchema.validationMessages;
  }

  async onSubmit(){
    if(this.expertDeliveryRulesForm.valid){
      this.tmpBtnDisabled = true;
      let expertDeliveryRule: any = new FormGroupToObject().transform(this.expertDeliveryRulesForm);
      expertDeliveryRule.days = this.days;
      let promiseResult: any;
      if (this.expertDeliveryRule['id']) {
        promiseResult = this.expertDeliveryRulesService.updateExpertDeliveryRule(this.expertDeliveryRule['id'], expertDeliveryRule);
      } else {
        promiseResult = this.expertDeliveryRulesService.create(expertDeliveryRule);
      }
      let message: string = this.expertDeliveryRule['id'] ? "Valet Conegna Rule aggiornato" : "Valet Conegna Rule creato";
      promiseResult.then(async (response: any) => {
        this.tmpBtnDisabled = false;
        await this.showSnackBar(message);
        await this.router.navigate(['/expert/delivery/rules']);
      }).catch(async (error: any) => {
        this.tmpBtnDisabled = false;
        await this.showSnackBar(error.error.message);
      });
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

  async getAllActiveExperts(){
    try {
      this.experts = (await this.expertService.getAllActiveExperts(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
    }catch { }
  }

  onCheckboxChange(selectedCheckbox: string) {
    if (selectedCheckbox === 'perDayDeliveryRule') {
        this.expertDeliveryRulesForm.patchValue({
            totalDeliveryRule: !this.expertDeliveryRulesForm.get('perDayDeliveryRule').value,
        });
    } else if (selectedCheckbox === 'totalDeliveryRule') {
        this.expertDeliveryRulesForm.patchValue({
            perDayDeliveryRule: !this.expertDeliveryRulesForm.get('totalDeliveryRule').value,
        });
    }
  }

}
