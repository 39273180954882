import { PipeTransform, Pipe } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({ name: 'formGroupToObject' })
export class FormGroupToObject implements PipeTransform {
    transform(form: FormGroup): {} {
        return this.genericCast(form);
    }

    private genericCast(form: FormGroup) {
        let result = {};
        Object.keys(form.controls).forEach(key => {
            if (Array.isArray(form.controls[key]))
                result = form.controls[key]['controls'].map(elem => this.genericCast(elem));
            else if (form.controls[key])
                result[key] = form.controls[key].value;
        });
        return result;
    }
}
