import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProvincesService extends ApiAuthService {

  private readonly url = `${this.baseUrl}/provinces`;

  async getAllProvinces(): Promise<any>{
    return this.request(() => this.http.get(`${this.url}`).toPromise() );
  }

}
