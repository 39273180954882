import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-error-html-handler',
  templateUrl: './error-html-handler.component.html',
  styleUrls: ['./error-html-handler.component.scss']
})
export class ErrorHtmlHandlerComponent implements OnInit {
  private _formControl: AbstractControl;
  @Input('messages') public messages: [{}];

  @Input('ctrl') set formControl(value: AbstractControl) { this._formControl = value; }
  get formControl(): AbstractControl { return this._formControl; }

  constructor() { }

  ngOnInit() {
  }

}
