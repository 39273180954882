import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStatusService } from '../../services/auth-status.service';
import { AdminSmsTemplate } from '../../models/admin-sms-template.schema';
import { AdminSmsTemplatesService } from '../../services/admin-sms-templates.service';
import { Partner } from '../../models/partner.schema';
import { SmsTemplate } from '../../models/sms-template.schema';
import { PartnerService } from '../../services/partner.service';
import { FormArray, FormGroup } from '@angular/forms';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';

@Component({
  selector: 'app-admin-sms-template-form',
  templateUrl: './admin-sms-template-form.component.html',
  styleUrls: ['./admin-sms-template-form.component.scss']
})
export class AdminSmsTemplateFormComponent implements OnInit {

  public groups: string[];
  public readonly formValidationMsgs;
  public _smsTemplateForm: any;
  public adminSmsTemplate: AdminSmsTemplate;
  public tmpBtnDisabled: boolean;
  public partners: Partner[];
  public selectReadOnly: boolean = false;
  public smsTempForAdmin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private adminSmsTemplatesService: AdminSmsTemplatesService,
    private partnerService: PartnerService,
    private _snackBar: MatSnackBar,
  ) {
    this.formValidationMsgs = AdminSmsTemplate.validationMessages;
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
  }

  async ngOnInit() {

    this.tmpBtnDisabled = false;
    this._smsTemplateForm = new FormGroup(AdminSmsTemplate.validation());

    if (this.groups.includes('admin')) {
      try {
        this.partners = (await this.partnerService.getAllActivePartners(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
      }
      catch { }
    }

    const smsTempId: string = this.route.snapshot.paramMap.get('id');
    if (smsTempId) {
      this.selectReadOnly = true;
      try {
        this.adminSmsTemplate = await this.adminSmsTemplatesService.getOne(Number(smsTempId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.adminSmsTemplate){
      this.patch();
    }
    else
      this.adminSmsTemplate = new SmsTemplate();
  }

  onSubmit(e:any=[]){
    this.tmpBtnDisabled = true;
    let adminSmsTemp: AdminSmsTemplate = (new FormGroupToObject()).transform(this._smsTemplateForm);
    let promiseResult: any;
    if (this.adminSmsTemplate['id']) {
      console.log("Template Update")
      // return false;
      promiseResult = this.adminSmsTemplatesService.update(this.adminSmsTemplate['id'], adminSmsTemp);
    } else {
      console.log("Template Create")
      promiseResult = this.adminSmsTemplatesService.create(adminSmsTemp, this.smsTempForAdmin);
    }
    promiseResult.then(async(data: SmsTemplate) => {
      if(data['status'] === false){
        this._snackBar.open('Non puoi creare un altro modello di sms con lo stesso tipo', 'Chiudi', {
          direction: 'ltr',
          duration: 2000,
          horizontalPosition: 'center',
          politeness: 'assertive',
          verticalPosition: 'top',
        });
        return false;
      }
      this.adminSmsTemplate = await this.adminSmsTemplatesService.getOne(data.id);
      const message: string = `Modello di messaggio creato con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: 'ltr',
        duration: 2000,
        horizontalPosition: 'center',
        politeness: 'assertive',
        verticalPosition: 'top',
      }).afterDismissed().subscribe(result => {
        this.router.navigate(['/admin/smstemplates', this.adminSmsTemplate.id, 'edit']);
      });
    }).catch((err: any) => {
      console.log("err", err)
    });
  }

  patch(){
    ['temp_type', 'description'].forEach(param => {
      this._smsTemplateForm.controls[param].setValue(this.adminSmsTemplate[param]);
    });
    if (this.adminSmsTemplate.partner)
      this._smsTemplateForm.controls.partner.controls.id.setValue(this.adminSmsTemplate.partner.id);
  }

  handleClickForDeluxy($event){
    this.smsTempForAdmin = $event.target.checked;
    console.log("smsTempForAdmin", this.smsTempForAdmin)
  }

}
