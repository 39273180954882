import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User } from '../../../models/user.schema';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  public readonly formValidationMsgs;
  public registrationForm: FormGroup;
  private checkedPassword1: boolean;
  private checkedPassword2: boolean;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private _snackBar: MatSnackBar) {
    this.formValidationMsgs = User.validationMessages
    this.registrationForm = new FormGroup({ ...User.loginValidation(), ...User.specificValidation() });
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('token'))
      this.activate();
    this.checkedPassword1 = false;
    this.checkedPassword2 = false;

    this.registrationForm.controls.password1.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword1) {
        this.checkedPassword1 = true;
        this.registrationForm.controls.password2.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });

    this.registrationForm.controls.password2.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword2) {
        this.checkedPassword2 = true;
        this.registrationForm.controls.password1.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });
  }

  async submit() {
    if (!this.registrationForm.valid)
      return null;
    let success = undefined;
    let values = this.registrationForm.value;
    if (values.password1 === values.password2) {
      values.password = values.password1;
      delete values.password1;
      delete values.password2;
    }
    try {
      await this.authService.createUser(values).then((data: User) => {
        this._snackBar.open("Richiesta di registrazione inviata con successo. " +
          "Controlla la tua casella di posta per proseguire la procedura.", 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
        success = true;
      }).catch(error => {
        success = false;
        const result: { statusCode: number, message: string } = error.error;
        let message: string = "";
        switch (result.message) {
          case 'NEW_USER.EMAIL_EXISTS':
          case 'REGISTRATION.EMAIL_EXISTS':
            message = "Esiste già un account associato all'email inserita.";
            break;
          case 'NEW_USER.MAIL_NOT_SENT':
          case 'REGISTRATION.MAIL_NOT_SENT':
            message = "Non è stato possibile inviare l'email. Contattare l'amministratore.";
            break;
          default:
            message = "Errore di sistema. Contattare l'amministratore.";
            break;
        }
        if (message)
          this._snackBar.open(message, 'Chiudi', {
            direction: "ltr",
            duration: 2000,
            horizontalPosition: "center",
            politeness: "assertive",
            verticalPosition: "top"
          });
      });
    } catch (error) {
      console.log('Server error')
    }
    return success;
  }

  async activate() {
    const token = this.route.snapshot.paramMap.get('token');
    if (token) {
      this.authService.activateUser(token).then((data: User) => {
        this._snackBar.open(
          "Attivazione avvenuta con successo. Effettuare il login per accedere.",
          'Chiudi',
          {
            direction: "ltr",
            duration: 2000,
            horizontalPosition: "center",
            politeness: "assertive",
            verticalPosition: "top"
          }).afterDismissed().subscribe(result => {
            this.router.navigate(['/']);
          });
      }).catch(error => {
        const result: { statusCode: number, message: string } = error.error;
        let message: string;
        switch (result.message) {
          case "ACTIVATION.INVALID_USER":
            message = message || "Utente non valido. Contattare l'amministrazione.";
          case "ACTIVATION.EXPIRED_TOKEN":
          case "ACTIVATION.TOKEN_NOT_EXISTS":
            // message = message || "Token non valido. Richiedere l'email di attivazione.";
            message = message || "Grazie per esserti registrato a Deluxy, il tuo profilo è ora in attesa di approvazione, prova a loggarti più tardi.";
          default:
            message = message || "Errore di sistema. Contattare l'amministratore.";
            this._snackBar.open(
              message,
              'Chiudi',
              {
                direction: "ltr",
                duration: 5000,
                horizontalPosition: "center",
                politeness: "assertive",
                verticalPosition: "top"
              });
            break;
        }
      });
    }
  }

}
