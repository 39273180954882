import { AbstractControl, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { User } from './user.schema';
import { Vehicle } from './vehicle.schema';
import { phoneValid } from '../helpers/validators/phone.validator';

export class TeamLeader {
    public static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            phone: new FormControl('', Validators.compose([Validators.required, phoneValid()])),
            address: new FormControl('', Validators.required),
            // province: new FormControl('', Validators.required),
            longitude: new FormControl(''),
            latitude: new FormControl(''),
            notes: new FormControl(''),
            teamLeaderProvinces: new FormArray([new FormGroup(TeamLeaderProvinces.validation())]),
        };
        validator['user'] = profile ?
            new FormGroup({ ...User.specificValidation(), ...User.editLoginValidation() }) :
            new FormGroup(User.specificValidation());
        return validator;
    }

    public static readonly validationMessages = {
        'phone': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }
        ],
        'address': [{ type: 'required', message: 'Obbligatorio' }],
        'province': [{ type: 'required', message: 'Obbligatorio' }],
        'user': User.validationMessages
    };

    public static teamLeaderProvinceFormGroup() {
        return new FormGroup(TeamLeaderProvinces.validation());
    }

    constructor(
        public id?: number,
        public phone?: string,
        public address?: string,
        public longitude?: string,
        public latitude?: string,
        public notes?: string,
        public user?: User,
        // public province?: any,
        public teamLeaderProvinces?: TeamLeaderProvinces[]
    ) { }
}

export class TeamLeaderProvinces {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            province: this.provinceFormGroup(),
        };
        return validator;
    }

    public static provinceFormGroup() {
        return new FormGroup({
            id: new FormControl(null),
        });
    }

    constructor(
        public id?: number,
        public provinceCode?: string,
        public province?: any,
        public updatedAt?: Date,
        public createdAt?: Date,
    ) { }
}
