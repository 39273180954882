import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as assert from 'assert';
import * as jwt from 'jwt-decode';
import { AuthService } from './auth.service';

@Injectable()
export class AuthStatusService {
    constructor(
        public jwtHelper: JwtHelperService,
        private authService: AuthService,
    ) { }

    public logout() {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
    }

    public async isAuthenticated(reRun = 0): Promise<boolean> {
        try {
            const token = localStorage.getItem('access');
            assert(token !== null && token !== undefined);
            if (!this.jwtHelper.isTokenExpired(token)) {
                // Check blacklist
                let validity = await this.authService.checkIfValid(token);
                if (validity.message !== 'TOKEN.VALID') {
                    this.logout();
                    return false;
                }
            }
            return !this.jwtHelper.isTokenExpired(token) ||
                (this.jwtHelper.isTokenExpired(token)
                    && reRun < 1   // avoid overflow
                    && !this.isRefreshTokenExpired()
                    && await this.requestNewToken()
                    && this.isAuthenticated(reRun + 1));
        } catch (error) {
            return false;
        }
    }

    public getTokenInfo() {
        if (!this.isAuthenticated())
            return undefined;
        const token = localStorage.getItem('access');
        return this.jwtHelper.decodeToken(token);
    }

    public getRoles() {
        const token = this.getTokenInfo();
        return token['roles'];
    }

    public getUserId() {
        const token = this.getTokenInfo();
        return token['id'];
    }

    public getExtra() {
        const token = this.getTokenInfo();
        return { type: token['extraType'], id: token['extraId'] };
    }

    public async requestNewToken(): Promise<boolean> {
        try {
            assert(!this.isRefreshTokenExpired());
            const token = localStorage.getItem('access');
            assert(token !== null && token !== undefined);
            const payload: any = jwt.default(token);
            const now = new Date();
            const expire = payload.exp * 1000 - now.getTime();
            assert(expire < 5 * 60 * 1000); // 5 minutes
            const newAccessToken = (await this.authService.getAccessToken(localStorage.getItem('refresh')))['accessToken'];
            this.setAccessStorage(newAccessToken);
            return true;
        }
        catch (error) {
            return false;
        }

    }

    public setAccessStorage(token: string) {
        localStorage.setItem('access', token);
    }

    public setRefreshStorage(token: string) {
        localStorage.setItem('refresh', token);
    }

    private isRefreshTokenExpired(): boolean {
        try {
            const token = localStorage.getItem('refresh');
            assert(token !== null && token !== undefined);
            return this.jwtHelper.isTokenExpired(token);
        } catch (error) {
            return true;
        }
    }
}
