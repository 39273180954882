import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-priority-list',
  templateUrl: './product-priority-list.component.html',
  styleUrls: ['./product-priority-list.component.scss']
})
export class ProductPriorityListComponent implements OnInit {

  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 10;
  defaultOrder = { 'ProductPriorityList.createdAt': 'DESC' };
  productPriorityLsitFilterForm: any;
  productPriorityLists: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { 
    this.manageProductPriorityLsitFilterForm();
  }

  manageProductPriorityLsitFilterForm(){
    this.productPriorityLsitFilterForm = new FormGroup({
      'ProductPriorityList.id': new FormControl(''),
    });
  }

  async ngOnInit() {}

  async filter(){}

}
