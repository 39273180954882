import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';
import { ShopifySale } from '../models/shopify-sale.schema';
import { Delivery } from '../models/delivery.schema';

@Injectable({
  providedIn: 'root'
})

export class ShopifySaleService extends ApiAuthService {
  private readonly url = `${this.baseUrl}/shopify-sale`;

  async getShopifyOrders(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }){
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() =>
      this.http.get(`${this.url}`, { params: params }).toPromise()
    );
  }

  async getShopifyOrderHistory(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }){
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() =>
      this.http.get(`${this.url}/order-history`, { params: params }).toPromise()
    );
  }

  async getShopifyOrdersVaiApi(){
    return this.request(() =>
      this.http.get(`${this.url}/using-api`).toPromise()
    );
  }

  async refuseSaleByAdmin(id: number): Promise<{ id: number, success: boolean }> {
    return this.request(() => this.http.delete<{ id: number, success: boolean }>(`${this.url}/${id}`).toPromise());
  }

  async getOne(saleId: string): Promise<ShopifySale> {
    return this.request(() => this.http.get<ShopifySale>(`${this.url}/${saleId}`).toPromise());
  }

  async findSaleDelivery(token: string): Promise<any> {
    return this.request(() => this.http.get<ShopifySale>(`${this.url}/sale/${token}`).toPromise());
  }

  async create(sale: ShopifySale): Promise<ShopifySale>{
    return this.http.post<ShopifySale>(`${this.url}`, sale).toPromise();
  }

  async update(id: number, sale: ShopifySale): Promise<ShopifySale>{
    return this.http.put<ShopifySale>(`${this.url}/${id}`, sale).toPromise();
  }

  async acceptSale(token: string, deliveryId: number, delivery: Delivery): Promise<any> {
    return this.request(() => this.http.put(`${this.url}/accept/sale/${token}/${deliveryId}`, delivery).toPromise());
  }

  async refuseSale(token: string, deliveryId: number): Promise<any> {
    return this.request(() => this.http.delete(`${this.url}/refuse/sale/${token}/${deliveryId}`).toPromise());
  }

  async manageSaleForUniqueProduct(deliveryId: number, status: string): Promise<any> {
    return this.request(() => this.http.put(`${this.url}/manage/sale/${deliveryId}`, {status}).toPromise());
  }

  async stopSaleAutomation(id: number, sale: any): Promise<any>{
    return this.request(() => this.http.put(`${this.url}/stop-automation/${id}`, sale).toPromise());
  }

}
