import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
export class ProductCategory {
    static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            categoryName: new FormControl('', Validators.compose([
                Validators.required,
                ProductCategory.singleSpaceValidator
            ])),
            notes: new FormControl(''),
        };
        return validator;
    }

    static singleSpaceValidator(control: FormControl) {
        const value = control.value;
        const isValid = /^\S+(\s\S+)*$/.test(value);
        return isValid ? null : { singleSpace: true };
    }

    static readonly validationMessages = {
        'categoryName': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'singleSpace', message: 'Solo uno spazio è consentito tra le parole' }
        ],
    };

    constructor(
        public id?: number,
        public categoryName?: string,
        public notes?: string,
    ) { }
}