import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStatusService } from '../../services/auth-status.service';
import { AdminSmsTemplatesService } from '../../services/admin-sms-templates.service';
import { AdminSmsTemplate } from '../../models/admin-sms-template.schema';
import { PaginationComponent } from '../_pagination/pagination.component';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-admin-sms-templates',
  templateUrl: './admin-sms-templates.component.html',
  styleUrls: ['./admin-sms-templates.component.scss']
})
export class AdminSmsTemplatesComponent implements OnInit {

  public adminSmsTemplates: AdminSmsTemplate[];
  public _smsTemplateForm: any;
  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public filtered: {};
  public groups: string[];
  public order: {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private adminSmsTemplatesService: AdminSmsTemplatesService,
    private _snackBar: MatSnackBar,
  ) { 
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  ngOnInit(): void {
    this._smsTemplateForm = new FormGroup(AdminSmsTemplate.validation());
    this._smsTemplateForm = new FormGroup({
      'SmsTemplate.id': new FormControl(''),
      'SmsTemplate.temp_type': new FormControl(''), 
      'Partner.businessName': new FormControl(''),  
      'SmsTemplate.description': new FormControl(''),
    });
    this.loadAdminSmsTemplates();
  }

  async initialize() {
      try {
        let adminSmsTemp = await this.adminSmsTemplatesService.getAllAdminSmsTemplates(undefined, undefined, { 'Partner.businessName': 'ASC' });
        this.adminSmsTemplates = adminSmsTemp.adminSmsTemplates;
        this.count = adminSmsTemp.count;
        this.maxPage = Math.ceil(this.count / this.limit);
      }
      catch { }
  }

  async loadAdminSmsTemplates(limit?: number, page?: number, order?: {}){

    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || 1;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || 200;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || { 'Partner.businessName': 'ASC' };

    let adminSmsTemp = await this.adminSmsTemplatesService.getAllAdminSmsTemplates(this.page, this.limit, this.order, this.filtered);
    this.adminSmsTemplates = adminSmsTemp.adminSmsTemplates;
    this.count = adminSmsTemp.count;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  async delete(smsTempId: number){
    if (!confirm("Sei sicuro di voler eliminare questo modello di sms?"))
      return;
    let deleteResult: { id: number | string, success: boolean } = await this.adminSmsTemplatesService.delete(smsTempId);
    let message = "L'eliminazione del modello SMS non è riuscita.";
    if (deleteResult.success){
      this.adminSmsTemplates = this.adminSmsTemplates.filter(smsTemp => smsTemp.id != deleteResult.id);
      message = "Annullamento riuscito del modello di sms.";
    }
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
    if (deleteResult.success)
      this.loadAdminSmsTemplates();
  }

  async filter(){
    let filterForm = (new FormGroupToObject()).transform(this._smsTemplateForm);
    this.filtered = {};

    if (filterForm['SmsTemplate.id'])
      this.filtered['SmsTemplate.id'] = filterForm['SmsTemplate.id'];    
    if (filterForm['SmsTemplate.temp_type'])
      this.filtered['SmsTemplate.temp_type'] = { operator: 'like', values: [`%${filterForm['SmsTemplate.temp_type']}%`] };
    if (filterForm['Partner.businessName'])
      this.filtered['Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Partner.businessName']}%`] };
    if (filterForm['SmsTemplate.description'])
      this.filtered['SmsTemplate.description'] = { operator: 'like', values: [`%${filterForm['SmsTemplate.description']}%`] };

    this.loadAdminSmsTemplates();
  }

  async resetFilter(){
    this._smsTemplateForm = new FormGroup({
      'SmsTemplate.id': new FormControl(''),
      'SmsTemplate.temp_type': new FormControl(''), 
      'Partner.businessName': new FormControl(''),  
      'SmsTemplate.description': new FormControl(''),
    });
    this.initialize();
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadAdminSmsTemplates();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }
}
