import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { User } from '../models/user.schema';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiAuthService {
    private readonly url = `${this.baseUrl}/users`;

    async getMyInformation(): Promise<User> {
        return this.request(() => this.http.get<User>(`${this.url}/me`).toPromise());
    }

    async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ users: User[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get<{ users: User[], count: number }>(`${this.url}`, { params: params }).toPromise());
    }

    async getOne(id: string): Promise<User> {
        return this.request(() => this.http.get<User>(`${this.url}/${id}`).toPromise());
    }

    async create(userParams: User): Promise<User> {
        let body = userParams;
        return this.request(() => this.http.post<User>(`${this.url}`, body).toPromise());
    }

    async update(id: number, userParams: User): Promise<User> {
        let body = userParams;
        return this.request(() => this.http.put<User>(`${this.url}/${id}`, body).toPromise());
    }

    async delete(id: number): Promise<{ id: number, success: boolean }> {
        return this.request(() => this.http.delete<{ id: number, success: boolean }>(`${this.url}/${id}`).toPromise());
    }

    async updateMe(userParams: User): Promise<User> {
        let body = userParams;
        return this.request(() => this.http.put<User>(`${this.url}`, body).toPromise());
    }

    async changeStatus(id: number, status: number): Promise<User> {
        return this.request(() => this.http.patch<User>(`${this.url}/${id}/status`, { status: status }).toPromise());
    }

    async changeGroup(id: number, groupId: number): Promise<User> {
        return this.request(() => this.http.patch<User>(`${this.url}/${id}/group`, { groupId: groupId }).toPromise());
    }
}
