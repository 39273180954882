import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ExpertServiceSchema } from "src/app/models/expert-service.schems";
import { FormGroupToObject } from "src/app/pipes/form-group-to-object.pipe";
import { ExpertServiceService } from "src/app/services/expert-service.service";

@Component({
  selector: "app-expert-service-form",
  templateUrl: "./expert-service-form.component.html",
  styleUrls: ["./expert-service-form.component.scss"],
})
export class ExpertServiceFormComponent implements OnInit {
  expertServiceForm: any;
  formValidationMsgs: any;
  expertServiceId: string|number = null;
  eService: ExpertServiceSchema
  constructor(
    private expertService: ExpertServiceService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.handleExpertServiceForm();
  }

  async ngOnInit() {
    this.expertServiceForm = new FormGroup(ExpertServiceSchema.validation());
    this.expertServiceId = this.route.snapshot.paramMap.get('id');
    if (this.expertServiceId) {
      try {
        this.eService = await this.expertService.getExpertService(Number(this.expertServiceId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.eService)
      this.patch();
    else
      this.eService = new ExpertServiceSchema();
  }

  handleExpertServiceForm() {
    this.expertServiceForm = new FormGroup(ExpertServiceSchema.validation());
    this.formValidationMsgs = ExpertServiceSchema.validationMessages;
  }

  patch(){
    ['serviceName', 'serviceType', 'notes'].forEach(param => {
      this.expertServiceForm.controls[param].setValue(this.eService[param]);
    });
  }

  async onSubmit() {
    if(this.expertServiceForm.valid){
      let expert_service: ExpertServiceSchema = new FormGroupToObject().transform(this.expertServiceForm);
      let promiseResult: any
      if (this.eService['id']) {
        promiseResult = this.expertService.update(this.eService['id'], expert_service);
      } else {
        promiseResult = this.expertService.create(expert_service);
      }
      let message: string = this.eService['id'] ? "Valet Servizio aggiornato" : "Valet Servizio creato";
      promiseResult.then(async (res: any) => {
        await this.showToastMessage(message);
        await this.router.navigate(['/valet/servizi']);
      }).catch(async (error: any) => {
        await this.showToastMessage(error.error.message);
      });
    }
  }

  async showToastMessage(message: string){
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }
}
