/* Old Code
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { } from 'google.maps';
@Component({
  selector: 'app-google-maps-search',
  templateUrl: './google-maps-search.component.html',
  styleUrls: ['./google-maps-search.component.scss']
})
export class GoogleMapsSearchComponent implements AfterViewInit {
  @Input() addressType: string;
  @Input() initialAddress: string = '';
  @Input() control: FormControl;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addressText') addressText: any;

  constructor() { }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        componentRestrictions: { country: ['it', 'sm', 'va', 'mc', 'ch', 'at', 'si'] },
        types: [this.addressType]  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  focusOut() {
    this.setAddress.emit({ name: this.addressText.nativeElement.value });
  }

  invokeEvent(place: google.maps.places.PlaceResult) {
    this.addressText.nativeElement.value = place.formatted_address;
    this.setAddress.emit(place);
  }

}
Old Code */

import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { } from 'google.maps';

@Component({
  selector: 'app-google-maps-search',
  templateUrl: './google-maps-search.component.html',
  styleUrls: ['./google-maps-search.component.scss']
})
export class GoogleMapsSearchComponent implements AfterViewInit {
  @Input() addressType: string;
  @Input() initialAddress: string = '';
  @Input() control: FormControl;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addressText') addressText: any;
  private autocomplete: google.maps.places.Autocomplete;

  constructor() { }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        componentRestrictions: { country: ['it', 'sm', 'va', 'mc', 'ch', 'at', 'si'] },
        types: [this.addressType]  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      if (place && place.geometry) {
        this.invokeEvent(place);
      } else {
        this.setInvalidAddress();
      }
    });
  }

  async focusOut() {
    this.control.markAsTouched();  // Mark the control as touched

    const addressValue = this.addressText.nativeElement.value;
    if (!addressValue) {
      this.control.setErrors({ required: true });
      return;
    }

    const place = this.autocomplete.getPlace();
    if (!place || !place.geometry) {
      try {
        const prediction = await this.getPlacePrediction(addressValue);
        if (prediction) {
          const placeDetails = await this.getPlaceDetails(prediction.place_id);
          if (placeDetails) {
            this.invokeEvent(placeDetails);
          } else {
            this.setInvalidAddress();
          }
        } else {
          this.setInvalidAddress();
        }
      } catch (error) {
        this.setInvalidAddress();
      }
    } else {
      this.setAddress.emit({ name: addressValue });
      if (!addressValue) {
        this.control.setErrors({ required: true });
      } else {
        this.control.setErrors(null);  // Clear any existing errors
      }
    }
  }

  private getPlacePrediction(input: string): Promise<google.maps.places.AutocompletePrediction | null> {
    return new Promise((resolve, reject) => {
      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input, componentRestrictions: { country: ['it', 'sm', 'va', 'mc', 'ch', 'at', 'si'] }, types: [this.addressType] },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
            resolve(predictions[0]);
          } else {
            resolve(null);
          }
        });
    });
  }

  private getPlaceDetails(placeId: string): Promise<google.maps.places.PlaceResult | null> {
    return new Promise((resolve, reject) => {
      const placesService = new google.maps.places.PlacesService(this.addressText.nativeElement);
      placesService.getDetails({ placeId }, (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        } else {
          resolve(null);
        }
      });
    });
  }

  setInvalidAddress() {
    this.control.setErrors({ invalidAddress: true });
    this.setAddress.emit(null);  // Emit null to indicate an invalid address
  }

  invokeEvent(place: google.maps.places.PlaceResult) {
    this.addressText.nativeElement.value = place.formatted_address;
    this.setAddress.emit(place);
    this.control.setErrors(null);  // Clear any existing errors
  }
}
