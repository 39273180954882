import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { Delivery, DeliveryStatus, DeliveryStatusAux } from '../models/delivery.schema';
import { ToTypeOrm } from '../models/_to-type-orm.schema';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DeliveryService extends ApiAuthService {
    private readonly url = `${this.baseUrl}/deliveries`;

    async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ deliveries: Delivery[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        //alert(JSON.stringify(filter));
        //alert(this.url);
        //return;
        return this.request(() => this.http.get<{ deliveries: Delivery[], count: number }>(`${this.url}`, { params: params }).toPromise());
    }

    async getAllXlsx(order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<HttpResponse<Blob>> {
        let params = {};
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() =>
            this.http
                .get(`${this.url}/xlsx`, {
                    params: params,
                    observe: 'response',
                    responseType: 'blob',
                })
                .toPromise()
        );
    }

    async getAllActive(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<{ deliveries: Delivery[], count: number }> {
        if (!filter['status'])
            filter['status'] = { operator: 'in', values: DeliveryStatusAux.active };
        return this.getAll(page, limit, order, filter);
    }

    async getAllInactive(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<{ deliveries: Delivery[], count: number }> {
        if (!filter['status'])
            filter['status'] = { operator: 'in', values: DeliveryStatusAux.inactive };
        return this.getAll(page, limit, order, filter);
    }

    async getAllActiveXlsx(order?: {}, filter: any = {}): Promise<HttpResponse<Blob>> {
        if (!filter['status'])
            filter['status'] = { operator: 'in', values: DeliveryStatusAux.active };
        return this.getAllXlsx(order, filter);
    }

    async getAllInactiveXlsx(order?: {}, filter: any = {}): Promise<HttpResponse<Blob>> {
        if (!filter['status'])
            filter['status'] = { operator: 'in', values: DeliveryStatusAux.inactive };
        return this.getAllXlsx(order, filter);
    }

    async getOne(id: string | number): Promise<Delivery> {
        return this.request(() => this.http.get<Delivery>(`${this.url}/${id}`).toPromise());
    }

    async create(deliveryParams: Delivery, sendSmsConfirmation?: boolean): Promise<Delivery> {
        let body = deliveryParams;
        return this.request(() => this.http.post<Delivery>(`${this.url}/${sendSmsConfirmation}`, body).toPromise());
    }

    async update(id: number, deliveryParams: Delivery): Promise<Delivery> {
        let body = deliveryParams;
        return this.request(() => this.http.put<Delivery>(`${this.url}/${id}`, body).toPromise());
    }

    async partialUpdate(id: number, deliveryParams: Delivery): Promise<Delivery> {
        let body = deliveryParams;
        return this.request(() => this.http.put<Delivery>(`${this.url}/partial/${id}`, body).toPromise());
    }

    async generateIdentifier(id: number): Promise<Delivery> {
        return this.request(() => this.http.put<Delivery>(`${this.url}/${id}/identifier`, null).toPromise());
    }

    async delete(id: number): Promise<{ id: number, success: boolean }> {
        return this.request(() => this.http.delete<{ id: number, success: boolean }>(`${this.url}/${id}`).toPromise());
    }

    async setDeliveryDelivered(id: number, sendSmsConfirmation?: boolean): Promise<Delivery> {
        return this.request(() => this.http.post<Delivery>(`${this.url}/${id}/${sendSmsConfirmation}/delivered`, undefined).toPromise());
    }

    async setDeliveryNotDelivered(id: number, body?: {}): Promise<Delivery> {
        return this.request(() => this.http.post<Delivery>(`${this.url}/${id}/not-delivered`, body).toPromise());
    }

    async setDeliveryDelivering(id: number, sendSmsConfirmation?: boolean): Promise<Delivery> {
        return this.request(() => this.http.post<Delivery>(`${this.url}/${id}/${sendSmsConfirmation}/delivering`, undefined).toPromise());
    }

    async setDeliveryAccepting(id: number): Promise<Delivery> {
        //alert(`${this.url}/${id}/accepting`);
        return this.request(() => this.http.post<Delivery>(`${this.url}/${id}/accepting`, undefined).toPromise());
    }

    async setDeliveryRefusing(id: number): Promise<Delivery> {
        return this.request(() => this.http.post<Delivery>(`${this.url}/${id}/refusing`, undefined).toPromise());
    }



    findOneDeliveryDdt(id: number): Promise<HttpResponse<Blob>> {
        return this.request(() =>
            this.http
                .get(`${this.url}/ddt/${id}`, {
                    observe: 'response',
                    responseType: 'blob',
                })
                .toPromise()
        );
    }

    updateDeliveryDdt(id: number, file: any) {
        const formData = new FormData();
        formData.append('ddt', file);
        // console.log('before put', file);
        return this.request(() =>
            this.http.put<Delivery>(`${this.url}/ddt/${id}`, formData).toPromise()
        );
        console.log('after putting ',file);
    }

    // import_product_via_csv(deliveries: any): Promise<Delivery>{
    //     return this.request(() =>
    //       this.http.post<Delivery>(`${this.url}/true/import`, deliveries).toPromise()
    //     );
    // }
    
    
    import_product_via_csv(delivery: Delivery): Promise<Delivery>{
        let data = [];
        data.push(delivery);
        return this.request(() =>
          this.http.post<Delivery>(`${this.url}/true/import`, data).toPromise()
        );
    }
    

    async setDeliveryRequestCancellation(id: number): Promise<Delivery> {
        return this.request(() => this.http.post<Delivery>(`${this.url}/${id}/requestCancellation`, undefined).toPromise());
    }

    async createDeliveryViaShopifyOrder(deliveryParams: Delivery): Promise<Delivery>{
        let body = deliveryParams;
        return this.request(() => this.http.post<Delivery>(`${this.url}/createDelivery/ViaShpoifyOrder`, body).toPromise());
    }

    async acceptSale(token: string, deliveryId: number, delivery: Delivery): Promise<any> {
        return this.request(() => this.http.put(`${this.url}/accept/sale/${token}/${deliveryId}`, delivery).toPromise());
    }

    async refuseSale(token: string, deliveryId: number): Promise<any> {
        return this.request(() => this.http.delete(`${this.url}/refuse/sale/${token}/${deliveryId}`).toPromise());
    }

    async manageSaleForUniqueProduct(deliveryId: number, status: string): Promise<any> {
        return this.request(() => this.http.put(`${this.url}/manage/sale/${deliveryId}`, {status}).toPromise());
    }

    async findDelivery(id: number): Promise<Delivery>{
        return this.request(() => this.http.get<Delivery>(`${this.url}/find/delivery/${id}`).toPromise());
    }

    async getDeliveryWithOrderId(id: number, realOrderNumber: number): Promise<Delivery>{
        return this.request(() => this.http.get<Delivery>(`${this.url}/find/delivery/orderId/${id}/${realOrderNumber}`).toPromise());
    }

    async exportInvoiceInfo(deliveries: any): Promise<HttpResponse<Blob>> {
        let deliveryData = {
            data: JSON.stringify(deliveries)
        };
        return this.request(() =>
            this.http.post(`${this.url}/invoice/xlsx`, deliveryData, { observe: 'response', responseType: 'blob' }).toPromise()
        );
    }

    async exportSalaryInfo(deliveries: any): Promise<HttpResponse<Blob>> {
        let deliveryData = {
            data: JSON.stringify(deliveries)
        };

        return this.request(() =>
            this.http.post(`${this.url}/salary/xlsx`, deliveryData, { observe: 'response', responseType: 'blob' }).toPromise()
        );
    }

    async saveReceiptInfo(id: number, data: any){
        const formData = new FormData();
        formData.append('image', data.image);
        formData.append('receiverName', data.receiverName);
        formData.append('receiverSurname', data.receiverSurname);
        formData.append('receiverType', data.receiverType);
        return this.request(() =>
            this.http.put(`${this.url}/${id}/receipt`, formData).toPromise()
        );
    }
}
