import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { ToTypeOrm } from '../models/_to-type-orm.schema';
import { ExpertServiceSchema } from '../models/expert-service.schems';

@Injectable({
  providedIn: 'root'
})
export class ExpertServiceService extends ApiAuthService {

  private readonly url = `${this.baseUrl}/expert-service`;

  async getAllServicess(page?: number, limit?: number, order?: {}, filter: any = {}): Promise<any> {
      return this.getAll(page, limit, order, filter);
  }
  
  async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<any> {
      let params = {};
      if (page) params['page'] = page;
      if (limit) params['limit'] = limit;
      if (order) params['order'] = JSON.stringify(order);
      if (filter) params['filter'] = JSON.stringify(filter);
      return this.request(() => this.http.get<any>(`${this.url}`, { params: params }).toPromise());
  }

  async create(expertParams: ExpertServiceSchema): Promise<ExpertServiceSchema> {
    let body = expertParams;
    return this.request(() => this.http.post<ExpertServiceSchema>(`${this.url}`, body).toPromise());
  }

  async getExpertService(expertServiceId: number): Promise<ExpertServiceSchema>{
    return this.request(() => this.http.get(`${this.url}/${expertServiceId}` ).toPromise() );
  }

  async update(expertServiceId: number, formValues: any): Promise<ExpertServiceSchema>{
    return this.request(() => this.http.put(`${this.url}/${expertServiceId}`, formValues ).toPromise() );
  }

  async deleteExpertService(expertServiceId: number): Promise<any>{
    return this.request(() => this.http.delete(`${this.url}/${expertServiceId}` ).toPromise() );
  }

}
