import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { Expert } from "./expert.schema";

export class ExpertDeliveryRulesSchema {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            startTimeRange: new FormControl('', Validators.required),
            endTimeRange: new FormControl('', Validators.required),
            expert: new FormGroup({ id: new FormControl('', Validators.required) }),
            kmLimit: new FormControl('', [Validators.required,  Validators.pattern('^[0-9]*$')]),
            perDayDeliveryLimit: new FormControl('', [Validators.pattern('^[0-9]*$')]),
            totalDeliveryLimit: new FormControl('', [Validators.pattern('^[0-9]*$')]),
            serviceType: new FormControl('', Validators.required),
            payable: new FormControl(false),
            valetAdditionalPrice: new FormControl('', [Validators.pattern('^-?[0-9]*$')]),
            billable: new FormControl(false),
            perDayDeliveryRule: new FormControl(true),
            totalDeliveryRule: new FormControl(false),
            additionalPrice: new FormControl('', [Validators.pattern('^-?[0-9]*$')]),
            validFrom: new FormControl('', [Validators.required]),
            validTill: new FormControl('', [Validators.required])
        }

        return validator;
    }

    public static readonly validationMessages = {
        'startTimeRange': [{ type: 'required', message: 'Obbligatorio' }],
        'endTimeRange': [{ type: 'required', message: 'Obbligatorio' }],
        'validFrom': [{ type: 'required', message: 'Obbligatorio' }],
        'validTill': [{ type: 'required', message: 'Obbligatorio' }],
        'expert': {
            'id': [{ type: 'required', message: 'Obbligatorio' }]
        },
        'kmLimit': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'pattern', message: 'Unico numero consentito' }
        ],
        'perDayDeliveryLimit': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'pattern', message: 'Unico numero consentito' }
        ],
        'totalDeliveryLimit': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'pattern', message: 'Unico numero consentito' }
        ],
        'serviceType': [{ type: 'required', message: 'Obbligatorio' }],
        'additionalPrice': [
            { type: 'pattern', message: 'Unico numero consentito' }
        ],
        'valetAdditionalPrice': [
            { type: 'pattern', message: 'Unico numero consentito' }
        ],
    }

    constructor(
        public id?: number,
        public expert?: Expert,
        public startTimeRange?: string,
        public endTimeRange?: string,
        public kmLimit?: number,
        public perDayDeliveryLimit?: number,
        public totalDeliveryLimit?: number,
        public serviceType?: string,
        public payable?: boolean,
        public valetAdditionalPrice?: string,
        public billable?: boolean,
        public additionalPrice?: string,
        public validFrom?: Date,
        public validTill?: Date,
        public days?: any,
        public totalDeliveryRule?: boolean,
        public perDayDeliveryRule?: boolean,
    ){}

}