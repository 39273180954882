import { Component, OnInit, ViewChild } from '@angular/core';
import { ExpertFormComponent } from '../expert-form/expert-form.component';
import { Router } from '@angular/router';
import { ExpertService } from '../../services/expert.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthStatusService } from 'src/app/services/auth-status.service';

@Component({
  selector: 'app-expert-form-decorator',
  templateUrl: './expert-form-decorator.component.html',
  styleUrls: ['./expert-form-decorator.component.scss']
})
export class ExpertFormDecoratorComponent implements OnInit {
  @ViewChild(ExpertFormComponent, { static: false }) form: ExpertFormComponent;
  public groups: string[];

  constructor(
    private router: Router,
    private expertService: ExpertService,
    private _snackBar: MatSnackBar,
    private authStatusService: AuthStatusService,
  ) { 
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  ngOnInit() {
  }

  async delete() {
    if (!confirm("Sei sicuro di voler cancellare questo expert?"))
      return;
    let expert: { id: number | string, success: boolean } = await this.expertService.delete(this.form.expert.id);
    let message = "Cancellazione dell'expert fallita.";
    if (expert.success)
      message = "Cancellazione dell'expert avvenuta con successo.";
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    }).afterDismissed().subscribe(result => {
      if (expert.success)
        this.router.navigate(['/expert']);
    });
  }

}
