import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Delivery } from 'src/app/models/delivery.schema';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ShowImageComponent } from '../show-image/show-image.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sale-delivery-detail',
  templateUrl: './sale-delivery-detail.component.html',
  styleUrls: ['./sale-delivery-detail.component.scss']
})
export class SaleDeliveryDetailComponent implements OnInit {

  public groups: string[];
  delivery: Delivery;
  totalProductPrice: number = 0;

  constructor(
    private authStatusService: AuthStatusService,
    private route: ActivatedRoute,
    private deliveryService: DeliveryService,
    public dialog: MatDialog,
    private router: Router
  ) { 
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    try {
      this.delivery = await this.deliveryService.getOne(this.route.snapshot.paramMap.get('id'));
      if(this.delivery?.deliveryProducts){
        this.delivery?.deliveryProducts.forEach((product) => {
          this.totalProductPrice += Number(product?.product?.price)
        })
      }
    } catch (error) {
      this.router.navigateByUrl("/404", { skipLocationChange: true });
    }
  }

  showImage(product: any){
    let ref = this.dialog.open(ShowImageComponent, { panelClass: 'partner-time-dialog', width: '50vw', data: { product } });
    ref.afterClosed().subscribe(result => {});
  }
  
  openImage(imageUrl: string) {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }
  }

}
