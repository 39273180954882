import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { CakeSaleService } from 'src/app/services/cake-sale.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { CakeSaleFormDialogComponent } from '../cake-sale-form-dialog/cake-sale-form-dialog.component';
import { AiCakeOrderInfoModalComponent } from '../ai-cake-order-info-modal/ai-cake-order-info-modal.component';
import { CakeOrderService } from 'src/app/services/cake-order.service';

@Component({
  selector: 'app-cake-sale',
  templateUrl: './cake-sale.component.html',
  styleUrls: ['./cake-sale.component.scss']
})
export class CakeSaleComponent implements OnInit {

  limit: number;
  page: number;
  count: number;
  maxPage: number;
  filtered: {};
  orders: string[];
  cakesSaleForm: any;
  order: {};
  myjson:any=JSON;
  groups: any;
  selected: string = 'active';
  settings = {
    active: {
      title: "Cakes Vendita",
    },
    inactive: {
      title: "Storico Cakes Vendita",
    }
  };
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'CakeSales.actualOrderId': 'DESC' };
  btnDisable: boolean = false;
  cakeOrders: any[] = [];
  
  constructor(
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private deliveryService: DeliveryService,
    private cakeSaleService: CakeSaleService,
    private cakeOrderService: CakeOrderService,
  ) { 
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    // this.manageCakeSaleFilterForm();
  }

  async ngOnInit(){
    try {
      this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
      this.cakeOrders = await this.cakeOrderService.getAll();
      this.manageCakeSaleFilterForm();
      await this.filter();
    } catch (error) {
      console.log("ERROR", error?.message);
    }
  }

  async manageCakeSaleFilterForm(){
    this.cakesSaleForm = new FormGroup({
      'CakeSales.orderId': new FormControl(''),
      'CakeSales.actualOrderId': new FormControl(''),
      'CakeSales.firstName': new FormControl(''), 
      'CakeSales.deliveryDate_from': new FormControl(''), 
      'CakeSales.deliveryDate_to': new FormControl(''),   
      'CakeSales.lastName': new FormControl(''),  
      'CakeSales.channel': new FormControl(''),
      'CakeSales.total': new FormControl(''),
      'CakeSales.paymentStatus': new FormControl(''),
      'CakeSales.fullFillmentStatus': new FormControl(''),
      'CakeSales.deliveryMethod': new FormControl(''),
      'CakeSales.address': new FormControl(''),
      'CakeSales.pickUpTime': new FormControl(''),
      'CakeSales.phone': new FormControl(''),
      'CakeSales.deliveryDate': new FormControl(''),
      'CakeSales.vendor': new FormControl(''),
      'CakeSales.status': new FormControl(''),
    });
  }

  async filter() {

    let filterForm = (new FormGroupToObject()).transform(this.cakesSaleForm);
    this.filtered = {};
    if (filterForm['CakeSales.orderId'])
      this.filtered['CakeSales.orderId'] = filterForm['CakeSales.orderId'];    
    if (filterForm['CakeSales.actualOrderId'])
      this.filtered['CakeSales.actualOrderId'] = filterForm['CakeSales.actualOrderId'];    
    if (filterForm['CakeSales.firstName'])
      this.filtered['CakeSales.firstName'] = { operator: 'like', values: [`%${filterForm['CakeSales.firstName']}%`] };
    if (filterForm['CakeSales.channel'])
      this.filtered['CakeSales.channel'] = { operator: 'like', values: [`%${filterForm['CakeSales.channel']}%`] };
    if (filterForm['CakeSales.total'])
      this.filtered['CakeSales.total'] = { operator: 'like', values: [`%${filterForm['CakeSales.total']}%`] };
    if (filterForm['CakeSales.paymentStatus'])
      this.filtered['CakeSales.paymentStatus'] = { operator: 'like', values: [`%${filterForm['CakeSales.paymentStatus']}%`] };
    if (filterForm['CakeSales.fullFillmentStatus'])
      this.filtered['CakeSales.fullFillmentStatus'] = { operator: 'like', values: [`%${filterForm['CakeSales.fullFillmentStatus']}%`] };
    if (filterForm['CakeSales.deliveryMethod'])
      this.filtered['CakeSales.deliveryMethod'] = { operator: 'like', values: [`%${filterForm['CakeSales.deliveryMethod']}%`] };
    if (filterForm['CakeSales.address'])
      this.filtered['CakeSales.address'] = { operator: 'like', values: [`%${filterForm['CakeSales.address']}%`] };
    if (filterForm['CakeSales.pickUpTime'])
      this.filtered['CakeSales.pickUpTime'] = { operator: 'like', values: [`%${filterForm['CakeSales.pickUpTime']}%`] };
    if (filterForm['CakeSales.phone'])
      this.filtered['CakeSales.phone'] = { operator: 'like', values: [`%${filterForm['CakeSales.phone']}%`] };
    if (filterForm['CakeSales.deliveryDate_from'])
      this.filtered['CakeSales.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['CakeSales.deliveryDate_from']] };
    if (filterForm['CakeSales.deliveryDate_to'])
      this.filtered['CakeSales.deliveryDate'] = { operator: 'lessThan', values: [filterForm['CakeSales.deliveryDate_to']] };
    if (filterForm['CakeSales.deliveryDate_from'] && filterForm['CakeSales.deliveryDate_to'])
      this.filtered['CakeSales.deliveryDate'] = { operator: 'between', values: [filterForm['CakeSales.deliveryDate_from'], filterForm['CakeSales.deliveryDate_to']] };
    if (filterForm['CakeSales.vendor'])
      this.filtered['CakeSales.vendor'] = { operator: 'like', values: [`%${filterForm['CakeSales.vendor']}%`] };
    if (filterForm['CakeSales.status'])
      this.filtered['CakeSales.status'] = { operator: 'like', values: [`%${filterForm['CakeSales.status']}%`] };

    await this.getCakesOrders();
  }

  async getCakesOrders(limit?: number, page?: number, order?: {}){

    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
    let promiseResult = null;
    promiseResult = this.selected == 'active' ? 
      this.cakeSaleService.getCakeOrders(this.page, this.limit, this.order, this.filtered) :
      this.cakeSaleService.getCakeOrdersHistory(this.page, this.limit, this.order, this.filtered);
    promiseResult.then((res) => {
      this.orders = res.orders;
      this.count = res.count;
      this.maxPage = Math.ceil(this.count / this.limit);
    }).catch((err) => {
      console.log("err", err)
    })
  }

  async resetFilter() {
    await this.manageCakeSaleFilterForm();
    await this.filter();
  }

  async navigate(event) {
    await this.getCakesOrders(event.limit, event.page, this.order);
  }

  async confirmSale(saleOrder: any) {
    if(this.groups.includes('admin') || this.groups.includes('operation') || this.groups.includes('teamleader')){
      /*If Sale if unique then not confirm sale because partner is still accept or reject the sale*/
      let delivery = await this.deliveryService.getDeliveryWithOrderId(saleOrder?.orderId, saleOrder?.realOrderNumber);
      if(delivery){
        this._snackBar.open(`This is unique product sale. Delivery has been created. Partner is still accpet or reject the sale`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
        return false;
      }
      /*If Sale if unique then not confirm sale because partner is still accept or reject the sale*/
      let ref = this.dialog.open(CakeSaleFormDialogComponent, { width: '80vw', data: { saleOrder } });
      ref.afterClosed().subscribe(async (result) => {
        if (result?.delivery){
          await this.getCakesOrders();
        }
      });
    } else {
      return false;
    }
  }

  async refuseSale(orderId: number){
    if(!confirm(`Sei sicuro di voler rifiutare l'ordine di Shopify?`))
      return false;
    let promiseResult = await this.cakeSaleService.refuseSaleByAdmin(orderId);
    if(promiseResult.success){
      this._snackBar.open(`L'ordine di Shopify è stato rifiutato. Puoi controllare nella cronologia`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      await this.getCakesOrders();
    } else {
      this._snackBar.open(`Qualcosa è andato storto`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }
  }

  async stopSaleAutomation(sale: any){
    if(!confirm(`Are You Sure to Stop Sale Automation ${sale?.platform?.toLowerCase()}?`))
      return false;
    try {
      this.btnDisable = true;
      let id: number = sale?.id;
      let promiseResult = await this.cakeSaleService.stopSaleAutomation(id, sale);

      if(promiseResult.success){
        this._snackBar.open(`Sale Automation has been Stopped.`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
      } else {
        this._snackBar.open(`Something Went Wrong`, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        });
      }
      await this.getCakesOrders();
    } catch (error) {
      this.btnDisable = false;
      this._snackBar.open(`Something Went Wrong ${error?.error?.message}`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }
  }

  async showAiInfo(order: any){
    const lineItems = JSON.parse(order?.lineItems || '[]');
    lineItems.some((item: any) =>
      item.properties?.some((property: any) =>
        property.name.toLowerCase() === 'ai_cake_image_url' && property.value
          ? this.dialog.open(AiCakeOrderInfoModalComponent, { width: '80vw', data: { order } })
          : false
      )
    );
  }

  
  showBtnAiBtn(order: any): boolean {
    return this.cakeOrders.some((cakeOrder) => {
      return cakeOrder.realOrderNumber === order?.realOrderNumber && cakeOrder.orderId === order?.orderId;
    });
  }

  public async navigateOrder(attribute: string | string[]) {
    delete this.order['CakeSales.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    await this.navigatePage(this.limit, this.page, { order: this.order });
  }

  async navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(async (data) => {
      await this.getCakesOrders();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

}
