import { AbstractControl, FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { User } from './user.schema';
import { Delivery } from './delivery.schema';
import { Vehicle } from './vehicle.schema';
import { phoneValid } from '../helpers/validators/phone.validator';
import { ExpertServiceSchema } from './expert-service.schems';

export class Expert {
    public static validation(vehicles: Vehicle[], profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            phone: new FormControl('', Validators.compose([Validators.required, phoneValid()])),
            address: new FormControl('', Validators.required),
            longitude: new FormControl(''),
            latitude: new FormControl(''),
            notes: new FormControl(''),
            vehicles: new FormArray(vehicles.map(x => new FormControl(false))),
            minimumKmIncluded: new FormControl(null),
            expertServices: new FormArray([new FormGroup(ExpertServices.validation())]),
            expertProvinces: new FormArray([new FormGroup(ExpertProvinces.validation())]),
            // teamLeaderProvince: new FormGroup({ id: new FormControl('') }),
            expertTeamLeaderProvinces: new FormArray([new FormGroup(ExpertTeamLeaderProvinces.validation())]),
            isTeamLeader: new FormControl(''),
        };
        validator['user'] = profile ?
            new FormGroup({ ...User.specificValidation(), ...User.editLoginValidation() }) :
            new FormGroup(User.specificValidation());
        return validator;
    }

    public static readonly validationMessages = {
        'phone': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }],
        'address': [{ type: 'required', message: 'Obbligatorio' }],
        'user': User.validationMessages
    };

    public static serviceFormGroup() {
        return new FormGroup(ExpertServices.validation());
    }

    
    public static provinceFormGroup() {
        return new FormGroup(ExpertProvinces.validation());
    }

    public static teamLeaderProvinceFormGroup() {
        return new FormGroup(ExpertTeamLeaderProvinces.validation());
    }

    constructor(
        public id?: number,
        public phone?: string,
        public address?: string,
        public longitude?: string,
        public latitude?: string,
        public notes?: string,
        public vehicles?: Vehicle[],
        public user?: User,
        public deliveries?: Delivery[],
        public minimumKmIncluded?: number,
        public isTeamLeader?: boolean,
        public expertTeamLeaderProvinces?: ExpertTeamLeaderProvinces[],
        public expertServices?: ExpertServices[],
        public expertProvinces?: ExpertProvinces[],
    ) { }
}

export class ExpertServices {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            service: this.serviceFormGroup(),
        };
        return validator;
    }

    public static serviceFormGroup() {
        return new FormGroup({
            id: new FormControl(null),
            salary: new FormControl(null),
            minimumKmPrice: new FormControl(null),
        });
    }

    constructor(
        public id?: number,
        public salary?: number,
        public minimumKmPrice?: string,
        public service?: ExpertServiceSchema,
        public updatedAt?: Date,
        public createdAt?: Date,
    ) { }
}

export class ExpertProvinces {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            province: this.provinceFormGroup(),
        };
        return validator;
    }

    public static provinceFormGroup() {
        return new FormGroup({
            id: new FormControl(null),
        });
    }

    constructor(
        public id?: number,
        public provinceCode?: string,
        public province?: any,
        public updatedAt?: Date,
        public createdAt?: Date,
    ) { }
}
export class ExpertTeamLeaderProvinces {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            province: this.provinceFormGroup(),
        };
        return validator;
    }

    public static provinceFormGroup() {
        return new FormGroup({
            id: new FormControl(null),
        });
    }

    constructor(
        public id?: number,
        public provinceCode?: string,
        public province?: any,
        public updatedAt?: Date,
        public createdAt?: Date,
    ) { }
}