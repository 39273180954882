import { AbstractControl, FormControl, Validators } from "@angular/forms";

export class ExpertPriorityListSchema {
  public static validation(profile = false): {
    [key: string]: AbstractControl;
  } {
    let validator: { [key: string]: AbstractControl } = {
      partner: new FormControl("", Validators.required),
      province: new FormControl("", Validators.required),
      expertPriorities: new FormControl(""),
    };
    return validator;
  }

  public static readonly validationMessages = {
    partner: [{ type: "required", message: "Obbligatorio" }],
    province: [{ type: "required", message: "Obbligatorio" }],
    expert: [{ type: "required", message: "Obbligatorio" }],
  };

  constructor(
    public id?: number,
    public partner?: any,
    public province?: any,
    public expertPriorities?: any
  ) {}
}
