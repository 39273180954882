import { ShopifySaleService } from 'src/app/services/shopify-sale.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Delivery } from 'src/app/models/delivery.schema';
import { ShowImageComponent } from '../show-image/show-image.component';
import { MatDialog } from '@angular/material/dialog';
import { Service } from 'src/app/services/service.service';
import { ServiceModel } from 'src/app/models/service.schems';
import { AuthService } from './../../services/auth.service';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { CakeSaleService } from 'src/app/services/cake-sale.service';
import { BusinessSaleService } from 'src/app/services/business-sale.service';
import { FlowersSaleService } from 'src/app/services/flowers-sale.service';

@Component({
  selector: 'app-confirm-sale',
  templateUrl: './confirm-sale.component.html',
  styleUrls: ['./confirm-sale.component.scss']
})
export class ConfirmSaleComponent implements OnInit {

  decodedInfo: any;
  isTokenExpire: boolean = false;
  delivery: Delivery;
  disableProposeSaleBtn: boolean = false;
  totalProductPrice: number = 0;
  token: string;
  disablebtn: boolean = false;
  deliveryId: number;
  public services :ServiceModel[] = [];
  public serviceDetails:any;

  constructor(
    private route: ActivatedRoute,
    private jwtHelper: JwtHelperService,
    private deliveryService: DeliveryService,
    private shopifySaleService: ShopifySaleService,
    private cakeSaleService: CakeSaleService,
    private businessSaleService: BusinessSaleService,
    private flowersSaleService: FlowersSaleService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private service: Service,
    private router: Router,
    private authService: AuthService,
    private authStatusService: AuthStatusService,
  ) { }

  async ngOnInit() {
    await this.initialize();
  } 

  async initialize(){
    try {
      const token: string = this.route.snapshot.paramMap.get('token');
      this.token = token;
      this.isTokenExpire = this.jwtHelper.isTokenExpired(token);
      if(this.isTokenExpire){
        this.showSnackBar('Sale has been expired');
        this.disableProposeSaleBtn = true;
        return false;
      }
      this.decodedInfo = this.jwtHelper.decodeToken(token);
      this.deliveryId = this.decodedInfo?.deliveryId;
      let data: any;
      if(this.decodedInfo?.saleType){
        if(this.decodedInfo?.saleType?.toLowerCase() === 'shopifysale'){
          data = await this.shopifySaleService.findSaleDelivery(token);
        }
        if(this.decodedInfo?.saleType?.toLowerCase() === 'cakesales'){
          data = await this.cakeSaleService.findSaleDelivery(token);
        }
        if(this.decodedInfo?.saleType?.toLowerCase() === 'flowerssales'){
          data = await this.flowersSaleService.findSaleDelivery(token);
        }
        if(this.decodedInfo?.saleType?.toLowerCase() === 'businesssales'){
          data = await this.businessSaleService.findSaleDelivery(token);
        }
      }
      // data = await this.shopifySaleService.findSaleDelivery(token);
      if(data === null){
        this.showSnackBar('Sale has been alreday accepted or refused');
        return false;
      }
      this.delivery = JSON.parse(data?.deliveryInfo);
      this.getTotalOfProduct();
    } catch (error) {
      console.log("error", error)
    }
  }

  async getTotalOfProduct(){
    if(this.delivery?.deliveryProducts){
      this.delivery?.deliveryProducts.forEach((product) => {
        this.totalProductPrice += Number(product?.product?.price)
      })
    }
  }

  async acceptSale(){
    try {
      if(this.isTokenExpire){
        this.showSnackBar('Sale has been expired');
        return;
      }
      if(!this.disableProposeSaleBtn){
        if (!confirm("Confermi di accettare la vendita?"))
          return;
        this.disablebtn = true;
        let result: any;
        if(this.decodedInfo?.saleType){
          if(this.decodedInfo?.saleType?.toLowerCase() === 'shopifysale'){
            result = await this.shopifySaleService.acceptSale(this.token, this.deliveryId, this.delivery);
          }
          if(this.decodedInfo?.saleType?.toLowerCase() === 'cakesales'){
            result = await this.cakeSaleService.acceptSale(this.token, this.deliveryId, this.delivery);
          }
          if(this.decodedInfo?.saleType?.toLowerCase() === 'flowerssales'){
            result = await this.flowersSaleService.acceptSale(this.token, this.deliveryId, this.delivery);
          }
          if(this.decodedInfo?.saleType?.toLowerCase() === 'businesssales'){
            result = await this.businessSaleService.acceptSale(this.token, this.deliveryId, this.delivery);
          }
        }
        // result = this.deliveryService.acceptSale(this.token, this.deliveryId, this.delivery);
        if(result){
          this.delivery = null;
          await this.initialize();
          this._snackBar.open('Sale has been accepted', 'Chiudi', {
            direction: "ltr",
            duration: 2000,
            horizontalPosition: "center",
            politeness: "assertive",
            verticalPosition: "top"
          }).afterDismissed().subscribe(async (res) => {
            if(result?.id){
              const isLogin: boolean = await this.authStatusService.isAuthenticated();
              if(!isLogin){
                let loginInfo: any = await this.authService.forceLogin(result?.partner?.user?.email);
                if(loginInfo){
                  this.authStatusService.setRefreshStorage(loginInfo.refreshToken);
                  this.authStatusService.setAccessStorage(loginInfo.accessToken);
                  this.router.navigate(['/consegne', result?.id]).then(() => {
                    window.location.reload();
                  });
                }
              }else {
                this.router.navigate(['/consegne', result?.id]).then(() => {
                  window.location.reload();
                });
              }
            }
          })
          this.disablebtn = false;
        } else {
          this.disablebtn = false;
          this.showSnackBar('Something went wrong');
          // if(error.error.message === 'ALREDAY_ACCEPTED'){
          //   this.showSnackBar('Sale has been already accepted');
          // } else if(error.error.message === 'SALE_SERVICE_NOT_AVAILABLE') {
          //   this.showSnackBar('Partner has not sale type service. Can not accept sale');
          // } else {}
        }
      }
    } catch (error) {
      this.disablebtn = false;
      if(error.error.message === 'ALREDAY_ACCEPTED'){
        this.showSnackBar('Sale has been already accepted');
      } else if(error.error.message === 'SALE_SERVICE_NOT_AVAILABLE') {
        this.showSnackBar('Partner has not sale type service. Can not accept sale');
      } else {}
    }
  }

  async refuseSale(){
    try {
      if(this.isTokenExpire){
        this.showSnackBar('Sale has been expired');
        return;
      }
      if(!this.disableProposeSaleBtn){
        if (!confirm("Confermi di rifiutare la vendita?"))
          return;
        this.disablebtn = true;
        let result: any;
        if(this.decodedInfo?.saleType){
          if(this.decodedInfo?.saleType?.toLowerCase() === 'shopifysale'){
            result = await this.shopifySaleService.refuseSale(this.token, this.deliveryId);
          }
          if(this.decodedInfo?.saleType?.toLowerCase() === 'cakesales'){
            result = await this.cakeSaleService.refuseSale(this.token, this.deliveryId);
          }
          if(this.decodedInfo?.saleType?.toLowerCase() === 'flowerssales'){
            result = await this.flowersSaleService.refuseSale(this.token, this.deliveryId);
          }
          if(this.decodedInfo?.saleType?.toLowerCase() === 'businesssales'){
            result = await this.businessSaleService.refuseSale(this.token, this.deliveryId);
          }
        }
        // let promise = this.deliveryService.refuseSale(this.token, this.deliveryId);
        if(result){
          // console.log("result", result);
          this.delivery = null;
          await this.initialize();
          this.showSnackBar('Sale has been refused');
          this.disablebtn = false;
        }
      }
    } catch (error) {
      this.showSnackBar('Something went wrong');
      this.disablebtn = false;
    }
  }

  showImage(product: any){
    let ref = this.dialog.open(ShowImageComponent, { panelClass: 'partner-time-dialog', width: '50vw', data: { product } });
    ref.afterClosed().subscribe(result => {});
  }

  openImage(imageUrl: string) {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }
  }

  async showSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

}
