import { Location } from '@angular/common';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input('elemPerPage') public limits: number[];
  @Input('limit') public limit: number;
  @Input('page') public page: number;
  @Input('count') public count: number;
  @Input('maxPage') public maxPage: number;
  @Input('prefix') public prefix: string = '';
  @Input('compact') public compact = false;
  @Input('navigate') public navigation = true;
  @Input('onTheFly') public onTheFly = false;
  @Output() load = new EventEmitter<{}>();
  public url: any;
  public queryParams: {};

  constructor(private route: ActivatedRoute, private router: Router, private location: Location) { }

  ngOnInit() {
    if (this.route.snapshot.url && this.route.snapshot.url.length) {
      this.url = this.route.snapshot.url.map(u => u.path);
      this.queryParams = this.route.snapshot.queryParams;
    }
    else {
      this.router.events.subscribe((data) => {
        if (data instanceof RoutesRecognized) {
          this.url = data['url'].split('/').filter(p => p != '');
          if (!this.url.length)
            this.url = [''];
          else if (this.url[this.url.length - 1].indexOf('?') >= 0) {
            this.url[this.url.length - 1].substr(0, this.url[this.url.length - 1].indexOf('?'));
          }
          this.queryParams = data.state.root.firstChild.queryParams;
        }
      });
    }
    if (!this.limits) {
      this.limits = [10, 25, 50, 100, 200, 500];
    }
  }

  navigate(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.queryParams;
    const newParams = { ...previousArgs };
    newParams[`${this.prefix}limit`] = limit;
    newParams[`${this.prefix}page`] = page;
    if (!this.onTheFly) {
      if (this.navigation)
        this.router.navigate(this.url, { queryParams: { ...newParams, ...args } });
      else
        this.location.replaceState(
          this.url.join('/'),
          new URLSearchParams({ ...newParams, ...args }).toString());
    }
    this.load.emit({ limit, page });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public getPagesArray(): number[] {
    if (!this.maxPage) { return []; }
    const pages: number[] = Array.from(Array(this.maxPage).keys());
    delete pages[0];
    pages.push(this.maxPage);
    return pages;
  }

}
