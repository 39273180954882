import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerDeliveryRulesSchema } from 'src/app/models/partner-delivery-rules.schema';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { PartnerDeliveryRulesService } from './../../services/partner-delivery-rules.service';

@Component({
  selector: 'app-partner-delivery-rules',
  templateUrl: './partner-delivery-rules.component.html',
  styleUrls: ['./partner-delivery-rules.component.scss']
})
export class PartnerDeliveryRulesComponent implements OnInit {

  filtered = {};
  limit: number;
  page: number;
  count: number;
  maxPage: number;
  order: {};
  defaultPage = 1;
  defaultLimit = 100;
  defaultOrder = { 'PartnerDeliveryRules.createdAt': 'DESC' };
  partnerDeliveryRules: PartnerDeliveryRulesSchema[] = [];
  partnerDeliveryRulesFilterForm: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private partnerDeliveryRulesService: PartnerDeliveryRulesService,
    private _snackBar: MatSnackBar
  ) { 
    this.managepartnerDeliveryRulesFilterForm();
  }

  async ngOnInit() {
    await this.filter();
  }

  async managepartnerDeliveryRulesFilterForm(){
    this.partnerDeliveryRulesFilterForm = new FormGroup({
      'PartnerDeliveryRules.id': new FormControl(''),
      'PartnerDeliveryRules.startTimeRange': new FormControl(''),
      'PartnerDeliveryRules.endTimeRange': new FormControl(''),
      'Partner.businessName': new FormControl(''),
      'PartnerDeliveryRules.kmLimit': new FormControl(''),
      'PartnerDeliveryRules.perDayDeliveryLimit': new FormControl(''),
      'PartnerDeliveryRules.totalDeliveryLimit': new FormControl(''),
      'PartnerDeliveryRules.serviceType': new FormControl(''),
      'PartnerDeliveryRules.payable': new FormControl(''),
      'PartnerDeliveryRules.valetAdditionalPrice': new FormControl(''),
      'PartnerDeliveryRules.billable': new FormControl(''),
      'PartnerDeliveryRules.totalDeliveryRule': new FormControl(''),
      'PartnerDeliveryRules.perDayDeliveryRule': new FormControl(''),
      'PartnerDeliveryRules.additionalPrice': new FormControl(''),
      'PartnerDeliveryRules.validFrom': new FormControl(''),
      'PartnerDeliveryRules.validTill': new FormControl(''),
      'PartnerDeliveryRules.perDayDeliveryLimit.operator': new FormControl('equal'),
      'PartnerDeliveryRules.totalDeliveryLimit.operator': new FormControl('equal'),
      'PartnerDeliveryRules.kmLimit.operator': new FormControl('equal'),
      'PartnerDeliveryRules.additionalPrice.operator': new FormControl('equal'),
      'PartnerDeliveryRules.valetAdditionalPrice.operator': new FormControl('equal'),
    });
  }

  async filter(){
    let filterForm = (new FormGroupToObject()).transform(this.partnerDeliveryRulesFilterForm);
    this.filtered = {};

    if (filterForm['PartnerDeliveryRules.id'])
      this.filtered['PartnerDeliveryRules.id'] = filterForm['PartnerDeliveryRules.id'];  
    if (filterForm['PartnerDeliveryRules.validFrom'])
      this.filtered['PartnerDeliveryRules.validFrom'] = { operator: 'moreThanOrEqual', values: [filterForm['PartnerDeliveryRules.validFrom']] };
    if (filterForm['PartnerDeliveryRules.validTill'])
      this.filtered['PartnerDeliveryRules.validTill'] = { operator: 'lessThanOrEqual', values: [filterForm['PartnerDeliveryRules.validTill']] };
    // if (filterForm['PartnerDeliveryRules.validFrom'] && filterForm['PartnerDeliveryRules.validTill'])
    //   this.filtered['PartnerDeliveryRules.deliveryDate'] = { operator: 'between', values: [filterForm['PartnerDeliveryRules.deliveryDate_from'], filterForm['PartnerDeliveryRules.deliveryDate_to']] };  
    if (filterForm['PartnerDeliveryRules.startTimeRange'])
      this.filtered['PartnerDeliveryRules.startTimeRange'] = { operator: 'moreThanOrEqual', values: [filterForm['PartnerDeliveryRules.startTimeRange']] };
    if (filterForm['PartnerDeliveryRules.endTimeRange'])
      this.filtered['PartnerDeliveryRules.endTimeRange'] = { operator: 'lessThanOrEqual', values: [filterForm['PartnerDeliveryRules.endTimeRange']] };
    if (filterForm['Partner.businessName'])
      this.filtered['Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Partner.businessName']}%`] };
    if (filterForm['PartnerDeliveryRules.kmLimit'])
      this.filtered['PartnerDeliveryRules.kmLimit'] = { operator: filterForm['PartnerDeliveryRules.kmLimit.operator'], values: [filterForm['PartnerDeliveryRules.kmLimit']] };
    if (filterForm['PartnerDeliveryRules.perDayDeliveryLimit'])
      this.filtered['PartnerDeliveryRules.perDayDeliveryLimit'] = { operator: filterForm['PartnerDeliveryRules.perDayDeliveryLimit.operator'], values: [filterForm['PartnerDeliveryRules.perDayDeliveryLimit']] };
    if (filterForm['PartnerDeliveryRules.totalDeliveryLimit'])
      this.filtered['PartnerDeliveryRules.totalDeliveryLimit'] = { operator: filterForm['PartnerDeliveryRules.totalDeliveryLimit.operator'], values: [filterForm['PartnerDeliveryRules.totalDeliveryLimit']] };
    if (filterForm['PartnerDeliveryRules.serviceType'])
      this.filtered['PartnerDeliveryRules.serviceType'] = { operator: 'like', values: [`%${filterForm['PartnerDeliveryRules.serviceType']}%`]};
    if (filterForm['PartnerDeliveryRules.payable'])
      this.filtered['PartnerDeliveryRules.payable'] = { operator: 'like', values: [`%${filterForm['PartnerDeliveryRules.payable']}%`]};
    if (filterForm['PartnerDeliveryRules.billable'])
      this.filtered['PartnerDeliveryRules.billable'] = { operator: 'like', values: [`%${filterForm['PartnerDeliveryRules.billable']}%`]};
    if (filterForm['PartnerDeliveryRules.totalDeliveryRule'])
      this.filtered['PartnerDeliveryRules.totalDeliveryRule'] = { operator: 'like', values: [`%${filterForm['PartnerDeliveryRules.totalDeliveryRule']}%`]};
    if (filterForm['PartnerDeliveryRules.perDayDeliveryRule'])
      this.filtered['PartnerDeliveryRules.perDayDeliveryRule'] = { operator: 'like', values: [`%${filterForm['PartnerDeliveryRules.perDayDeliveryRule']}%`]};
    if (filterForm['PartnerDeliveryRules.additionalPrice'])
      this.filtered['PartnerDeliveryRules.additionalPrice'] = { operator: filterForm['PartnerDeliveryRules.additionalPrice.operator'], values: [filterForm['PartnerDeliveryRules.additionalPrice']] };
    if (filterForm['PartnerDeliveryRules.valetAdditionalPrice'])
      this.filtered['PartnerDeliveryRules.valetAdditionalPrice'] = { operator: filterForm['PartnerDeliveryRules.valetAdditionalPrice.operator'], values: [filterForm['PartnerDeliveryRules.valetAdditionalPrice']] };

    this.loadPartnerDeliveryRules();
  }

  async loadPartnerDeliveryRules(limit?: number, page?: number, order?: {}){
    try {
      this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
      this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
      this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;
      let partnerDeliveryRules = await this.partnerDeliveryRulesService.getAll(this.page, this.limit, this.order, this.filtered);
      this.partnerDeliveryRules = partnerDeliveryRules;
      this.count = partnerDeliveryRules.length;
      this.maxPage = Math.ceil(this.count / this.limit);
    } catch (error) {
      console.log("Error", error);
    }
  }

  
  async delete(id: number){
    if (!confirm("Sei sicuro di voler eliminare questa regola di consegna del partner?"))
      return;
    try { 
      const result = await this.partnerDeliveryRulesService.delete(id);
      await this.showSnackBar('Regola di consegna partner eliminata correttamente');
      await this.loadPartnerDeliveryRules();
    } catch (error) {
      this.showSnackBar(`${error.error.message}`);
    }
  }

  formatTimeShow(fromTime, toTime){
    let trimmedFromTime = fromTime != null ? fromTime?.slice(0, -3) : '';
    let trimmedToTime = toTime != null ? toTime?.slice(0, -3) : '';
    let time = trimmedFromTime + '-' + trimmedToTime;
    return time;
  }

  async resetFilter() {
    await this.managepartnerDeliveryRulesFilterForm();
    await this.filter();
  }

  async navigate(event) {
    await this.loadPartnerDeliveryRules(event.limit, event.page, this.order);
  }

  public async navigateOrder(attribute: string | string[]) {
    delete this.order['FlowersSales.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order = {}
          this.order[attr] = 'ASC';
          break;
      }
    }

    await this.navigatePage(this.limit, this.page, { order: this.order });
  }

  async navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(async (data) => {
      await this.loadPartnerDeliveryRules();
    });
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }
  
  async showSnackBar(message: string) {
    this._snackBar.open(`${message}`, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
  }

}
